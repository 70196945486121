import React, { Component } from 'react';

export default class BrowserFrame extends Component {
  render() {
    let info = null;
    if (this.props.info) {
      info = (<p className="info">{this.props.info}</p>);
    }

    let content = null;
    if (this.props.title || this.props.subtitle || info) {
      content = (
        <div className="browser-content">
          { this.props.title && <p className="title">{ this.props.title }</p> }
          { this.props.subtitle && <p className="subtitle">{this.props.subtitle}</p> }
          { info }
        </div>
      );
    }

    let style = {};
    if (this.props.backgroundColor) {
      style.backgroundColor = this.props.backgroundColor;
    }

    return (
      <div className={`browser ${this.props.mobile ? 'mobile' : null}`} style={style}>
        <div className="browser-header">
          <div className="icons">
            <i className="fas fa-chevron-left" />
            <i className="fas fa-chevron-right" />
            <i className="fas fa-redo-alt" />
          </div>
        </div>

        { this.props.showTitle && content }
        { this.props.showLoading && <div className="loading" />}
      </div>
    );
  }
}