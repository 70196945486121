import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

import $ from 'jquery';
import _ from 'lodash';

import * as PollActions from '../actions/Polls';
import * as SlideActions from '../actions/Slides';

import * as FlashNotificationActions from '../actions/FlashNotifications';

import Tooltip from './Tooltip';
import Switch from './Switch';
import Modal from './Modal';
import Embed from './Embed';

import { getSlideLabel, getSlideIcon, decode } from '../utils';

function replaceProduct(str, product) {
  if (!str) { return str; }
  return str.replace(/{{ product }}/g, product);
}

const slides = [
{
  type: 'question',
  title: 'How did you hear about us?',
  handle: 'How did you hear about us?',
  subtitle: '',
  answers: [
    { title: 'From a Friend', handle: 'from-a-friend' },
    { title: 'Instagram', handle: 'instagram' },
    { title: 'Facebook', handle: 'facebook' },
    { title: 'Youtube', handle: 'youtube' },
    { title: 'TikTok', handle: 'tiktok' },
    { title: 'Podcast', handle: 'podcast' },
    { title: 'Google', handle: 'google' },
    { title: 'Other', handle: 'other', dynamic: true },
  ],
},
{
  type: 'question',
  title: 'What led you to our store today?',
  handle: 'What led you to our store today?',
  subtitle: '',
  answers: [
    { title: 'From or family', handle: 'friend-or-family' },
    { title: 'Instagram', handle: 'instagram' },
    { title: 'Facebook', handle: 'facebook' },
    { title: 'Youtube', handle: 'youtube' },
    { title: 'TikTok', handle: 'tiktok' },
    { title: 'Podcast', handle: 'podcast' },
    { title: 'Google', handle: 'google' },
    { title: 'Other', handle: 'other', dynamic: true },
  ],
},
{
  type: 'question',
  title: 'When did you first hear about us?',
  handle: 'When did you first hear about us?',
  subtitle: '',
  answers: [
    { title: 'Today', handle: 'today' },
    { title: 'In the last few days', handle: 'in-the-last-few-days' },
    { title: 'Over a week ago', handle: 'over-a-week-ago' },
    { title: 'Over a month ago', handle: 'over-a-month-ago' },
  ],
},
{
  type: 'question',
  title: 'What brought you to our site today?',
  handle: 'What brought you to our site today?',
  subtitle: '',
  answers: [
    { title: 'I recieved a text message', handle: 'i-recieved-a-text-message' },
    { title: 'I saw an ad', handle: 'i-saw-an-add' },
    { title: 'Someone told me about you', handle: 'someone-told-me-about-you' },
    { title: 'I was searching for something', handle: 'i-was-searching-for-something' },
    { title: 'I recieved an email', handle: 'i-recieved-an-email' },
    { title: 'I remembered you from the past', handle: 'i-remembered-you-from-the-past' },
    { title: 'Other', handle: 'other', dynamic: true },
  ],
},
{
  type: 'question',
  title: 'How long did you know about us before placing your first purchase?',
  handle: 'How long did you know about us before placing your first purchase?',
  subtitle: '',
  answers: [
    { title: 'Less than a day', handle: 'less-than-a-day' },
    { title: 'Less than a week', handle: 'less-than-a-week' },
    { title: 'Less than a month', handle: 'less-than-a-month' },
    { title: '1 - 3 months', handle: '1-3-months' },
    { title: '3 - 12 months', handle: '3-12-months' },
    { title: 'Over 12 months', handle: 'over-12-months' },
  ],
},
{
  type: 'long-answer',
  handle: 'Why did you purchase from us?',
  title: 'Why did you purchase from us?',
  subtitle: '',
  copy: '',
  placeholder: ''
},
{
  type: 'long-answer',
  handle: 'Was there anything that almost made you not purchase?',
  title: 'Was there anything that almost made you not purchase?',
  subtitle: '',
  copy: "",
  placeholder: ''
},
{
  type: 'long-answer',
  handle: 'What other brands did you consider before purchasing from us?',
  title: 'What other brands did you consider before purchasing from us?',
  subtitle: '',
  copy: '',
  placeholder: ''
},
{
  type: 'long-answer',
  handle: 'How will our product make an impact on your lifestyle?',
  title: 'How will our product make an impact on your lifestyle?',
  subtitle: '',
  copy: '',
  placeholder: ''
},
{
  type: 'long-answer',
  handle: 'What occasion is this purchase for?',
  title: 'What occasion is this purchase for?',
  subtitle: '',
  copy: '',
  placeholder: ''
},
{
  type: 'long-answer',
  handle: 'Why did you make this purchase?',
  title: 'Why did you make this purchase?',
  subtitle: '',
  copy: '',
  placeholder: ''
},
{
  type: 'long-answer',
  handle: 'What almost kept you purchasing from us?',
  title: 'What almost kept you purchasing from us?',
  subtitle: '',
  copy: '',
  placeholder: ''
},
{
  type: 'long-answer',
  handle: 'What do you wish we had more of?',
  title: 'What do you wish we had more of?',
  subtitle: '',
  copy: '',
  placeholder: ''
},
{
  type: 'long-answer',
  handle: 'Who can we thank for your purchase today?',
  title: 'Who can we thank for your purchase today?',
  subtitle: '',
  copy: '',
  placeholder: ''
},
{
  type: 'long-answer',
  handle: 'Was a part of this website difficult to navigate?',
  title: 'Was a part of this website difficult to navigate?',
  subtitle: '',
  copy: '',
  placeholder: ''
},
{
  type: 'long-answer',
  handle: "What's something you love doing?",
  title: "What's something you love doing?",
  subtitle: '',
  copy: '',
  placeholder: ''
},
{
  type: 'long-answer',
  handle: "What's your favorite thing about our brand?",
  title: "What's your favorite thing about our brand?",
  subtitle: '',
  copy: '',
  placeholder: ''
},
{
  type: 'long-answer',
  handle: "What did you enjoy most about your experience?",
  title: "What did you enjoy most about your experience?",
  subtitle: '',
  copy: '',
  placeholder: ''
},
{
  type: 'long-answer',
  handle: "What feature do you want us to have?",
  title: "What feature do you want us to have?",
  subtitle: '',
  copy: '',
  placeholder: ''
},
{
  type: 'long-answer',
  handle: "What makes us stand out from our competitors?",
  title: "What makes us stand out from our competitors?",
  subtitle: '',
  copy: '',
  placeholder: ''
},
{
  type: 'long-answer',
  handle: "What's one thing we should keep doing?",
  title: "What's one thing we should keep doing?",
  subtitle: '',
  copy: '',
  placeholder: ''
},
{
  type: 'short-answer',
  handle: "What would you Google to search for a business like ours?",
  title: "What would you Google to search for a business like ours?",
  subtitle: '',
  copy: '',
  placeholder: ''
},
{
  type: 'short-answer',
  handle: "Do you have any questions for us?",
  title: "Do you have any questions for us?",
  subtitle: '',
  copy: '',
  placeholder: ''
},
{
  type: 'short-answer',
  handle: "What are our competitors doing better than what we do?",
  title: "What are our competitors doing better than what we do?",
  subtitle: '',
  copy: '',
  placeholder: ''
},
{
  type: 'short-answer',
  handle: "What's your occupation?",
  title: "What's your occupation?",
  subtitle: '',
  copy: '',
  placeholder: ''
},
{
  type: 'satisfaction',
  title: 'How was your shopping experience?',
  handle: 'How was your shopping experience?',
  subtitle: '',
  answers: [
    { title: 'Very Unsatisfied', handle: 'very-unsatisfied' },
    { title: 'Unsatisfied', handle: 'unsatisfied' },
    { title: 'Neutral', handle: 'neutral' },
    { title: 'Satisfied', handle: 'satisfied' },
    { title: 'Very Satisfied', handle: 'very-satisfied' },
  ],
  leftLabel: 'Negative',
  rightLabel: 'Positive'
},
{
  type: 'question',
  handle: 'Who is this purchase for?',
  title: 'Who is this purchase for?',
  subtitle: '',
  copy: '',
  answers: [
    { title: 'Myself', handle: 'myself' },
    { title: 'Friend or family', handle: 'friend-or-family' },
    { title: 'Coworker or client', handle: 'coworker-or-client' },
    { title: 'Other', handle: 'other', dynamic: true },
  ]
},
{
  type: 'long-answer',
  handle: 'What is this purchase for?',
  title: 'What is this purchase for?',
  subtitle: '',
  copy: '',
  placeholder: ''
},
{
  type: 'question',
  handle: 'How often do you shop?',
  title: 'How often do you shop?',
  subtitle: '',
  copy: '',
  answers: [
    { title: 'A few times a month', handle: 'a-few-times-a-month' },
    { title: 'Once a month', handle: 'once-a-month' },
    { title: 'Weekly', handle: 'weekly' },
    { title: 'Daily', handle: 'daily' },
    { title: 'Other', handle: 'other', dynamic: true },
  ]
},
{
  type: 'question',
  handle: 'What is most important when shopping for products like ours?',
  title: 'What is most important when shopping for products like ours?',
  subtitle: '',
  copy: '',
  answers: [
    { title: 'Quality', handle: 'quality' },
    { title: 'Price', handle: 'price' },
    { title: 'Delivery Speed', handle: 'delivery-speed' },
    { title: 'Warranty', handle: 'warranty' },
    { title: 'Other', handle: 'other', dynamic: true },
  ]
},
{
  type: 'question',
  handle: 'What generation do you identify with?',
  title: 'What generation do you identify with?',
  subtitle: '',
  copy: '',
  answers: [
    { title: 'Gen Z', handle: 'gen-z' },
    { title: 'Millennial', handle: 'millennial' },
    { title: 'Gen X', handle: 'gen-x' },
    { title: 'Baby Boomer', handle: 'baby-boomer' },
    { title: 'Other', handle: 'other', dynamic: true },
  ]
},
{
  type: 'question',
  handle: 'Are you happy with our product selection?',
  title: 'Are you happy with our product selection?',
  subtitle: '',
  copy: '',
  answers: [
    { title: 'Yes', handle: 'yes' },
    { title: 'No', handle: 'no' },
    { title: 'Other', handle: 'other', dynamic: true },
  ]
},
{
  type: 'long-answer',
  handle: 'Who do you think our biggest competitors are?',
  title: 'Who do you think our biggest competitors are?',
  subtitle: '',
  copy: '',
  placeholder: ''
},
{
  type: 'range',
  handle: 'How important is fast shipping to you?',
  title: 'How important is fast shipping to you?',
  subtitle: '',
  copy: '',
  answers: [
    { title: '1', handle: '1' },
    { title: '2', handle: '2' },
    { title: '3', handle: '3' },
    { title: '4', handle: '4' },
    { title: '5', handle: '5' },
  ],
  leftLabel: "Not Important",
  rightLabel: "Very Important"
},
{
  type: 'binary',
  handle: 'Do we stand out?',
  title: 'Do we stand out?',
  subtitle: 'Is it clear that you purchased something you couldn’t get anywhere else?',
  copy: '',
  answers: [
    { title: 'No', handle: 'no' },
    { title: 'Yes!', handle: 'yes' },
  ]
},
{
  type: 'binary',
  handle: 'Would you recommend us to your friend?',
  title: 'Would you recommend us to your friend?',
  subtitle: '',
  copy: '',
  answers: [
    { title: 'No', handle: 'no' },
    { title: 'Yes', handle: 'yes' },
  ]
},
]

function filterSlides(slides, query) {
  if (!query) {
    return slides;
  }

  const regex = new RegExp(".*" + query + "+", 'i');

  return _.filter(slides, ({ handle, title, subtitle, copy }) => {
    if (regex.test(title)) { return true; }
    if (regex.test(handle)) { return true; }
    if (regex.test(subtitle)) { return true; }
    if (regex.test(copy)) { return true; }
    return false;
  });
}

class SlideBankModal extends Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false, currentIdx: 0, slide: false, slides: slides, prevIndexes: [] }
  }

  next() {
    this.state.prevIndexes.push(this.state.currentIdx);
    this.setState({ currentIdx: this.state.currentIdx + 1 });
  }

  prev() {
    const idx = this.state.prevIndexes.pop();
    if (idx !== undefined) {
      this.setState({ currentIdx: idx });
    }
  }

  showModal() {
    this.setState({ showModal: true });
  }

  closeModal() {
    this.props.onClose();
  }

  generateCopy(subtitle, copy) {
    let str = [];
    if (subtitle) {
      str.push(subtitle);
    }

    if (copy) {
      str.push(copy);
    }

    str = str.join(' ');

    if (!str) {
      return '';
    }
    return str.replace(/{{ product }}/g, `<strong>${this.props.product}</strong>`);
  }

  prepareSlides(slides) {
    const preparedSlides = [];
    slides.forEach((slide) => {
      const s = { ...slide }
      s.copy = replaceProduct(s.copy, this.props.product);
      s.subtitle = replaceProduct(s.subtitle, this.props.product);
      preparedSlides.push(s);
    });
    return preparedSlides;
  }

  createSlide() {
    const slide = this.state.slide;
    slide.pollId = this.props.pollId;

    const formData = new FormData();
    formData.append('slide', JSON.stringify(this.state.slide));
    this.props.createSlide(formData, 'Slide created successfully', this.props.skipRedirect);
    this.closeModal();
    this.setState({ slide: undefined });
  }

  render() {
    let customize = null;
    // if (this.props.hasProduct) {
    //   customize = (
    //     <div className="section">
    //       <form>
    //         <label>Customize</label>
    //         <input
    //           type="text"
    //           name="product"
    //           value={this.props.product}
    //           onChange={this.props.onChange}
    //         />
    //       </form>
    //     </div>
    //   );
    // }

    const slides = [this.state.slide];
    const displaySettings = this.props.poll.displaySettings || this.props.account.displaySettings;
    let embed = <div className="preview-text"><p className="preview-title">Slide Preview</p><p className="preview-subtitle">Select a Slide to Preview It.</p></div>;
    if (this.state.slide) {
      embed = <Embed 
        {...displaySettings}
        toggleActive={() => { this.setState({ active: !this.state.active})}}
        delayRender={true}
        align="modal"
        isEmbed={true}
        position={[0,0]}
        active={true}
        slides={this.prepareSlides(slides)}
        goto={(idx) => {
          this.state.prevIndexes.push(this.state.currentIdx);
          this.setState({ currentIdx: idx }) 
        }}
        next={this.next.bind(this)}
        prev={this.prev.bind(this)}
        currentIdx={this.state.currentIdx}
      />
    }

    let filteredSlides = filterSlides(this.state.slides, this.state.query);

    let slidesContent = <ul>
      { filteredSlides.map((slide) => {
        if (slide.hide) { return null; }

        return (<li
          className={this.state.slide && (this.state.slide.handle === slide.handle) ? 'active' : ''}
          onClick={() => {
            this.setState({ slide }, () => {
              $(window).trigger('resize');
          }) }}
        > 
          <i className={`fas ${getSlideIcon(slide.type)}`} />
          <div>{slide.listTitle || slide.title}</div>
          <div className="below">{getSlideLabel(slide.type)}</div>
        </li>);
      })}
    </ul>

    if (filteredSlides.length === 0) {
      slidesContent = (<div className="empty"><i className="fas fa-user-secret" />
        <div className="top">No matching questions found.</div>
        <div>Try a different search.</div>
      </div>);
    }

    return (
      <Modal 
        isOpen={this.props.active}
        onRequestClose={this.closeModal.bind(this)}
      >
        <div className="frame slide-bank-section">
          <div className="close" onClick={this.closeModal.bind(this)} />
          <div className="title">Question Bank</div>

          <div className="split-content">
            <div className="left slide-bank-options ">
              <div className="section">
                <label>Question Templates</label>
                <div className="filter">
                  <i className="fas fa-search" />
                  <input
                  type="text"
                  placeholder="Filter questions"
                  onChange={(e) => {
                    this.setState({ query: e.target.value });
                  }}></input>
                </div>
                { slidesContent }
              </div>

              { customize }
            </div>
            <div className="right">
              { embed }
            </div>
          </div>

          <div className="actions">
            <button className="positive" onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              if (!this.state.slide) {
                this.props.flash('Please select a slide');
                return;
              }

              this.createSlide();
            }}>Create Slide</button>
            <button className="negative" onClick={this.closeModal.bind(this)}>Cancel</button>
          </div>
        </div>
      </Modal>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const accountId = decode(ownProps.match.params.accountId);
  const pollId = decode(ownProps.match.params.pollId);

  const accounts = state.accounts || [];
  const account = accounts[accountId];

  const polls = state.polls || [];
  const poll = polls[pollId];

  return {
    loading: state.polls.loading,
    account,
    pollId,
    poll
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...PollActions, ...SlideActions, ...FlashNotificationActions }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SlideBankModal));
