import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import * as UserActions from '../actions/Users';

import { EmailInput } from './SimpleInputs';
import { AuthSubmitButton } from './Buttons';

import AuthForm from './AuthForm';

class ForgotPasswordForm extends AuthForm {
  constructor(props)  {
    super(props);
    this.state = { 
      inputs: {
        email: ''
      },
      valid: undefined
    };
    this.inputStates = {};
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading && !this.props.loading) {
      if (this.props.notifications[0].type === 'server-error') {
        this.setState({ submitted: false });
      } else {
        this.setState({ submitted: true });
      }
    }
  }

  submitAction() {
    const email = this.state.inputs.email;
    this.props.sendPasswordResetEmail(email);
  }

  renderInputs() {
    return (
      <div className="inputs">
        <EmailInput
          onChange={this.onChange.bind(this)}
          onValidate={this.onValidate.bind(this)}
          label="Email Address"
          type="email"
          placeholder="name@company.com"
          name="email"
          value={this.state.inputs.email}
          errorMessage="Please enter a valid email address."
          ref={this.setRef.bind(this)}
          disabled={this.state.submitted ? true : false}
          maxlength={255}
          hideCharCount={true}
        />
      </div>
    );
  }

  renderActions() {
    return (
      <div className="actions">
        <AuthSubmitButton
          title={this.state.submitted ? "Email Sent!" : "Reset Password"}
          disabled={this.state.submitted ? true : false}
        />
        <div className="login">
          <div>Remember it now?</div>
          <Link to="/log-in">Log In</Link>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    loading: state.user.loading,
    notifications: state.notifications
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(UserActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordForm);

