import React, { Component } from 'react';

import Header from '../components/Header';
import Notifications from '../components/Notifications';

import FlashNotifications from '../components/FlashNotifications';
import SingleFormPageGraphics from '../components/SingleFormPageGraphics';
import DocumentTitle from 'react-document-title';

import topRight from '../images/topright.png';
import bottomLeft from '../images/bottomleft.png';

import { handleize } from '../utils';

const Graphics = (props) => {
  return (<SingleFormPageGraphics 
    bottomLeft={bottomLeft}
    topRight={topRight}
  />)
}

export default class Layout extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  renderContent() {
    return null;
  }

  render() {
    const props = this.props;
    let className = this.props.className || handleize(this.props.title);
    if (this.extraClass) {
      className = className + ' ' + this.extraClass;
    }

    let graphics;

    if (props.graphics) {
      graphics = <Graphics />
    }

    let documentTitle = null;
    if (props.title) {
      documentTitle = <DocumentTitle title={`Zigpoll → ${props.title}`} />
    }

    return (
      <div className={`page-container ${className}`}>
        { documentTitle }

        <Header title={props.title} pageType={this.props.pageType} />

        { props.breadcrumbs }

        <Notifications />
        <FlashNotifications />

        <div className="page-content">

          { this.renderContent() }

        </div>

        <div id="loading-indicator" />
        <div id="body-visibility-status" />
        <div id="account-dropdown-items" />
        <div id="survey-dropdown-items" />
      </div>
    );
  }
}