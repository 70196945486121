import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import VisibilitySettingsForm from './VisibilitySettingsForm';

import * as AccountActions from '../actions/Accounts';

class AccountVisibilitySettingsForm extends VisibilitySettingsForm {
  // updateSettings(message) {
  //   const account = {};
  //   account._id = this.props.account._id;
  //   account.visibilitySettings = this.state;
  //   delete account.showDynamicRules;

  //   this.props.updateAccount(account, message);
  // }

  onSubmit(e) {
    e.stopPropagation();
    e.preventDefault();

    const account = {};
    account._id = this.props.account._id;
    account.visibilitySettings = this.state.inputs.visibilitySettings;
    delete account.showDynamicRules;

    if (account.visibilitySettings && account.visibilitySettings.openAttempts) {
      if (parseInt(account.visibilitySettings.openAttempts) == 0) {
        account.visibilitySettings.openAttempts = '1';
      }
    }

    this.props.updateAccount(account, "The behavior settings have been updated.");
  }

  updateAttempts() {
    const account = {};
    account._id = this.props.account._id;
    account.visibilitySettings = {
      openAttempts: parseInt(this.state.openAttempts)
    };
    this.props.updateAccount(account, "The behavior settings have been updated.");
  }
}

function mapStateToProps(state, ownProps) {
  const displaySettings = ownProps.account.displaySettings || {};
  const settings = ownProps.account.visibilitySettings || {};

  return {
    settings,
    displaySettings,
    isAccountVisibilitySettingsForm: true,
    loading: state.accounts.loading
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(AccountActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountVisibilitySettingsForm);
