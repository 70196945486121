import _ from 'lodash';

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';

import { NameInput, HTMLInput, JSInput } from './SimpleInputs';
import Modal from './Modal';
import Toggle from './Toggle';
import Tooltip from './Tooltip';
import ColorPicker from './ColorPicker';
import ButtonIconSelector from './ButtonIconSelector';
import Switch from './Switch';

import NextSlideInput from './NextSlideInput';

import * as FlashNotificationsActions from '../actions/FlashNotifications';

import { handleize, move } from '../utils';

import { DragDropContext, DragSource, DropTarget } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

/* Drag */
const dragSource = {
  beginDrag(props) {
    return {
      idx: props.idx
    };
  }
};

const dragCollect = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  }
};

let direction;
const dropTarget = {
  hover(props, monitor, component) {
    const dragIdx = monitor.getItem().idx;
    let dropIdx = props.idx;

    if (dragIdx < dropIdx) {
      direction = 'down';
      component.forceUpdate();
    } else if (dragIdx > dropIdx) {
      direction = 'up';
      component.forceUpdate();
    }
  },
  drop(props, monitor, component) {
    const dragIdx = monitor.getItem().idx;
    let dropIdx = props.idx;

    if (dragIdx === dropIdx) {
      return;
    }

    let idx;
    if (dragIdx < dropIdx) {
      idx = dropIdx - 1 < 0 ? 0 : dropIdx;
      props.reorderActions(dragIdx, idx);
    } else {
      idx = dropIdx + 1 >= props.pollCount ? props.pollCount - 1 : dropIdx;
      props.reorderActions(dragIdx, idx);
    }
  }
};

const dropCollect = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver()
})

class DumbDragHandle extends Component {
  render() {
    const { connectDragSource } = this.props;
    return connectDragSource(<span className="handle" />)
  }
}

class DumbDropContext extends Component {
  render() {
    const { connectDropTarget, isOver } = this.props;
    return connectDropTarget(<div className={`drop-context ${isOver ? `is-over ${direction}` : ''}`}>{ this.props.children }</div>);
  }
}

const DragHandle = compose(
  DragSource('ACTION', dragSource, dragCollect),
)(DumbDragHandle);

const DropContext = compose(
  DropTarget('ACTION', dropTarget, dropCollect)
(DumbDropContext))

class Action extends Component {
  constructor(props) {
    super(props);
    let buttonAction = 'link';
    if (this.props.onClick) {
      buttonAction = 'custom-code';
    }
    if (this.props.link) {
      buttonAction = 'link';
    }
    if (this.props.redirect) {
      buttonAction = 'redirect';
    }
    this.state = { showModal: false, buttonAction };
  }

  onChange(e) {
    this.props.onChange(e, this.props.idx);
  }

  onValidate(e) {
    this.props.onValidate(e, this.props.idx);
  }

  setRef(element) {
    if (!this.inputs) {
      this.inputs = {};
    }
    if (element) {
      this.inputs[element.props.name] = element;
    }
  }

  validate() {
    let valid = true;

    for (const key in this.inputs) {
      if (!this.inputs[key].validate()) {
        valid = false;
      }
    }

    return valid;
  }

  removeAction(e) {
    this.props.removeAction(this.props.idx);
  }

  showModal() {
    this.setState({ showModal: true });
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  render() {
    return <DropContext
      reorderActions={this.props.reorderActions}
      idx={this.props.idx}
    ><div className="answer">
      <DragHandle idx={this.props.idx} />

      <NameInput
        onChange={this.onChange.bind(this)}
        onValidate={this.onValidate.bind(this)}
        label={`Button Title`}
        type="text"
        placeholder="Public facing title for the action"
        name="title"
        value={this.props.title}
        errorMessage="Please a title for the action."
        ref={this.setRef.bind(this)}
        // maxlength={40}
        // extraComponent={<NextSlideInput
        //   slides={this.props.slides}
        //   nextSlide={this.props.nextSlide}
        //   idx={this.props.idx}
        //   value={this.props.title}
        //   hidden={this.props.showRules ? false: true}
        //   onChange={this.props.onChange.bind(this)}
        // />}
      />

      <div className="input" style={{ marginBottom: 15 }}>
        <label>Background Color</label>
        <ColorPicker
          color={this.props.backgroundColor}
          onChange={(color, event) => {
            this.onChange({ target: { name: 'backgroundColor', value: color.hex } });
          }}
        />
      </div>

      <div style={{ marginBottom: 15 }}>
        <ButtonIconSelector
          selected={this.props.icon}
          onChange={(data, type) => {
            this.onChange({ target: { name: 'icon', value: data } });
          }}
        />
      </div>

      <div className="input">
        <label>Action</label>
        <Switch
          vertical={true}
          options={[
            { label: 'Link (New Window)', value: 'link'},
            { label: 'Link (Redirect)', value: 'redirect'},
            { label: 'Custom Code', value: 'custom-code'},
          ]}
          value={this.state.buttonAction}
          onChange={(value) => {
            if (value === 'custom-code') {
              this.onChange({ target: { name: 'redirect', value: '' }});
              this.onChange({ target: { name: 'link', value: '' }});
            } else if (value === 'link') {
              this.onChange({ target: { name: 'onClick', value: '' }});              
              this.onChange({ target: { name: 'redirect', value: '' }});
            } else {
              this.onChange({ target: { name: 'onClick', value: '' }});
              this.onChange({ target: { name: 'link', value: '' }});
            }

            this.setState({ buttonAction: value });
          }}
        />

        { this.state.buttonAction === 'link' && <div>
          <NameInput
            key={'link'}
            onChange={this.onChange.bind(this)}
            onValidate={() => console.log('validate')}
            noValidate={true}
            hideLabel={true}
            type="text"
            placeholder="https://www.yoursite.com"
            name="link"
            value={this.props.link}
            errorMessage="Please enter a link."
            ref={this.setRef.bind(this)}
          />
        </div>}
        { this.state.buttonAction === 'redirect' && <div>
          <NameInput
            key={'redirect'}
            onChange={this.onChange.bind(this)}
            onValidate={() => console.log('validate')}
            noValidate={true}            
            hideLabel={true}
            type="text"
            placeholder="https://www.yoursite.com"
            name="redirect"
            value={this.props.redirect}
            errorMessage="Please enter a link."
            ref={this.setRef.bind(this)}
          />
        </div>}
        { this.state.buttonAction === 'custom-code' && <JSInput
          style={{ marginTop: 0 }}
          onChange={(value) => {
            this.onChange({ target: { name: 'onClick', value } });
          }}
          // label="Custom Code"
          key={'custom-code'}
          type="text"
          name="onClick"
          onValidate={() => console.log('validate')}
          noValidate={true}
          value={this.props.onClick}
        />}

        <label>Behavior <Tooltip>If an action is required, it must be clicked by the respondant before proceeding to the next slide.</Tooltip></label>
        <Switch
          options={[
            { label: 'Optional', value: 'optional'},
            { label: 'Required', value: 'required'},
          ]}
          value={this.props.behavior || 'optional'}
          onChange={(value) => {
            this.onChange({ target: { name: 'behavior', value }});
          }}
        />
      </div>



      <div className="remove" onClick={() => {
        if (this.props.votes) {
          this.showModal();
        } else {
          this.removeAction();
        }
      }} />
      <Modal 
        isOpen={this.state.showModal}
        onRequestClose={this.closeModal.bind(this)}
      >
        <div className="frame">
          <div className="close" onClick={this.closeModal.bind(this)} />
          <div className="title">Are you sure?</div>
          <div className="content">
            <div className="subtitle">This will remove the action and all analytics associated with it.</div>
            <div className="actions">
              <button className="positive" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                this.removeAction();
                this.closeModal();
              }}>Yes</button>
              <button className="negative" onClick={this.closeModal.bind(this)}>No</button>
            </div>
          </div>
        </div>
      </Modal>
    </div></DropContext>
  }
}

class ActionsInput extends Component {
  constructor(props) {
    super(props);

    let actions = [];
    if (props.actions) {
      actions = [ ...props.actions ];
    }
    if (actions.length === 0) {
      actions.push({ title: '', handle: '' });
    }

    this.state = {
      actions,
    };
  }

  reset() {
    this.setState({ valid: undefined, invalid: undefined });
  }

  addAction(e) {
    e.preventDefault();
    e.stopPropagation();

    const actions = this.state.actions;
    // let hasEmpty = false;

    // actions.forEach(action => {
    //   if (!action.title) {
    //     hasEmpty = true;
    //   }
    // });

    // if (hasEmpty) { return; }

    actions.push({ title: '', handle: '' });
    this.setState({ actions });
  }

  removeAction(idx) {
    delete this.inputs[idx];
    const actions = this.state.actions;
    actions.splice(idx, 1);

    this.setState({ actions }, this.update.bind(this));
  }

  onChange(e, idx) {
    const actions = [...this.state.actions];
    const action = actions[idx];
    action[e.target.name] = e.target.value;

    if (e.target.name === 'title') {
      action.handle = handleize(e.target.value, this.props.slideId);
    }

    this.setState({ actions }, this.update.bind(this));
  }

  update() {
    const actions = this.state.actions.map(action => { delete action.clicks; return action });

    const e = { target: { name: 'actions', value: actions } };
    this.props.onChange(e)
  }

  onValidate(e, idx) {
    this.props.onValidate('actions', true);
  }

  setRef(element) {
    if (!this.inputs) {
      this.inputs = {};
    }

    if (element) {
      if (element.getDecoratedComponentInstance && element.getDecoratedComponentInstance()) {
        element = element.getDecoratedComponentInstance();
      }
      this.inputs[element.props.idx] = element;
    }
  }

  reorderActions(dragIdx, dropIdx) {
    const actions = move(this.state.actions, dragIdx, dropIdx);
    this.setState({ actions }, this.update.bind(this));
  }

  validate() {
    let valid = true;

    for (const key in this.inputs) {
      if (!this.inputs[key].validate()) {
        valid = false;
      }
    }

    var uniq = _.uniqBy(this.state.actions, action => action.handle);
    if (uniq.length !== this.state.actions.length) {
      const e = { target: { name: 'actions', value: uniq } };
      this.props.onChange(e)

      this.props.flash('Duplicate actions detected. They were automatically removed.', 'warning');

      this.setState({ actions: uniq });
    }

    return valid;
  }

  renderActions() {
    let actions = null;

    actions = this.state.actions.map(({ title, value, backgroundColor, onClick, icon, link, redirect, nextSlide, behavior, votes }, idx) => (
      <Action 
        key={idx}
        title={title}
        link={link}
        redirect={redirect}
        backgroundColor={backgroundColor || this.props.backgroundColor}
        behavior={behavior}
        onClick={onClick}
        icon={icon}
        value={value}
        nextSlide={nextSlide}
        idx={idx}
        votes={votes}
        onChange={this.onChange.bind(this)}
        onValidate={this.onValidate.bind(this)}
        removeAction={this.removeAction.bind(this)}
        ref={this.setRef.bind(this)}
        slideId={this.props.slideId}
        slides={this.props.slides}
        showRules={this.props.showRules}
        reorderActions={this.reorderActions.bind(this)}
      />
    ));

    return actions;
  }

  render() {
    /* Remove the input reference */
    if (this.inputs) {
      delete this.inputs[1000];
    }

    return (
      <DndContext><div className={`actions-input ${this.props.className}`}>
        {/*
          <div className="input" style={{ marginBottom: 10 }}>
            <label>Action Options<Tooltip>Control the look and functionality of each of your buttons.</Tooltip></label>
          </div>
        */}

        { this.renderActions() }
        <div className="add-action">
          <div onClick={this.addAction.bind(this)}>Add Action</div>
        </div>

      </div></DndContext>
    );
  }
}

class Context extends Component {
  render() {
    return <div>{ this.props.children }</div>
  }
}

const DndContext = DragDropContext(HTML5Backend)(Context);

function mapDispatchToProps(dispatch) {
  return bindActionCreators(FlashNotificationsActions, dispatch);
}

export default connect(null, mapDispatchToProps, null, { withRef: true })(ActionsInput);
