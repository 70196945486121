import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import _ from 'lodash';
import moment from 'moment';
import qs from 'query-string';

import CreatableSelect from 'react-select/creatable';

import ColumnLoading from './ColumnLoading';

import ColumnLayout from '../components/ColumnLayout';
import Breadcrumbs from '../components/Breadcrumbs';
import SectionHeader from '../components/SectionHeader';
import SlideInfo from '../components/SlideInfo';
import Pagination from '../components/Pagination';
import Toggle from '../components/Toggle';
import SortBy from '../components/SortBy';
import HeaderButtons from '../components/HeaderButtons';
import SlideAnswers from '../components/SlideAnswers';

import ResponseEditForm from '../components/ResponseEditForm';
import ResponseRankAndApprovalInput from '../components/ResponseRankAndApprovalInput';
import * as AccountsActions from '../actions/Accounts';
import * as ResponsesActions from '../actions/Responses';

import { decode, encode, getDate, getSlideLabel, renderParticipantId, renderNumber, truncate, getSlideIcon, uuid, scrollToTop, isUrl, getDateAsTimestamp, stripTags, prepareInsights, getPoll } from '../utils';

class InsightsBlock extends Component {
  render() {
    if (!this.props.active) { return null; }

    const insights = prepareInsights(this.props.insights);

    if (!insights || insights.length === 0) {
      if (this.props.hideEmpty) {
        return null;
      }

      return (<div className="no-insights">
        <div>
          <h3>No insights generated yet.</h3>
          <p>Check back later once you have more responses.</p>
        </div>
      </div>);
    }

    const insight = insights[this.props.idx] || {};
    let { content } = insight;
    const items = content;

    let next = <div className="next" onClick={() => this.props.next() }><i className="fas fa-arrow-right" /></div>;
    let prev = <div className="prev" onClick={() => this.props.prev()}><i className="fas fa-arrow-left" /></div>

    let hasNext = true;
    let hasPrev = true;
    if (this.props.idx === 0) {
      prev = (<div className="prev disabled"><i className="fas fa-arrow-left" /></div>);
      hasPrev = false;
    }
    if (this.props.idx >= insights.length - 1) {
      next = (<div className="next disabled"><i className="fas fa-arrow-right" /></div>);
      hasNext = false;
    }
    let nextPrev = null;
    if (hasNext || hasPrev) {
      nextPrev = (<span className="next-prev">{ prev }{ next }</span>);
    }

    return (<div className="insights">
      <div className={` ${items.length > 3 ? 'has-more' : ''}`}>
        <p className="stats">{this.props.title}<span>{ getDateAsTimestamp(insight._id, 'MMMM Do YYYY, h:mm A') }</span>{nextPrev}</p>
        <ul>
        { items.map((item, idx) => {
          return <li>{ item }</li>
        }) }
        </ul>
      </div>
    </div>)
  }
}

class Response extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onBodyClick(e) {
    if (this.state.showDetails) { return; }
    this.setState({ showDetails: true });
  }

  render() {
    const account = this.props.account || {};
    const poll = this.props.poll || {};
    const slide = this.props.slide || {};
    const accountId = this.props.accountId;
    const event = this.props;

    let participantInfo = null;

    let href = null;
    if (event) {
      href = <div className="page"><a target='_blank' href={event.href}>{truncate(event.href, 40)}</a></div>
    }

    if (this.props.participant) {
      participantInfo = (<div className="participant"><a target="_blank" href={`/participants/a/${encode(this.props.accountId)}/pa/${encode(this.props.participant._id)}`}>{this.props.participant.handle || renderParticipantId(this.props.participant.id, 20) }</a></div>);
    }

    const r = this.props.response || {};
    let response = (<div>{ r.title }</div>);

    if (event.response && event.valueType !== 'vote') {
      if (typeof r.value !== 'object') {
        response = <div>{ r.value }</div>
      }
    }

    if (event.response && event.response.type === 'date') {
      response = (
        <div>{ moment(event.response.value || event.response.title).format('MM/DD/YYYY') }</div>
      );
    }

    if (event.responses) {
      if (event.valueType === 'form-response') {
        response = (<div>
          { event.responses.map(({ label, value }) => {
            if (!value) { return null; }

            return (<div className="value" style={{ clear: 'both', paddingTop: 3 }}><strong style={{ fontWeight: 700 }}>{label}</strong>: {value}</div>);
          })}
        </div>)
      } else {
        response = <div>{ event.responses.map(({ title, value }) => value || title).join(', ') }</div>
      }
    }

    if (event.response && event.response.type === 'rank') {
      response = <div>{ _.sortBy(event.responses, 'rank').map(({ title, value, rank }) => {
        return <div>#{rank} — {value || title}</div>
      })}</div>
    }

    if (event.response && event.response.type === 'file-upload') {
      response = <div>{ event.response.value.split(',').map((url) => {
        if (!url.match(/\.(jpg|jpeg|png|gif)$/i)) {
          return <div><a href={url} target="_blank">{ url }</a></div>
        } else {          
          return <div style={{ display: 'inline-block' }}><a className="image" href={url} onClick={(e) => e.stopPropagation() }><img src={url} style={{ maxHeight: 150, borderRadius: 5, lineHeight: 0 }} /></a></div>
        }
      }) }</div>
    }

    const metadata = event.metadata;
    let metadataOutput = null;

    let participantUrl = `/participants/a/${encode(this.props.accountId)}/pa/${encode(this.props.participant._id)}`;

    if (this.props.params.pollId) {
      participantUrl = `/participants/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}/pa/${encode(this.props.participant._id)}`;
    }
    if (this.props.params.slideId) {
      participantUrl = `/participants/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}/s/${encode(this.props.slideId)}/pa/${encode(this.props.participant._id)}`;
    }

    if (metadata) {
      let keys = Object.keys(metadata);
      keys = _.sortBy(keys, (key) => {
        if (key.indexOf('shopify') !== -1) { return 3 };
        if (key.indexOf('Timestamp') !== -1) { return -1 };
        if (key.indexOf('Participant') !== -1) { return -1 };
      });
      metadataOutput = (<div>
      <div className="heading-tag"><i className="fas fa-asterisk" />Metadata</div>
      { keys.map((key, idx) => {
        let val = metadata[key];
        
        if (key === 'shopify_order_id' && this.props.account.shop) {
          val = <a target="_blank" rel="noopener noreferrer" href={`https://${this.props.account.shop}/admin/orders/${val}`}>{val}</a>
        } else if (key === 'shopify_customer_id' && this.props.account.shop) {
          val = <a target="_blank" rel="noopener noreferrer" href={`https://${this.props.account.shop}/admin/customers/${val}`}>{val}</a>
        } else {
          val = isUrl(val) ? <a href={val} title={val} target="_blank">{truncate(val, 40)}</a> : val;
        }

        const style = { marginBottom: 10 };

        if (['original-referrer', 'landing-page'].indexOf(key) !== -1) {
          style.wordBreak = 'break-all';
        }

        let icon = null;
        if (key.indexOf('shopify') !== -1) {
          icon = <i className="fa-brands fa-shopify" />
        }
        return (<div className="row" style={style}>
          <label>{ icon }{ key }</label>
          <div>{ val }</div>
        </div>);
      }) }

      <div className="row" style={{ marginBottom: 10 }}>
        <label>Timestamp</label>
        <div>{getDateAsTimestamp(event._id)}</div>
      </div>

      <div className="row">
        <label>Participant</label>
        <div><a target="_blank" href={participantUrl}>{this.props.participant.handle || renderParticipantId(this.props.participant.id, 20, true) }</a></div>
      </div>

      </div>);
    }

    let metadataToggle = (
      <div className="show-more" onClick={() => {
      this.setState({ showDetails: !this.state.showDetails });
      }}>{ this.state.showDetails ? <span><i className="fas fa-minus" />Hide Details</span> : <span><i className="fas fa-plus"/>Show Details</span> }</div>
    );

    let participantLink = null;

    if (this.props.participant.handle) {
      participantLink = (<div className="inline-participant">·<i className="fas fa-user" /><a target="_blank" href={`/participants/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}/s/${encode(this.props.slideId)}/pa/${encode(this.props.participant._id)}`}>{this.props.participant.handle || renderParticipantId(this.props.participant.id, 20, true) }</a></div>);
    }

    console.log('response');
    console.log(this.props);

    return (<div className={`object-list events`} onClick={this.onBodyClick.bind(this)} style={{ marginBottom: 5, marginLeft: 0 }}>

      {/*
        <div className="events-meta"><i className="fas fa-chart-pie" />Survey: <Link to={`/a/${encode(accountId)}/p/${encode(poll._id)}`}>{ poll.title }</Link></div>
      */}

      {/*
        <div className="row">
          <label>Slide <span>/ {getSlideLabel(this.props.slide.type)}</span></label>
          <p><Link to={`/a/${encode(this.props.account._id)}/p/${encode(this.props.poll._id)}/s/${encode(this.props.slide._id)}`}>{ this.props.slide.handle || this.props.slide.title }</Link></p>
        </div>
      */}

        <div className="row top">
          <div className="meta-brief">
            <div className="date">{ getDateAsTimestamp(event._id) }{ participantLink }</div>
            { metadataToggle }
          </div>

          {/*<div className="heading-tag"><i className="fas fa-comment-alt" />Response</div>*/}
          {/*<label className="block"><i className={`fas ${getSlideIcon(slide.type)}`} /><Link to={`/a/${encode(account._id)}/p/${encode(poll._id)}/s/${encode(slide._id)}`}>{ truncate(slide.handle || slide.title, 30) }</Link></label>*/}
          <div style={{ wordBreak: 'break-word' }}>{ response }</div>

          {/*
          <div className="object-metadata">
            <div className="time">{getDate(this.props._id)}</div>
            { href }
            { participantInfo }
          </div>
          */}
        </div>
    
        <div style={{ display: this.state.showDetails ? 'block' : 'none' }}>
          { metadataOutput }
        </div>

        { this.props.responseId && <div style={{ display: this.state.showDetails ? 'block' : 'none' }}>
          <ResponseEditForm
            { ...this.props }
          />
        </div>}

        { this.props.responseId && this.props.slide && this.props.slide.showResponses && <ResponseRankAndApprovalInput
          active={this.state.showDetails}
          showResponses={this.props.slide.showResponses}
          showRankInput={this.props.slide.settings.sortBy === 'manual'}
          { ...this.props }
        /> }
    </div>);
  }
}

class Responses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortBy: props.sortBy,
      page: props.page,
      insightIdx: 0,
      responseInsightIdx: 0,
      responseFilter: props.filter || '',
      pollId: props.pollId,
      slideId: props.slideId,
      isFirst: true
    };

    let page = 0;
    if (props.page) {
      page = props.page - 1;
    }

    // props.fetchAccount(props.accountId);
    props.fetchAccountWithPolls(props.accountsId)
    .then(() => this.fetchAccountResponses(page))
    .then(() => {
      const polls = this.props.account.polls || [];
      if (!polls.length) { return; }

      const pollId = this.state.pollId || polls[0]._id;

      const poll = getPoll(this.props.polls, pollId);
      let slide;
      if (!this.state.slideId) {
        const slides = poll.slides || [];
        slide = slides[0] || {};
      }

      this.setState({
        pollId: poll._id,
        slideId: this.state.slideId || slide._id,
        isFirst: false
      })
    });
  }

  onChange(state) {
    this.setState(state, () => {
      this.fetchAccountResponses();
    });
  }

  fetchAccountResponses(page = 0) {
    return this.props.fetchAccountResponses(page, this.state.sortBy, this.state.responseFilter, this.state.pollId, this.state.slideId, this.state.isFirst)
  }

  export(e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.exportResponses(this.props.accountId, this.state.pollId, this.state.slideId, this.state.responseFilter);
  }

  renderInsights() {
    const pollId = this.state.pollId || this.props.account.polls[0]._id;
    const poll = getPoll(this.props.polls, pollId);

    const slideId = this.state.slideId;
    let slide = _.find(poll.slides, ({ _id }) => slideId === _id ) || { insights: [] };

    /* Use slide on reducer if it exists (hack to get date toggle to work.) . */
    if (this.props.slides[slideId]) {
      slide = { ...slide, ...this.props.slides[slideId] };
    }

    /* Replace with slide insights... poll as fallback... */
    /* Replace this so that you get both and they are listed on top of one another... ex. "top ten common responses are..."*/
    let insights = slide.insights;
    let responseInsights = slide.responseInsights;

    // if (slide && slide.insights) {
    // insights = slide.insights;
    // }

    // insights = _.groupBy(slide.insights, ({ type }) => {
    //   if (type === undefined) {
    //     return "general";
    //   }
    //   return type;
    // });


    // const insightGroup = insights[this.state.responseFilter === 'open-ended' ? "response" : "general"] || [];
    return <div className="insights-column">  
      <SlideAnswers
        accountId={this.props.accountId}
        pollId={this.state.pollId}
        slide={slide}
        condensed={true}
        includeFilter={true}
      />
      <InsightsBlock
        insights={responseInsights}
        idx={this.state.responseInsightIdx}
        next={() => this.setState({ responseInsightIdx: ++this.state.responseInsightIdx })}
        prev={() => this.setState({ responseInsightIdx: --this.state.responseInsightIdx })}
        hideEmpty={true}
        // active={this.props.filter === 'open-ended'}
        active={true}
        title={<span className="title"><i className="fas fa-arrow-trend-up" />Open-ended response trends</span>}
      />
      <InsightsBlock
        insights={insights}
        idx={this.state.insightIdx}
        next={() => this.setState({ insightIdx: ++this.state.insightIdx })}
        prev={() => this.setState({ insightIdx: --this.state.insightIdx })}
        active={true}
        title={<span className="title"><i className="fas fa-wand-magic-sparkles" />Slide insights</span>}
      />
    </div>
  }

  renderNotReady() {
    return (
      <ColumnLayout
        title={`Responses`}
        className="account-responses"
        graphics={true}
      >
        <div style={{ minWidth: 1000, padding: '0 20px' }}>
          <div className="section-header no-margin">
            <div className="top">
              <div className="section-subtitle">
                <span>
                  <i className="fas fa-comment-alt" />Responses
                </span>
              </div>
            </div>

            <div style={{ lineHeight: '18px', fontSize: 16 }} className="section-description">View all your responses across all of your surveys.</div>
          </div>

          <div className="card empty-object-list responses">
            <h3>No Responses Yet</h3>
            <p>A list will show up here when people have responded to your surveys. Try creating a survey or making your existing surveys live in order to collect data.</p>
          </div>
        </div>
      </ColumnLayout>
    );
  }

  render() {
    if (this.props.account._id && (!this.props.account.polls || this.props.account.polls.length === 0)) {
      return this.renderNotReady();
    }
    if (!this.props.responses || !this.props.polls || !this.props.account) {
      return <ColumnLoading />
    }

    let results;
    let header = null;
    let footer = null;

    let sortOptions = [
      { label: "Newest to Oldest", value: 'new-old' },
      { label: "Oldest to Newest", value: 'old-new' },
    ];

    const pollId = this.state.pollId || this.props.account.polls[0]._id;
    const poll = getPoll(this.props.polls, pollId);
    const slides = poll.slides || [];

    let slideBrief = null;
    const slideId = this.state.slideId;
    const slide = _.find(poll.slides, ({ _id }) => slideId === _id );

    let responseFilter = <div className="select-wrapper"><select 
      value={this.state.responseFilter}
      style={{ maxWidth: 'none' }}
      onChange={(e) => {
        e.preventDefault();
        e.stopPropagation();

        const responseFilter = e.target.value;
        this.onChange({ responseFilter, insightIdx: 0, responseInsightIdx: 0 });
    }}>
      <option value={''}>Open & Fixed Responses</option>
      <option value={'open-ended'}>Open-Ended responses</option>
      <option value={'fixed-choice'}>Fixed-Choice Responses</option>
    </select></div>

    if (this.props.responses.data.length) {
      header = (
        <div className="responses-header pages">
          <SortBy
            onChange={(value) => {
              this.onChange({ sortBy: value })
            }}
            value={this.state.sortBy}
            options={sortOptions}
          />
          <Pagination
            showPagination={this.props.responses.pages}
            showNext={this.props.responses.page !== this.props.responses.pages}
            showPrev={this.props.responses.page !== 0}
            next={() => { this.fetchAccountResponses(this.props.responses.page + 1); }}
            prev={() => { this.fetchAccountResponses(this.props.responses.page - 1); }}
            curPage={this.props.responses.page + 1 }
            pageLength={this.props.responses.pages + 1}
          />
        </div>
      );

      // let ids = [];
      // this.props.responses.data.forEach(({ event }) => {
      //   if (event._id) {
      //     ids.push(event._id);
      //   } else {
      //     const id = uuid()
      //     event._id = id;
      //     ids.push(id);
      //   }
      // });

      // ids = _.uniq(ids);

      // const responses = [];
      // ids.forEach((eventId) => {
      //   const response = _.find(this.props.responses.data, ({ event }) => event._id === eventId);
      //   responses.push(response);
      // });

      // const responses = this.props.responses.data.map((response, idx) => {
      //   let next = this.props.responses.data[idx+1];

      //   if (!next) { 
      //     return response;
      //   }

      //   if (!response.event._id) {
      //     return response;
      //   }

      //   if (response.event._id === next.event._id) {
      //     return null;
      //   }

      //   return response;
      // });

      footer = (
        <div className="responses-footer">
          <Pagination
            showPagination={this.props.responses.pages}
            showNext={this.props.responses.page !== this.props.responses.pages}
            showPrev={this.props.responses.page !== 0}
            next={() => { this.fetchAccountResponses(this.props.responses.page + 1).then(scrollToTop); }}
            prev={() => { this.fetchAccountResponses(this.props.responses.page - 1).then(scrollToTop); }}
            curPage={this.props.responses.page + 1 }
            pageLength={this.props.responses.pages + 1}
          />
        </div>
      );

      results = (
      <div className={`responses-wrapper ${this.props.loading ? 'loading' : ''}`}>
        { this.renderInsights() }
        <div className={`responses`}>
          <p className="responses-title">Latest Responses</p>
          {this.props.responses.data.map((res) => {
            if (!res) { return null; }
            if (!res.participant) { res.participant = { handle: '[REMOVED]' }; }

            return (<Response {...res} setApproval={this.props.setApproval} account={this.props.account} slideId={this.state.slideId} pollId={this.state.pollId} key={res._id} slide={slide} params={this.props.params} submitTags={this.props.submitTags} />);
          })}
          { footer }
        </div>
      </div>
      );
      // footer = (
      //   <div className="responses-footer">
      //     <button
      //       onClick={this.export.bind(this)}
      //     >Download CSV</button>
      //   </div>
      // );
    } else {
      results = (
        <div className="card empty-object-list responses">
          <h3>No Responses Yet</h3>
          <p>A list will show up here when people have responded.</p>
        </div>
      );
    }

    let pollSelector = <div className="select-wrapper"><select
      value={this.state.pollId}
      onChange={(e) => {
        e.preventDefault();
        e.stopPropagation();

        const pollId = e.target.value;
        const poll = getPoll(this.props.polls, pollId);
        const slides = poll.slides || [];
        const slideId = slides[0] ? slides[0]._id : undefined;

        this.onChange({ pollId, slideId: slideId, page: 0, insightIdx: 0, responseInsightIdx: 0, responseFilter: '' })
      }}
    >
      { this.props.account.polls.map((poll) => 
        <option value={poll._id}>{poll.title}</option>
      ) }
    </select></div>

    let slideSelector = <div className="select-wrapper"><select
    value={this.state.slideId}
    onChange={(e) => {
      e.preventDefault();
      e.stopPropagation();

      const slideId = e.target.value;
      this.onChange({ slideId, page: 0, insightIdx: 0, responseInsightIdx: 0, responseFilter: '' })
    }}>
      { slides.map((slide) => 
        <option value={slide._id}>{stripTags(slide.handle || slide.title)}</option>
      ) }
      { slides.length === 0 && <option selected={true} value={''} disabled={true}>No slides exist</option>}
    </select></div>

    if (this.props.responses.data.length && slide) {
      slideBrief = <div className="slide-brief">
        <i className="fas fa-quote-left" />

        <div className="title">{ stripTags(slide.title) }</div>
        { slide.copy && <div className="copy">{ stripTags(slide.copy) }</div> }

        <div className="type"><i className={`fas ${ getSlideIcon(slide.type) }`} />{ getSlideLabel(slide.type) }</div>
      </div>
    }

    return (
      <ColumnLayout
        title={`Responses`}
        className="account-responses"
        graphics={true}
      >
        <div style={{ minWidth: 1000, padding: '0 20px' }}>
          <div className="section-header no-margin wide left">
            <div className="top">
              <div className="section-subtitle">
                <span>
                  <i className="fas fa-comment-alt" />Responses
                </span>
              </div>
            </div>

            <div className="section-description">
              Showing { responseFilter } for survey { pollSelector } and slide { slideSelector }
            </div>
          </div>

          <div className="top-wrapper">
            { slideBrief }
            { header }
          </div>

          { results }

          <HeaderButtons>
            <button
              onClick={this.export.bind(this)}
              disabled={this.props.responses.data.length ? false : true}
            >Download CSV</button>
          </HeaderButtons>
        </div>
      </ColumnLayout>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const accountId = decode(ownProps.match.params.accountId);
  const accounts = state.accounts || {};
  const account = accounts[accountId] || {};

  const slides = state.slides || {};

  const allResponses = state.responses || [];
  const responses = allResponses[accountId];

  const params = qs.parse(ownProps.location.search);
  const page = parseInt(params.page) || 0;
  const sortBy = params.sortBy;
  const filter = params.filter;
  const pollId = decode(params.pollId) || decode(ownProps.match.params.pollId);
  const slideId = decode(params.slideId) || decode(ownProps.match.params.slideId);

  return {
    accountId,
    account,
    slides,
    params: ownProps.match.params,
    polls: account.polls || [],
    responses,
    pollId,
    slideId,
    page,
    filter,
    sortBy,
    loading: state.responses.loading
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...AccountsActions, ...ResponsesActions }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Responses));
