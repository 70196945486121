import _ from 'lodash';

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';

import { NameInput } from './SimpleInputs';
import NextSlideInput from './NextSlideInput';

import Modal from './Modal';

import * as FlashNotificationsActions from '../actions/FlashNotifications';

import { handleize, move } from '../utils';

import { DragDropContext, DragSource, DropTarget } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

/* Drag */
const dragSource = {
  beginDrag(props) {
    return {
      idx: props.idx
    };
  }
};

const dragCollect = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  }
};

let direction;
const dropTarget = {
  hover(props, monitor, component) {
    const dragIdx = monitor.getItem().idx;
    let dropIdx = props.idx;

    if (dragIdx < dropIdx) {
      direction = 'down';
      component.forceUpdate();
    } else if (dragIdx > dropIdx) {
      direction = 'up';
      component.forceUpdate();
    }
  },
  drop(props, monitor, component) {
    const dragIdx = monitor.getItem().idx;
    let dropIdx = props.idx;

    if (dragIdx === dropIdx) {
      return;
    }

    let idx;
    if (dragIdx < dropIdx) {
      idx = dropIdx - 1 < 0 ? 0 : dropIdx;
      props.reorderAnswers(dragIdx, idx);
    } else {
      idx = dropIdx + 1 >= props.pollCount ? props.pollCount - 1 : dropIdx;
      props.reorderAnswers(dragIdx, idx);
    }
  }
};

const dropCollect = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver()
})

class DumbDragHandle extends Component {
  render() {
    const { connectDragSource } = this.props;
    return connectDragSource(<span className="handle" />)
  }
}

class DumbDropContext extends Component {
  render() {
    const { connectDropTarget, isOver } = this.props;
    return connectDropTarget(<div className={`drop-context ${isOver ? `is-over ${direction}` : ''}`}>{ this.props.children }</div>);
  }
}

const DragHandle = compose(
  DragSource('ANSWER', dragSource, dragCollect),
)(DumbDragHandle);

const DropContext = compose(
  DropTarget('ANSWER', dropTarget, dropCollect)
(DumbDropContext))

class Answer extends Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false };
  }

  onChange(e) {
    this.props.onChange(e, this.props.idx);
  }

  onValidate(e) {
    this.props.onValidate(e, this.props.idx);
  }

  setRef(element) {
    if (!this.inputs) {
      this.inputs = {};
    }
    if (element) {
      this.inputs[element.props.name] = element;
    }
  }

  validate() {
    let valid = true;

    for (const key in this.inputs) {
      if (!this.inputs[key].validate()) {
        valid = false;
      }
    }

    return valid;
  }

  removeAnswer(e) {
    this.props.removeAnswer(this.props.idx);
  }

  showModal() {
    this.setState({ showModal: true });
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  render() {
    return <DropContext
      reorderAnswers={this.props.reorderAnswers}
      idx={this.props.idx}
    ><div className="answer">
      <DragHandle idx={this.props.idx} />
      <NameInput
        onChange={this.onChange.bind(this)}
        onValidate={this.onValidate.bind(this)}
        label={`Answer #${this.props.idx + 1}`}
        type="text"
        placeholder="Public facing title for the answer"
        name="title"
        value={this.props.title}
        errorMessage="Please a title for the question."
        ref={this.setRef.bind(this)}
        // maxlength={40}
        // extraComponent={<NextSlideInput
        //   slides={this.props.slides}
        //   nextSlide={this.props.nextSlide}
        //   idx={this.props.idx}
        //   value={this.props.title}
        //   onChange={this.props.onChange.bind(this)}
        // />}
      />
      { this.props.showAdvancedInputs && (<div className="clearfix">
        <NameInput
          onChange={this.onChange.bind(this)}
          onValidate={this.onValidate.bind(this)}
          label={`Handle`}
          type="text"
          placeholder="Unique handle for the answer"
          name="handle"
          value={this.props.handle}
          errorMessage="Please a handle for the answer option."
          ref={this.setRef.bind(this)}
          style={{ marginTop: -20, width: '100%', paddingRight: 0, float: 'left' }}
        />
      </div>) }
      <div className="answer-actions">
        <div className={`advanced-edit ${this.props.showAdvancedInputs ? 'active' : ''}`} onClick={() => this.props.toggleAdvancedInputs(this.props.idx)}><i className="fas fa-edit" />Advanced Edit</div>
        <div className="remove" onClick={() => {
          if (this.props.votes) {
            this.showModal();
          } else {
            this.removeAnswer();
          }
        }} ><i className="fas fa-trash" />Remove</div>
      </div>
      <Modal 
        isOpen={this.state.showModal}
        onRequestClose={this.closeModal.bind(this)}
      >
        <div className="frame">
          <div className="close" onClick={this.closeModal.bind(this)} />
          <div className="title">Are you sure?</div>
          <div className="content">
            <div className="subtitle">This will remove the question and all responses to it.</div>
            <div className="actions">
              <button className="positive" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                this.removeAnswer();
                this.closeModal();
              }}>Yes</button>
              <button className="negative" onClick={this.closeModal.bind(this)}>No</button>
            </div>
          </div>
        </div>
      </Modal>
    </div></DropContext>
  }
}

class AnswersInput extends Component {
  constructor(props) {
    super(props);

    let answers = [...props.answers, { title: '', handle: '' }, { title: '', handle: '' }];

    this.state = {
      answers: answers.slice(0, 2),
      advancedInputs: []
    };
  }

  reset() {
    this.setState({ valid: undefined, invalid: undefined });
  }

  removeAnswer(idx) {
    const answers = this.state.answers;
    answers[idx] = { title: '', handle: '' };
    this.setState({ answers }, this.update.bind(this));
  }

  onChange(e, idx) {
    const answers = _.cloneDeep(this.state.answers);
    const answer = answers[idx];
    answer[e.target.name] = e.target.value;

    if (e.target.name === 'title' && this.state.advancedInputs.indexOf(idx) === -1) {
      answer.handle = handleize(e.target.value, this.props.slideId);
    }

    this.setState({ answers }, this.update.bind(this));
  }

  update() {
    const answers = [ ...this.state.answers ];
    if (this.state.showDynamicAnswer && this.state.dynamicAnswer.handle)  {
      const dynamicAnswer = this.state.dynamicAnswer;
      answers.push(dynamicAnswer);
    }
    const e = { target: { name: 'answers', value: answers } };
    this.props.onChange(e)
  }

  onValidate(e, idx) {
    this.props.onValidate('answers', true);
  }

  setRef(element) {
    if (!this.inputs) {
      this.inputs = {};
    }

    if (element) {
      this.inputs[element.props.idx] = element;
    }
  }

  reorderAnswers(dragIdx, dropIdx) {
    const answers = move(this.state.answers, dragIdx, dropIdx);
    this.setState({ answers, advancedInputs: [] }, this.update.bind(this));
  }

  validate() {
    let valid = true;

    for (const key in this.inputs) {
      if (!this.inputs[key].validate()) {
        valid = false;
      }
    }

    if (valid) {
      let uniq = _.uniqBy(this.state.answers, answer => answer.handle);
      if (uniq.length !== this.state.answers.length) {
        const e = { target: { name: 'answers', value: uniq } };
        this.props.onChange(e)

        this.props.flash('Duplicate answers detected. They were automatically removed.', 'warning');

        uniq = [...uniq, { title: '', handle: '' }, { title: '', handle: '' }];
        this.setState({ answers: uniq.slice(0, 2) });

        valid = false;
      }
    }

    return valid;
  }

  toggleAdvancedInputs(index) {
    let advancedInputs = this.state.advancedInputs || [];
    let idx = advancedInputs.indexOf(index);
    if (idx !== -1) {
      advancedInputs.splice(idx, 1);
    } else {
      advancedInputs.push(index);
    }
    this.setState({ advancedInputs })
  }

  renderAnswers() {
    let answers = null;

    answers = this.state.answers.map(({ title, handle, value, nextSlide }, idx) => (
      <Answer 
        key={idx}
        title={title}
        value={value}
        handle={handle}
        nextSlide={nextSlide}
        idx={idx}
        onChange={this.onChange.bind(this)}
        onValidate={this.onValidate.bind(this)}
        removeAnswer={this.removeAnswer.bind(this)}
        ref={this.setRef.bind(this)}
        slideId={this.props.slideId}
        slides={this.props.slides}
        reorderAnswers={this.reorderAnswers.bind(this)}
        toggleAdvancedInputs={this.toggleAdvancedInputs.bind(this)}
        showAdvancedInputs={this.state.advancedInputs.indexOf(idx) !== -1}
      />
    ));

    return answers;
  }

  render() {
    return (
      <DndContext><div className={`answers-input ${this.props.className}`}>
        { this.renderAnswers() }
      </div></DndContext>
    );
  }
}

class Context extends Component {
  render() {
    return <div>{ this.props.children }</div>
  }
}

const DndContext = DragDropContext(HTML5Backend)(Context);

function mapDispatchToProps(dispatch) {
  return bindActionCreators(FlashNotificationsActions, dispatch);
}

export default connect(null, mapDispatchToProps, null, { withRef: true })(AnswersInput);
