import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import _ from 'lodash';

import * as FlashNotificationActions from '../actions/FlashNotifications';
import * as PollActions from '../actions/Polls';

import CreatableSelect from 'react-select/creatable';

import { NameInput, DisplayRulesInput, RecipientsInput } from './SimpleInputs';
import { AuthSubmitButton } from './Buttons';
import Toggle from './Toggle';
import Confirm from './Confirm';
import Tooltip from './Tooltip';
import Switch from './Switch';
import DateInput from './DateInput';
import NumberInput from './NumberInput';
import AccountSettingsVisualDisplay from '../components/AccountSettingsVisualDisplay';

import HeaderButtons from './HeaderButtons';
import AuthForm from './AuthForm';
import SectionHeader from './SectionHeader';

import PublicPollLinkCard from '../components/PublicPollLinkCard';
import JSSnippets from '../components/JSSnippets';
import CodeBlock from '../components/CodeBlock';
import EmailSettingsVisualDisplay from '../components/EmailSettingsVisualDisplay';
import SmsSettingsVisualDisplay from '../components/SmsSettingsVisualDisplay';
import Embed from '../components/Embed';
import PagePreview from '../components/PagePreview';
import BrowserFrame from '../components/BrowserFrame';

import SingleImageUploader from './SingleImageUploader';

import { DisplayRulesTemplatesInput } from '../components/TemplateInputs';

import { displayRulesTemplates, defaultEmailDisplaySettings, defaultSmsDisplaySettings } from '../settings';
import { encode, getTemplateFromDisplayRules, prepareShopifyCustomerTargeting, customerTargetingOptions, shopifyCustomerTargetingOptions, getRecipients, getPollDisplayType, generatePageDisplaySettings, previewSlides } from '../utils';

import topPlacement from '../images/ppp_1.png';
import bottomPlacement from '../images/ppp_2.png'
import bottomRightPlacement from '../images/ppp_3.png'

import CreateEmailTemplateModal from './CreateEmailTemplateModal';

class PollTypeOption extends Component {
  render() {
    let selected;
    if (this.props.active) {
      selected = <div className="selected"><i className="fas fa-check" />Selected</div>
    }
    return (<div className={`block ${this.props.active ? 'active' : ''}`} onClick={() => this.props.onChange(this.props.handle)}>
      { this.props.active ? selected : null}
      <div className="block-icon-wrapper">
        <div className="block-icon"><i className={`fas ${this.props.icon}`} /></div>
      </div>
      <div className="block-title">{this.props.title}</div>
      <div className="block-subtitle">{this.props.subtitle}</div>
    </div>)
  }
}

const pollTypes = [
{
  title: "On-site Survey",
  subtitle: "Easily include the survey as a small pop-up on your site.",
  icon: 'fa-poll',
  handle: "widget"
},
{
  title: "Site Embed",
  subtitle: "Embed the survey within a designated spot on your webpage.",
  icon: 'fa-arrows-to-dot',
  handle: "embed"
},
{ 
  title: "Link",
  subtitle: "Send your survey to your customers as a link they can fill out.",
  icon: 'fa-link',
  handle: "link"
},
{ 
  title: "API",
  subtitle: "Load your survey programatically using javascript.",
  icon: 'fa-code',
  handle: "api"
},
{ 
  title: "Email",
  subtitle: "Email your survey to your customers after certain events.",
  icon: 'fa-envelope',
  handle: "email",
  shopify: true
},
{ 
  title: "SMS",
  subtitle: "Send your survey via SMS to customers after certain events.",
  icon: 'fa-sms',
  handle: "sms",
  shopify: true
}
];

const applyTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: "#2167f5",
    danger: "#F26c57",
  }
});

class TargetingRow extends Component {
  render() {
    const [ value, operator, inputVal ] = this.props.row;
    console.log(this.props.row);
    const selectedOption = _.find(shopifyCustomerTargetingOptions, (option) => option.value === value);

    let operators;
    let inputType = null;
    if (selectedOption) {
      inputType = selectedOption.inputType;
      operators = <div className="select-wrapper"><select value={operator} onChange={(e) => { this.props.onChange(e.target.value, 1, this.props.rowIdx, this.props.sectionIdx) }}>
      { selectedOption.operators.map((operator) => <option value={operator.value}>{operator.name}</option>) }
      </select></div>
    }

    let input = null;

    if (value) {
      input = <input type="text" value={inputVal} onChange={(e) => { this.props.onChange(e.target.value, 2, this.props.rowIdx, this.props.sectionIdx) }} />;

      if (inputType === 'number') {
        input = <NumberInput
          value={inputVal}
          onChange={(value) => { 
            console.log('onchange');
            console.log(value);
            this.props.onChange(value, 2, this.props.rowIdx, this.props.sectionIdx) }} />
      }
      if (inputType === 'date') {
        input = <DateInput
          type="date"
          value={inputVal}
          onChange={(value) => { console.log('on change'); console.log(value); this.props.onChange(value, 2, this.props.rowIdx, this.props.sectionIdx) }} />
      }
      if (inputType === 'tags') {
        let list = [];
        if (inputVal) {
          list = inputVal.split(',').map((val) => ({ label: val, value: val }));
        }

        input = <CreatableSelect
          value={list}
          isMulti
          name="react-select"
          placeholder={selectedOption.placeholder || 'Add tags...'}
          className={true ? 'react-select active' : 'react-select'}
          // onMenuOpen={() => this.setState({ isMenuOpen: true })}
          // onMenuClose={() => this.setState({ isMenuOpen: false })}
          theme={applyTheme}
          components={{ 
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null
           }}
          onChange={(value) => {
            let val = value;
            if (value) {
              val = value.map(({ value }) => _.trim(value)).join(',');
            }
            this.props.onChange(val, 2, this.props.rowIdx, this.props.sectionIdx);
          }}
        />
      }
    }

    return (
    <div className="targeting-row">
      {/*<div>{ value }{ operator }{ inputVal }</div>*/}

      <div className="select-wrapper"><select value={value} onChange={(e) => {
        const value = e.target.value;
        const selectedOption = _.find(shopifyCustomerTargetingOptions, (option) => option.value === value);
        this.props.onChange(value, 0, this.props.rowIdx, this.props.sectionIdx, selectedOption.operators[0].value);
      }}>
        <option disabled="disabled" selected={true}>Select a condition...</option>
        { shopifyCustomerTargetingOptions.map((option) =>{
          if (option.requireOrder && !this.props.hasOrder) { return null }
          if (option.requireCustomer && !this.props.hasCustomer) { return null }
          return (<option value={option.value}>{ option.name }</option>);
        }
        )}
      </select></div>

      { operators }

      { input }

      { value ? <div className="remove" onClick={() => this.props.removeRow(this.props.rowIdx, this.props.sectionIdx)} ></div> : null }
    </div>);
  }
}

class ShopifyCustomerTargetingForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nextTopInput: false,
      nextBottomInput: false
    };
  }

  removeRow(rowIdx, sectionIdx) {
    const updates = [ ...this.props.shopifyCustomerTargeting ];
    const section = [ ...updates[sectionIdx] ];
    section.splice(rowIdx, 1);

    if (section.length === 0) {
      if (sectionIdx === 0) {
        this.setState({ nextTopInput: true });
      } else {
        this.setState({ nextBottomInput: true });        
      }
    }

    updates[sectionIdx] = section;
    this.props.onChange(updates);
  }

  onChange(value, updateIdx, rowIdx, sectionIdx, operator) { 
    const updates = [ ...this.props.shopifyCustomerTargeting ];
    const isFirst = rowIdx === undefined;

    if (isFirst) {
      if (sectionIdx === 0) {
        this.setState({ nextTopInput: false });
      } else {
        this.setState({ nextBottomInput: false });        
      }
      updates[sectionIdx].push([]);
      rowIdx = updates[sectionIdx].length - 1;
    }

    const val = updates[sectionIdx][rowIdx];
    val[updateIdx] = value;

    /* Operator, value, and input move in lock step */
    if (operator) {
      val[1] = operator;
      val[2] = undefined;
    }

    updates[sectionIdx][rowIdx] = val;


    this.props.onChange(updates);
  }

  render() {
    let [ top, bottom ] = this.props.shopifyCustomerTargeting;


    let bottomSection = null;
    if (bottom.length !== 0 || this.state.showAnd) {
      bottomSection = <div className="bottom">{ bottom.map((row, idx) => <TargetingRow hasCustomer={this.props.hasCustomer} hasOrder={this.props.hasOrder} key={idx} removeRow={this.removeRow.bind(this)} onChange={this.onChange.bind(this)} row={row} sectionIdx={1} rowIdx={idx} /> )}
        { this.state.nextBottomInput || bottom.length === 0 ? <TargetingRow hasCustomer={this.props.hasCustomer} hasOrder={this.props.hasOrder} key={-1} removeRow={this.removeRow.bind(this)} onChange={this.onChange.bind(this)} row={[]} sectionIdx={1} isLast={true}/> : null }
        { this.state.nextBottomInput ? null : <div className="or-button" onClick={() => this.setState({ nextBottomInput: true })}>OR</div>}</div>
    }

    return (
      <div className="card targeting-form" style={{ marginBottom: 25 }}>
        { top.map((row, idx) => <TargetingRow hasCustomer={this.props.hasCustomer} hasOrder={this.props.hasOrder} key={idx} removeRow={this.removeRow.bind(this)} onChange={this.onChange.bind(this)} row={row} sectionIdx={0} rowIdx={idx} /> )}
        { this.state.nextTopInput || top.length === 0 ? <TargetingRow hasCustomer={this.props.hasCustomer} hasOrder={this.props.hasOrder} key={-1} removeRow={this.removeRow.bind(this)} onChange={this.onChange.bind(this)} row={[]} sectionIdx={0} isLast={true}/> : null }
        { this.state.nextTopInput ? null : <div className="or-button" onClick={() => this.setState({ nextTopInput: true })}>OR</div> }

        <div className="and-button-wrapper"><div className="optional">Optional</div><div className="and-button" onClick={() => this.setState({ showAnd: true, nextBottomInput: true })}>AND</div></div>

        { bottomSection }
      </div>
    );
  }
}


class PollTypeSelector extends Component {
  constructor(props) {
    super(props);
    this.state = { active: false }
  }

  render() {
    let selectedType;

    return (<div className={`blocks-input ${this.state.active ? 'editable' : 'condensed'}`} style={{ marginBottom: 25, width: this.props.isShopify ? 700 : 950 }}>
      { pollTypes.map((type) => {
        if (!this.props.isShopify && type.shopify) { return null; }
        if (this.props.selectedType === type.handle) {
          return (<div className="selected"><PollTypeOption
            active={this.props.selectedType === type.handle}
            onChange={this.props.onChange} {...type} /></div>)
        }
        return null;
      })}

      { !this.state.active ? <div className="edit-block-list" onClick={() => this.setState({ active: true })}><i className="fas fa-plus" />Show more formats</div> : <div className="edit-block-list" onClick={() => this.setState({ active: false })}><i className="fas fa-minus" />Hide Formats</div> }

      { pollTypes.map((type) => {
        if (!this.props.isShopify && type.shopify) { return null; }

        return (<PollTypeOption
          active={this.props.selectedType === type.handle}
          onChange={this.props.onChange} {...type} />)
      })}

    </div>);
  }
}

class PollEditForm extends AuthForm {
  constructor(props)  {
    super(props);

    let showPageExcludeRulesInput = false;
    if (this.props.poll.pageExcludeRules && this.props.poll.pageExcludeRules.length) {
      showPageExcludeRulesInput = true;
    }

    let pollType = 'widget';
    if (this.props.poll.settings && this.props.poll.settings.selector) {
      pollType = 'embed';
    }
    if (this.props.poll.settings && this.props.poll.settings.apiOnly) {
      pollType = 'link';
    }
    if (this.props.poll.settings && this.props.poll.settings.apiOnly && this.props.poll.settings.pollType === 'api') {
      pollType = 'api';
    }
    if (this.props.poll.settings && this.props.poll.settings.shopifyAbandonedCheckout) {
      pollType = 'abandoned-checkout';
    }
    if (this.props.poll.settings && this.props.poll.settings.shopifyAbandonedCheckout) {
      pollType = 'email';
    }
    if (this.props.poll.settings && this.props.poll.settings.shopifyWebhooks) {
      pollType = 'email';
    }
    if (this.props.poll.settings && this.props.poll.settings.sms) {
      pollType = 'sms';
    }

    const match = _.find(customerTargetingOptions, ({ value }) => _.isEqual(value, this.props.poll.settings.shopifyCustomerTargeting || [[],[]]));
    let shopifyCustomerTargetingSettingsType = match ? 'basic' : 'advanced';
    if (typeof this.props.poll.settings.shopifyCustomerTargeting === 'string') {
      shopifyCustomerTargetingSettingsType = 'basic';
    }

    this.state = { 
      inputs: {
        title: this.props.poll.title,
        pageRules: this.props.poll.pageRules,
        pageExcludeRules: this.props.poll.pageExcludeRules || [],
        recipients: this.props.poll.recipients,
        settings: this.props.poll.settings
      },
      showPageExcludeRulesInput,
      valid: undefined,
      pollType: pollType,
      shopifyCustomerTargetingSettingsType,
      pollLocationsType: getTemplateFromDisplayRules(this.props.poll.pageRules, this.props.poll.pageExcludeRules) ? 'template' : 'manual'
    };

    if (this.props.account.shop === undefined) {
      this.state.pollLocationsType = 'manual';
    }

    this.inputStates = {};
    this.setInitialState();
  }

  updateSettings(settings) {
    const inputs = this.state.inputs;
    inputs.settings = settings;
    this.setState({ inputs });
  }

  submitAction() {
    const poll = {};

    poll.title = this.state.inputs.title;
    poll.pageRules = this.state.inputs.pageRules;
    poll.recipients = this.state.inputs.recipients;
    poll.settings = this.state.inputs.settings;
    poll.pageExcludeRules = this.state.inputs.pageExcludeRules;

    poll._id = this.props.poll._id;
    poll.slides = this.props.poll.slides;

    const settings = poll.settings;
    const shopifyCustomerTargeting = prepareShopifyCustomerTargeting(settings.shopifyCustomerTargeting || '');

    if (shopifyCustomerTargeting) {
      let isValid = true;
      shopifyCustomerTargeting[0].forEach((rule) => {
        if (rule.length !== 3) {
          this.props.flash('Please complete each targeting rule.');
          isValid = false;
          return;
        }
        rule.map((val) => {
          if (val === undefined || val === null) {
            this.props.flash('Please complete each targeting rule.');
            isValid = false;
            return;
          }
        });
      });
      shopifyCustomerTargeting[1].forEach((rule) => {
        if (rule.length !== 3) {
          this.props.flash('Please complete each targeting rule.');
          isValid = false;
          return;
        }
        rule.map((val) => {
          if (val === undefined || val === null) {
            this.props.flash('Please complete each targeting rule.');
            isValid = false;
            return;
          }
        });
      });

      if (!isValid) {
        return;
      }
    }

    let formData;
    if (poll.settings.localLogoImage) {
      formData = new FormData();
      formData.append('logoImage', poll.settings.localLogoImage);
      delete poll.settings.localLogoImage;
      // delete poll.settings.logoImage;
    }

    this.props.updatePoll(poll, undefined, formData);
  }

  showConfirmModal() {
    this.setState({ showConfirmModal: true });
  }

  onConfirm() {
    const settings = this.state.inputs.settings;
    settings.allowResubmissions = !this.state.inputs.settings.allowResubmissions;
    this.updateSettings(settings);
  }

  onLogoImageUpload(image) {
    const inputs = this.state.inputs;
    const settings = inputs.settings;

    if (!image) {
      settings.logoImage = undefined;
      settings.localLogoImage = undefined;
    } else {
      settings.localLogoImage = image;
      settings.logoImage = image.url;
    }

    this.setState(inputs);
  }

  showCreateEmailTemplateModal(onClose, onSave) {
    this.setState({ 
      showCreateEmailTemplateModal: true,
      onCreateModalClose: () => {
        if (onClose) {
          onClose();
        }
        this.setState({ showCreateEmailTemplateModal: false });
      },
      onCreateModalSave: (emailTemplateId) => {
        if (onSave) {
          onSave(emailTemplateId);
        }        
        this.setState({ showCreateEmailTemplateModal: false });
      }
    });
  }

  showEditEmailTemplateModal(emailTemplate) {
    this.setState({ showEditEmailTemplateModal: emailTemplate });
  }

  renderInputs() {
    const isShopify = this.props.account.shop === undefined ? false : true;

    let pageExcludeRulesInput = null;
    let toggleExcludeRules = (<div
      className={`in-card-more-options toggle-exclude-rules ${this.state.showPageExcludeRulesInput ? 'cancel' : 'button'} ${this.state.inputs.pageRules.length ? '' : 'no-margin'}`}
      onClick={() => {
        this.setState({ showPageExcludeRulesInput: !this.state.showPageExcludeRulesInput }, () => {
          if (!this.state.showPageExcludeRulesInput) {
            const inputs = this.state.inputs;
            inputs.pageExcludeRules = [];
            console.log('UPDATING INPUTS');
            this.setState({ inputs });
          }
        });
      }}><div><i className={`fas fa-${this.state.showPageExcludeRulesInput ? 'remove' : 'plus' }`} />{this.state.showPageExcludeRulesInput ? 'Remove exclusion rules' : 'Add exclusion rules' }</div></div>);
    if (this.state.showPageExcludeRulesInput) {
      pageExcludeRulesInput = (
        <DisplayRulesInput
          onChange={this.onChange.bind(this)}
          onValidate={this.onValidate.bind(this)}
          label="Exclusion Rules"
          hideAllPages={true}
          allowEmpty={true}
          type="text"
          placeholder="URL"
          name="pageExcludeRules"
          moreInfo={<span>Fill out this section if you want to exclude certain paths from showing this survey.</span>}
          value={this.state.inputs.pageExcludeRules}
          errorMessage="Please a title for the account."
          ref={this.setRef.bind(this)}
          domains={this.props.account.domains}
        />
      );
    }

    let recipientsInput = null;
    // if (this.state.inputs.settings.notifyOnComplete) {
      recipientsInput = (
        <RecipientsInput
          disabled={!this.state.inputs.settings.notifyOnComplete}
          onChange={this.onChange.bind(this)}
          onValidate={this.onValidate.bind(this)}
          label="Notification Email Recipients"
          moreInfo={<span>By default, this will send to the account notification settings. If you would like to override this behavior, set custom recipients instead.</span>}
          placeholder="email@domain.com"
          name="recipients"
          value={ getRecipients(this.state.inputs.recipients, this.props.account) }
          errorMessage="Please enter a valid domain name."
          ref={this.setRef.bind(this)}
        />
      );
    // }

    let warning = null;
    if (this.state.inputs.settings.apiOnly) {
      warning = (<div className="input-warning" style={{ top: 25, right: 20 }}><div className="content"><div>Your survey is set to be triggered via the API only. This means any page rules will be ignored.</div></div></div>)
    }

    let abandonedCheckoutSettings = null;
    if (this.props.user.type === 'shopify' || true) {
      abandonedCheckoutSettings = (
          <div style={{ marginTop: 25, display: 'none' }}>
            <label>Abandoned Checkout<Tooltip>This will automatically email the survey one hour after a customer has abandoned his or her checkout on Shopify. We recommend also making this survey link only by flipping the switch above.</Tooltip></label>
            <div className="inline-toggle">
              <Toggle
                active={this.state.inputs.settings.shopifyAbandonedCheckout}
                onChange={(value) => {
                  const settings = this.state.inputs.settings;
                  settings.shopifyAbandonedCheckout = value
                  this.updateSettings(settings);
                }}
              />
              <div className="subtle">Email this survey when a checkout is abandoned.</div>
            </div>
          </div>

      );
    }

    let pollLocations = null;

    if (this.state.pollType === 'widget' || this.state.pollType === 'embed') {
      let pollLocationContent = (
        <div className="inputs card" style={{ paddingBottom: 1, marginBottom: 25 }} >
          { warning }
          <DisplayRulesInput
            onChange={this.onChange.bind(this)}
            onValidate={this.onValidate.bind(this)}
            label="Page Rules"
            type="text"
            placeholder="URL"
            name="pageRules"
            moreInfo={<span>Fill out this section if you want different surveys to show up on different pages of your website. If you decide to fill it out, the survey will only show up on pages which match the rules you add. If you want the survey to show up on all pages select <strong>show survey on all pages</strong>.</span>}
            value={this.state.inputs.pageRules}
            errorMessage="Please a title for the account."
            ref={this.setRef.bind(this)}
            domains={this.props.account.domains}
          />

          <div style={{ position: 'relative', paddingBottom: this.state.showPageExcludeRulesInput ? 0 : 0 }}>
            { toggleExcludeRules }
            { pageExcludeRulesInput }
          </div>
        </div>
      );

      if (this.state.pollLocationsType === 'template') {
        const template = getTemplateFromDisplayRules(this.state.inputs.pageRules, this.state.inputs.pageExcludeRules, true);
        let extensibilityNotice = null;
        if (template && template.showExtensibilityNotice) {
          extensibilityNotice = (
          <div className="card info checkout-extensibility-notice" style={{ maxWidth: 691 }}>
            <p className="title">If you <strong>are</strong> using <a href="https://help.shopify.com/en/manual/checkout-settings/customize-checkout-configurations/checkout-extensibility" target="_blank">checkout or customer account extensibility</a></p><br/>
            <p>Make sure to add the app block to your thank you, order status, checkout, and customer profile pages. <a href="https://www.zigpoll.com/blog/zigpoll-shopify-checkout-extensions" target="_blank">Click here for a step by step guide</a>.</p>
          </div>
          );
        }

        let displayRulesTemplateToggle = null;
        let selectedTemplate = getTemplateFromDisplayRules(this.state.inputs.pageRules, this.state.inputs.pageExcludeRules, true);
        if (selectedTemplate) {
          displayRulesTemplateToggle = (
            <div className='selected'><div className={`block active`}>
              <div>
                <div className="content">
                  <div className="block-icon-wrapper">
                    <i className={`block-icon fas ${selectedTemplate.icon || 'fa-theater-masks' }`} />
                  </div>
                  <p className="block-title"><div dangerouslySetInnerHTML={{ __html: selectedTemplate.title }}/></p>
                  <p className="block-subtitle">{ selectedTemplate.description }</p>
                </div>
                <div className="selected"><i className="fas fa-check" />Selected</div>
              </div>
            </div></div>
          );
        }

        pollLocationContent = <div><div className={`blocks-input ${this.state.displayRulesTemplatesActive || !selectedTemplate ? 'editable' : 'condensed'}`}>
          { displayRulesTemplateToggle }

          { selectedTemplate && (!this.state.displayRulesTemplatesActive ? <div className="edit-block-list" onClick={() => this.setState({ displayRulesTemplatesActive: true })}><i className="fas fa-plus" />Show more templates</div> : <div className="edit-block-list" onClick={() => this.setState({ displayRulesTemplatesActive: false })}><i className="fas fa-minus" />Hide templates</div>) }

          <DisplayRulesTemplatesInput
            isShopify={isShopify}
            hideInputBlockClass={true}
            displayRulesTemplate={getTemplateFromDisplayRules(this.state.inputs.pageRules, this.state.inputs.pageExcludeRules)}
            onChange={(e) => {
              const value = e.target.value;
              const template = _.find(displayRulesTemplates, ({handle}) => handle === value);

              if (!template) {
                return;
              }

              const inputs = this.state.inputs;
              inputs.pageRules = template.pageRules || [];
              inputs.pageExcludeRules = template.pageExcludeRules || [];

              if (template.settings) {
                inputs.settings.urlDependant = template.settings.urlDependant;
              } else {
                inputs.settings.urlDependant = false;
                inputs.settings.shopifyCustomerTargeting = '';
              }
              this.setState(inputs);
            }}
            page="edit"
          />
        </div>
        { extensibilityNotice }</div>
      }

      pollLocations = (
      <span>
        <SectionHeader
          title=<span><i className="fas fa-map-marked-alt" />Locations</span>
          subtitle="The survey will only appear on the pages you specify below."
          bottom={true}
          className="no-margin"
        />

        { isShopify && <div>
          <div className={`medium-switch ${this.state.pollLocationsType === 'template' ? 'left' : 'right'}`}>
            <div 
              onClick={() => this.setState({ pollLocationsType: 'template' })}
              className={`${this.state.pollLocationsType === 'template' ? 'active' : ''}`}>Template</div>
            <div 
              onClick={() => this.setState({ pollLocationsType: 'manual' })}
              className={`${this.state.pollLocationsType === 'manual' ? 'active' : ''}`}>Manual</div>
          </div>
        </div> }

        <div style={{ margin: '0px auto' }}>
          { pollLocationContent }
        </div>

      </span>);
    }

    let embedTarget = null;
    if (this.state.pollType === 'embed') {
      embedTarget = (
      <div style={{ marginTop: 25 }}>
        <SectionHeader
          title=<span><i class="fas fa-arrows-to-dot"></i>Embed Settings</span>
          subtitle="Configure how you want your survey to appear on the page."
          tooltip="By default, all surveys show up as a pop-up. You can embed a survey on a page if you prefer."
          className="no-margin"
        />

        <div className="inputs card" style={{ marginBottom: 25, paddingBottom: 1 }}>
          {/*
          <div style={{ marginBottom: 0 }}>
            <label>Embed the poll</label>
            <div className="inline-toggle">
              <Switch 
                options={[
                  { label: 'Include as a widget', value: 'widget'},
                  { label: 'Embed on your page', value: 'embed'}
                ]}
                value={this.state.pollType}
                onChange={(value) => {
                  if (value === 'widget') {
                    const settings = this.state.inputs.settings;
                    settings.selector = false;
                    this.updateSettings(settings);
                  } else {
                    const settings = this.state.inputs.settings;
                    settings.selector = '#zigpoll-embed-target';
                    this.updateSettings(settings);
                  }
                  this.setState({ pollType: value });
                }}
              />
            </div>
          </div>
          */}
          { this.state.pollType === 'embed' ? (<div style={{ marginTop: 0 }}>
            <p className="subtle">Paste the code below where you want the survey to appear or use a custom css selector by entering it the input box.</p>

            <div style={{ marginBottom: 20 }}>
              <CodeBlock
                language="html"
                code={`<div id="${this.state.inputs.settings.selector.replace(/#/g, '')}"></div>`}
              />
            </div>

            <NameInput
              value={this.state.inputs.settings.selector || ''}
              label={'Selector'}
              placeholder="#zigpoll-embed-target"
              onChange={(e) => {
                const settings = this.state.inputs.settings;
                settings.selector = e.target.value;
                this.updateSettings(settings);
              }}
              onValidate={() => {
                console.log('validate');
              }}
              errorMessage="Please enter a value."
            />
          </div>) : null }
        </div>
      </div>
      );
      if (
        (['post-purchase-only', 'order-status-only'].indexOf(getTemplateFromDisplayRules(this.state.inputs.pageRules, this.state.inputs.pageExcludeRules)) !== -1) 
        && (this.props.account.shop !== undefined)
      ) {
        embedTarget = <div style={{ marginTop: 25 }}><SectionHeader
          title=<span><i class="fas fa-arrows-to-dot"></i>Embed Settings</span>
          subtitle="Configure where you want your survey to appear on the page."
          tooltip="By default, all polls show up as a pop-up. You can embed a survey on a page if you prefer."
          className="no-margin"
        />
          <div className="image-placement" style={{ marginBottom: 25 }}>
            <div className="left">
              <div className="input card">
                <label>Checkout Position<Tooltip>This placement tool is for classic post purchase stores only. If you are using checkout extensions place the app block where you want the survey to appear.</Tooltip></label>
                <Switch 
                  options={[
                    { label: 'Top Left', value: 'top-left'},
                    { label: 'Bottom Right', value: 'bottom-right'},
                    { label: 'Bottom Left', value: 'bottom-left'},
                  ]}
                  vertical={true}
                  value={this.state.inputs.settings.shopifyCheckoutPosition || 'top-left'}
                  onChange={(value) => {
                    const settings = this.state.inputs.settings;
                    settings.shopifyCheckoutPosition = value;
                    this.updateSettings(settings);
                  }}
                />
              </div>
            </div>

            <div className="images">
              <img className={`${this.state.inputs.settings.shopifyCheckoutPosition === 'top-left' || this.state.inputs.settings.shopifyCheckoutPosition === undefined ? 'active' : '' }`} src={topPlacement} />
              <img className={this.state.inputs.settings.shopifyCheckoutPosition === 'bottom-left' ? 'active' : '' } src={bottomPlacement} />
              <img className={this.state.inputs.settings.shopifyCheckoutPosition === 'bottom-right' ? 'active' : '' } src={bottomRightPlacement} />
            </div>
          </div>

          <div className="card info" style={{ marginTop: 0, }}>
            <p className="title">If you <strong>are</strong> using <a href="https://help.shopify.com/en/manual/checkout-settings/customize-checkout-configurations/checkout-extensibility" target="_blank">Checkout Extensibility</a></p><br/>
            <p>Make sure to add the app block to your thank you and order status pages. <a href="https://www.zigpoll.com/blog/zigpoll-shopify-checkout-extensions" target="_blank">Click here for a step by step guide</a>.</p>

            <hr/>

            <p className="title">If you <strong>are not</strong> using <a href="https://help.shopify.com/en/manual/checkout-settings/customize-checkout-configurations/checkout-extensibility" target="_blank">Checkout Extensibility</a></p><br/>

            <p>We will automatically embed this survey on your Shopify post purchase pages using the automatic embed code. <a href="https://www.zigpoll.com/blog/shopify-post-purchase-survey" target="_blank">Click here to learn more</a>.</p>
          </div>
        </div>;
      }
    }

    let postPurchaseTargeting = null;
    let displayRulesTemplate = getTemplateFromDisplayRules(this.state.inputs.pageRules, this.state.inputs.pageExcludeRules);

    if (
      (((displayRulesTemplate === 'post-purchase-only' || displayRulesTemplate === 'order-status-only' || displayRulesTemplate === 'checkout' ) &&
      ['email', 'link', 'api'].indexOf(this.state.pollType) === -1)) ||
      (
        _.isEqual(this.state.inputs.settings.shopifyWebhooks, ['orders/fulfilled']) ||
        _.isEqual(this.state.inputs.settings.shopifyWebhooks, ['orders/paid']) ||
        _.isEqual(this.state.inputs.settings.shopifyWebhooks, ['orders/cancelled']) ||
        _.isEqual(this.state.inputs.settings.shopifyWebhooks, ['fulfillment_events/create']) ||
        this.state.inputs.settings.shopifyAbandonedCheckout
      )
    ) {

      const shopifyCustomerTargetingInput = prepareShopifyCustomerTargeting(this.state.inputs.settings.shopifyCustomerTargeting || '');
      let targetingForm = null;

      if (this.state.shopifyCustomerTargetingSettingsType === 'basic') {
        const { info, handle } = _.find(customerTargetingOptions, ({ value }) => _.isEqual(value, shopifyCustomerTargetingInput));

        targetingForm = 
          <div className="card" style={{ marginBottom: 25 }}>
            <div className="select-wrapper" style={{ width: '100%' }}>
              <select style={{width: '100%'}} value={handle} onChange={(e) => {
                const settings = this.state.inputs.settings;
                const val = e.target.value;

                const value = _.find(customerTargetingOptions, ({ handle }) => handle === val).value;

                settings.shopifyCustomerTargeting = value;

                this.updateSettings(settings);
              }}>
                { customerTargetingOptions.map(({ value, handle, label, requireCustomer }) => {
                  if (displayRulesTemplate === 'checkout' && requireCustomer) {
                    return null;
                  }
                  return (<option value={handle} key={handle}>{label}</option>)
                })}
              </select>
              <p className="subtle" style={{ marginTop: 15 }}>{info}</p>
            </div>
          </div>
      }

      if (this.state.shopifyCustomerTargetingSettingsType === 'advanced') {
        targetingForm = <ShopifyCustomerTargetingForm
          shopifyCustomerTargeting={shopifyCustomerTargetingInput}
          hasOrder={this.state.inputs.settings.shopifyAbandonedCheckout ? false : true}
          hasCustomer={displayRulesTemplate === 'checkout' ? false : true}
          onChange={(value) => {
            const settings = this.state.inputs.settings;
            settings.shopifyCustomerTargeting = value;            
            this.updateSettings(settings);
          }}
        />
      }
      postPurchaseTargeting = <div style={{marginTop: 25 }}>
        <SectionHeader
          title=<span><i class="fas fa-bullseye" />Customer Targeting Settings</span>
          subtitle="Configure which types of customers should see this survey."
          tooltip="By default, all surveys will show to all customers. If you would like to show only to new or existing customers, adjust the dropdown below."
          className="no-margin"
        />

        <div>
          <div className={`medium-switch ${this.state.shopifyCustomerTargetingSettingsType === 'advanced' ? 'right' : 'left'}`}>
            <div 
              onClick={() => {
                const settings = this.state.inputs.settings;
                settings.shopifyCustomerTargeting = [[],[]];
                this.updateSettings(settings);
                this.setState({shopifyCustomerTargetingSettingsType: 'basic' });
              }}
              className={`${this.state.shopifyCustomerTargetingSettingsType === 'basic' ? 'active' : ''}`}>Basic</div>
            <div 
              onClick={() => this.setState({ shopifyCustomerTargetingSettingsType: 'advanced' })}
              className={`${this.state.shopifyCustomerTargetingSettingsType === 'advanced' ? 'active' : ''}`}>Advanced</div>
          </div>
        </div>

        { targetingForm }

      </div>
    }

    let emailSettingsForm = null;
    let emailPreviewForm = null;
    if (this.state.pollType === 'email' || this.state.pollType === 'sms') {
      const settings = this.state.inputs.settings;
      const emailDisplaySettings = this.props.poll.emailDisplaySettings || this.props.account.emailDisplaySettings || defaultEmailDisplaySettings;

      const smsDisplaySettings = this.props.poll.smsDisplaySettings || this.props.account.smsDisplaySettings || defaultSmsDisplaySettings;
      let selectVal;
      if (settings.shopifyAbandonedCheckout) {
        selectVal = 'abandoned-checkout';
      }
      if (settings.shopifyWebhooks) {
        selectVal = settings.shopifyWebhooks[0];
      }
      emailSettingsForm = <div style={{marginTop: 25}}>
        <SectionHeader
          title=<span><i className="fas fa-at" />{ this.state.pollType === 'email' ? "Email" : "SMS" } Settings</span>
          subtitle={`Configure when your ${this.state.pollType === 'email' ? 'email' : 'sms'} should be sent to your customers.`}
          className="no-margin"
        /> 

        <div className="card has-dynamic-input email-settings-form">
          <label>Trigger Settings<Tooltip>Adjust these settings in order to determine how and when your survey will be sent to your customers.</Tooltip></label>
          <div className="inline-input" style={{ marginTop: 15 }}>
            <span className="prefix">Send </span>
            <input
              type="text"
              name='emailDelay'
              value={ this.state.inputs.settings.emailDelay }
              onChange={(e) => {
                let val = e.target.value.replace(/[^\d.-]/g,'');
                const settings = this.state.inputs.settings;
                settings.emailDelay = val;
                this.updateSettings(settings);
              }}
            />
            <span className="suffix"><select onChange={(e) => {
              e.preventDefault();
              e.stopPropagation();
              let val = e.target.value;
              const settings = this.state.inputs.settings;
              settings.emailDelayInterval = val;
              this.updateSettings(settings);
            }} value={this.state.inputs.settings.emailDelayInterval || 'hours'} style={{ textAlign: 'left', width: 'auto', display: 'inline-block' }}><option value="minutes">minutes</option><option value="hours">hours</option><option value="days">days</option></select> after <div className="select-wrapper"><select
              value={selectVal}
              onChange={(e) => {
                let val = e.target.value;
                const settings = this.state.inputs.settings;
                if (val === 'abandoned-checkout') {
                  settings.shopifyWebhooks = false;
                  settings.shopifyAbandonedCheckout = true;
                } else {
                  settings.shopifyWebhooks = [val];
                  settings.shopifyAbandonedCheckout = false;
                }
                this.updateSettings(settings);
              }}
            >
              <option value="orders/fulfilled">Order Fulfilled</option>
              <option value="orders/paid">Order Paid</option>
              <option value="orders/cancelled">Order Cancelled</option>
              <option value="fulfillment_events/create">Order Delivered</option>
              { this.state.pollType === 'email' ? <option value="abandoned-checkout">Abandoned Checkout</option> : null }
            </select></div></span>
          </div> 
        </div>

        { this.state.pollType === 'sms' ? <div className="card info" style={{ marginTop: 20 }}>
            <div className="top">Make sure you are collecting phone numbers at checkout</div>
            <p><a href={`https://www.convertout.com/blog/how-to-make-phone-number-mandatory-in-shopify-checkout-2-min-tutorial`} target="_blank">Click here</a> for steps to collect phone numbers as part of your checkout.</p>
          </div> : null}
      </div>
      emailPreviewForm = (
      <div style={{marginTop: 25}}>
        <SectionHeader
          title=<span><i class="fas fa-envelope" />Email Preview</span>
          subtitle="See how your email will appear in an inbox."
          className="no-margin"
        /> 

        <div style={{ position: 'relative', width: 600, margin: '0px auto' }} className="account-display no-sticky">
          <EmailSettingsVisualDisplay
            poll={this.props.poll}
            {...emailDisplaySettings }
            belowVisualDisplay={<div className="below-visual-display">
              <i className="fas fa-arrow-turn-up" />Want to change the email content for this survey? Go to your <Link to={`/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}?tab=design&type=email`}>email design settings</Link> and make your changes.
            </div>}
          />
        </div>
      </div>
      )

      if (this.state.pollType === 'sms') {
        emailPreviewForm = (
        <div style={{marginTop: 25}}>
          <SectionHeader
            title=<span><i class="fas fa-sms" />SMS Preview</span>
            subtitle="See how your sms messages will appear to customers."
            className="no-margin"
          /> 

          <div style={{ position: 'relative', width: 600, margin: '0px auto' }} className="account-display">
            <SmsSettingsVisualDisplay
              {...smsDisplaySettings}
              account={this.props.account}
              poll={this.props.poll}
              height={296}
              belowVisualDisplay={<div className="below-visual-display">
                <i className="fas fa-arrow-turn-up" />Want to change the SMS content for this survey? Go to your <Link to={`/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}?tab=design&type=sms`}>SMS design settings</Link> and make your changes.
              </div>}
            />
          </div>
        </div>
        );
      }
    }

    let jsOnClickSnippet = null;
    if (this.state.pollType === 'api') {
      jsOnClickSnippet = <div>
        <div style={{marginTop: 25}}>
          <JSSnippets
            account={this.props.account}
            poll={this.props.poll}
          />
        </div>
      </div>
    }

    let shopifyPollSettings = null;
    if (isShopify) {
    // if (true) {
      let shopifyTagInput = null;
      if (!this.state.inputs.settings.disableShopifyTags) {
        shopifyTagInput = (
          <NameInput
            value={this.state.inputs.settings.shopifyCustomerTag === undefined ? 'Completed Zigpoll Survey' : this.state.inputs.settings.shopifyCustomerTag}
            label={'Shopify Customer Tag'}
            placeholder="Completed Zigpoll Survey"
            onChange={(e) => {
              const settings = this.state.inputs.settings;
              settings.shopifyCustomerTag = e.target.value;
              this.updateSettings(settings);
            }}
            onValidate={() => {
              console.log('validate');
            }}
            errorMessage="Please enter a value."
          />
        );
      }

      let shopifyCustomerTagInput = <div style={{ marginTop: 25 }}>
        <label>Shopify Tags<Tooltip>This will automatically tag the Shopify Customer when he or she completes a survey. The tag can be specified below.</Tooltip></label>
        <div className="inline-toggle" style={{ marginBottom: this.state.inputs.settings.disableShopifyTags ? 0 : 25 }}>
          <Toggle
            active={!this.state.inputs.settings.disableShopifyTags}
            onChange={(value) => {
              const settings = this.state.inputs.settings;
              settings.disableShopifyTags = !value;
              this.updateSettings(settings);
            }}
          />
          <div className="subtle">Tag customers who complete the survey in Shopify.</div>
        </div>
        {shopifyTagInput}
      </div>

      let acceptsMarketingInput = <div className="input" style={{ marginTop: 25 }}>
        <label>Accepts Marketing<Tooltip>Whenever a customer is created from Zigpoll we can mark accepts marketing as true or false based on this flag.</Tooltip></label>
        <div className="inline-toggle absolute">
          <Toggle
            active={!this.state.inputs.settings.disableAcceptsMarketing}
            onChange={(value) => {
              const settings = this.state.inputs.settings;
              settings.disableAcceptsMarketing = !value
              this.updateSettings(settings);
            }}
          />
          <div className="subtle">Mark created customers as "accepts marketing".</div>
        </div>
      </div>

      if (this.state.inputs.settings.disableCreateNewCustomers) {
        acceptsMarketingInput = null;
      }

      shopifyPollSettings = (
      <span>
        <SectionHeader
          title=<span><i class="fas fa-pencil-alt" />Shopify Write Settings</span>
          subtitle="Control how Zigpoll data interacts with your Shopify store."
          tooltip="Save time and make connections by merging any data collected by Zigpoll with your Shopify admin dashboard."
          className="no-margin"
        />
        <div className="inputs card" style={{ marginBottom: 25 }}>
          <div className="input" style={{ marginTop: 0 }}>
            <label>Write to order details<Tooltip>Whenever an order id is passed through to Zigpoll we will log the results to that particular order in your Shopify store under the "Additional Details" section. This lets you view all your data within the Shopify dashboard.</Tooltip></label>
            <div className="inline-toggle absolute">
              <Toggle
                active={this.state.inputs.settings.writeToOrder}
                onChange={(value) => {
                  const settings = this.state.inputs.settings;
                  settings.writeToOrder = value
                  this.updateSettings(settings);
                }}
              />
              <div className="subtle">Save responses to the <strong>Additional Details</strong> section of your Shopify order dashboard.</div>
            </div>
          </div>

          <div className="input" style={{ marginTop: 25 }}>
            <label>Write to order notes<Tooltip>Whenever an order id is passed through to Zigpoll we will log the results to that particular order in your Shopify store under the "Notes" section. This is useful if you prefer notes over the additional details section.</Tooltip></label>
            <div className="inline-toggle absolute">
              <Toggle
                active={this.state.inputs.settings.writeToOrderNote}
                onChange={(value) => {
                  const settings = this.state.inputs.settings;
                  settings.writeToOrderNote = value
                  this.updateSettings(settings);
                }}
              />
              <div className="subtle">Save responses to the <strong>Notes</strong> section of your Shopify order dashboard.</div>
            </div>
          </div>

          <div className="input" style={{ marginTop: 25 }}>
            <label>Write to customer notes<Tooltip>Whenever a customer id is passed through to Zigpoll we will log the results to that particular customer in your Shopify store. This lets you view all your data within the Shopify dashboard.</Tooltip></label>
            <div className="inline-toggle">
              <Toggle
                active={this.state.inputs.settings.writeToCustomer}
                onChange={(value) => {
                  const settings = this.state.inputs.settings;
                  settings.writeToCustomer = value
                  this.updateSettings(settings);
                }}
              />
              <div className="subtle">Save responses to the Shopify customer object.</div>
            </div>
          </div>

          <div className="input" style={{ marginTop: 25 }}>
            <label>Write to customer metafields<Tooltip>Whenever a customer id is passed through to Zigpoll we will log the results to that particular customer in your Shopify store. This lets you view all your data within the Shopify dashboard.</Tooltip></label>
            <div className="inline-toggle absolute">
              <Toggle
                active={this.state.inputs.settings.writeToCustomerMetafields}
                onChange={(value) => {
                  const settings = this.state.inputs.settings;
                  settings.writeToCustomerMetafields = value
                  this.updateSettings(settings);
                }}
              />
              <div className="subtle">Write customer responses to individual metafields on the Shopify customer object.</div>
            </div>
          </div>

          { this.state.inputs.settings.writeToCustomerMetafields && (
          <div className="input" style={{ marginTop: 25 }}>
              <NameInput
                value={this.state.inputs.settings.customerMetafieldNamespace === undefined ? 'Zigpoll' : this.state.inputs.settings.customerMetafieldNamespace}
                label={"Metafield Namespace"}
                moreInfo={"By default, we use Zigpoll as the namespace. If you want to use a different one, enter it below."}
                placeholder="zigpoll"
                onChange={(e) => {
                  const settings = this.state.inputs.settings;
                  settings.customerMetafieldNamespace = e.target.value;
                  this.updateSettings(settings);
                }}
                onValidate={() => {
                  console.log('validate');
                }}
                errorMessage="Please enter a value."
              />
          </div>
          ) }

          <div className="input" style={{ marginTop: 25 }}>
            <label>Create new customers<Tooltip>Whenever an email is found we will automatically create a new customer in your Shopify dashboard if it does not already exist.</Tooltip></label>
            <div className="inline-toggle absolute">
              <Toggle
                active={!this.state.inputs.settings.disableCreateNewCustomers}
                onChange={(value) => {
                  const settings = this.state.inputs.settings;
                  settings.disableCreateNewCustomers = !value
                  this.updateSettings(settings);
                }}
              />
              <div className="subtle">Create new customers in Shopify if they do not already exist.</div>
            </div>
          </div>

          { acceptsMarketingInput }

          { shopifyCustomerTagInput }
        </div>
      </span>
      );
    }

    let metadataWhitelistInput = null;
    if (this.props.poll.settings.emailMetadataRules === 'whitelist') {
      metadataWhitelistInput = (
        <div style={{ marginTop: 10 }}>
          <label>Metadata Whitelist</label>
          <textarea
            className="textarea"
            style={{ marginTop: 0, minHeight: 200 }}
            value={this.state.inputs.settings.emailMetadataWhitelist || ''}
            placeholder="completed_poll, order_total"
            onChange={(e) => {
              const settings = this.state.inputs.settings;
              settings.emailMetadataWhitelist = e.target.value;
              this.updateSettings(settings);
            }}
          />
        </div>
      );
    }

    let advancedGeneralOptions = null;
    if (this.state.showAdvancedGeneralOptions) {
      advancedGeneralOptions = (
      <div>
        <div style={{ marginTop: 25, marginBottom: 0 }}>
          <label>Anonymous Mode</label>
          <div className="inline-toggle">
            <Toggle
              active={this.state.inputs.settings.anonMode}
              onChange={(value) => {
                const settings = this.state.inputs.settings;
                settings.anonMode = value
                this.updateSettings(settings);
              }}
            />
            <div className="subtle">Do not collect any information from participants.</div>
          </div>
        </div>
        <div style={{ marginTop: 25, marginBottom: 0 }}>
          <label>Hide X Button</label>
          <div className="inline-toggle">
            <Toggle
              active={this.state.inputs.settings.hideXButton}
              onChange={(value) => {
                const settings = this.state.inputs.settings;
                settings.hideXButton = value
                this.updateSettings(settings);
              }}
            />
            <div className="subtle">Hide the X button on the top right of the survey.</div>
          </div>
        </div>
        <div style={{ marginTop: 25, marginBottom: 0 }}>
          <label>Hide Close Button</label>
          <div className="inline-toggle">
            <Toggle
              active={this.state.inputs.settings.hideCloseButton}
              onChange={(value) => {
                const settings = this.state.inputs.settings;
                settings.hideCloseButton = value
                this.updateSettings(settings);
              }}
            />
            <div className="subtle">Hide the close button at the end of the survey.</div>
          </div>
        </div>
        <div style={{ marginTop: 25, marginBottom: 0 }}>
          <label>Hide Back Button</label>
          <div className="inline-toggle">
            <Toggle
              active={this.state.inputs.settings.hideBackButton}
              onChange={(value) => {
                const settings = this.state.inputs.settings;
                settings.hideBackButton = value
                this.updateSettings(settings);
              }}
            />
            <div className="subtle">Prevent the back button from appearing on this survey.</div>
          </div>
        </div>
      </div>);
    }

    let advancedEmailOptions = null;
    if (this.state.showAdvancedEmailOptions) {
      advancedEmailOptions = (
      <div>
        <div style={{ marginTop: 25, marginBottom: 0 }}>
          <label>Notification Email Metadata Rules<Tooltip>Determine how much metadata to show on your notifications.</Tooltip></label>
          <div className="inline-toggle">
            <Switch
              vertical={true}
              options={[
                { label: 'Print out all metadata', value: 'all' },
                { label: 'Hide all metadata ', value: 'none' },
                { label: 'Show certain metadata only', value: 'whitelist' },
              ]}
              value={this.state.inputs.settings.emailMetadataRules || 'all'}
              onChange={(value) => {
                const settings = this.state.inputs.settings;
                settings.emailMetadataRules = value;
                this.updateSettings(settings);
              }}
            />
          </div>
        </div>
        { metadataWhitelistInput }

        <div style={{ marginTop: 25, marginBottom: 0 }}>
          <label>Notification Email Logo<Tooltip>Use a custom logo for your email heading. Useful if you plan to reply to the customer directly via email.</Tooltip></label>

          <SingleImageUploader
            hideLabel={true}
            key={this.state.inputs.settings.logoImage}
            image={this.state.inputs.settings.logoImage}
            onChange={this.onLogoImageUpload.bind(this)} />
        </div>
      </div>
      );
    }

    let followUpEmailForm = null;
    if (this.state.inputs.settings.followUpEmail) {
      const emailTemplates = this.props.account.emailTemplates || [];
      let editButton = null;

      if (this.state.inputs.settings.followUpEmailTemplate && _.find(emailTemplates, ({ id }) => id === this.state.inputs.settings.followUpEmailTemplate)) {
        editButton = <div className="edit-button" onClick={(e) => { 
          const emailTemplate = _.find(emailTemplates, ({ id }) => id === this.state.inputs.settings.followUpEmailTemplate);
          this.showEditEmailTemplateModal(emailTemplate);
        }}><i className="fas fa-edit" /></div>
      }

      followUpEmailForm = <div className="inline-input" style={{ marginTop: 15 }}>
        <span className="prefix">Send <div className="select-wrapper" style={{ maxWidth: 230 }}><select
          style={{ width: '100%' }}
          value={this.state.inputs.settings.followUpEmailTemplate}
          onChange={(e) => {
            const value = e.target.value;

            if (value === 'create') {
              this.showCreateEmailTemplateModal(() => {
                console.log('Do nothing');
              }, (emailTemplateId) => {
                const settings = this.state.inputs.settings;
                settings.followUpEmailTemplate = emailTemplateId;
                this.updateSettings(settings);
              });
            } else {
              const settings = this.state.inputs.settings;
              settings.followUpEmailTemplate = value;
              this.updateSettings(settings);
            }
          }}
        >
          <option value="" disabled selected>Select an email template</option>
          { emailTemplates.map((template) => {
            return <option value={template.id}>{ template.title || template.subject }</option>
          }) }
          <option value="create">+ Create Email Template</option>
        </select></div>{ editButton } after</span>
        <input
          type="text"
          name='followUpEmailDelay'
          value={ this.state.inputs.settings.followUpEmailDelay }
          onChange={(e) => {
            let val = e.target.value.replace(/[^\d.-]/g,'');
            const settings = this.state.inputs.settings;
            settings.followUpEmailDelay = val;
            this.updateSettings(settings);
          }}
        />
        <span className="suffix"><select onChange={(e) => {
          e.preventDefault();
          e.stopPropagation();
          let val = e.target.value;
          const settings = this.state.inputs.settings;
          settings.followUpEmailDelayInterval = val;
          this.updateSettings(settings);
        }} value={this.state.inputs.settings.followUpEmailDelayInterval || 'hours'} style={{ textAlign: 'left', width: 'auto', display: 'inline-block' }}><option value="minutes">minutes</option><option value="hours">hours</option><option value="days">days</option></select></span>
      </div> 
    }

    return (
      <div>
        <SectionHeader
          title=<span><i class="fas fa-shapes"></i>Delivery Format</span>
          subtitle="Select how you want your survey to appear."
          bottom={true}
          className="no-margin"
        />

        <PollTypeSelector
          isShopify={isShopify}
          selectedType={this.state.pollType}
          onChange={(value) => {
            const settings = this.state.inputs.settings;
            let pollType = 'widget';
            if (value === 'widget') {
              settings.apiOnly = false
              settings.shopifyAbandonedCheckout = false;
              settings.selector = false;
              settings.shopifyWebhooks = false;
              settings.emailDelay = false;
              settings.sms = false;
            } else if (value === 'embed') {
              settings.apiOnly = false;
              settings.shopifyAbandonedCheckout = false;
              settings.selector = "#zigpoll-embed-target";
              settings.shopifyWebhooks = false;
              settings.emailDelay = false;
              settings.sms = false;
              pollType = 'embed';
            } else if (value === 'link') {
              settings.apiOnly = true;
              settings.shopifyAbandonedCheckout = false;
              settings.selector = false;
              settings.shopifyWebhooks = false;
              settings.emailDelay = false;
              settings.sms = false;
              pollType = 'link';
            } else if (value === 'api') {
              settings.apiOnly = true;
              settings.shopifyAbandonedCheckout = false;
              settings.selector = false;
              settings.shopifyWebhooks = false;
              settings.emailDelay = false;
              settings.sms = false;
              pollType = 'api';
            } else if (value === 'abandoned-checkout') {
              settings.apiOnly = true;
              settings.shopifyAbandonedCheckout = true;
              settings.selector = false;
              settings.shopifyWebhooks = false;
              settings.emailDelay = 1;
              settings.sms = false;
              pollType = 'abandoned-checkout';
            } else if (value === 'email') {
              settings.apiOnly = true;
              settings.shopifyAbandonedCheckout = false;
              settings.selector = false;
              settings.shopifyWebhooks = settings.shopifyWebhooks || ['orders/fulfilled'];
              settings.emailDelay = 1;
              settings.sms = false;
              pollType = 'email';
            } else if (value === 'sms') {
              settings.apiOnly = true;
              settings.shopifyAbandonedCheckout = false;
              settings.selector = false;
              settings.shopifyWebhooks = settings.shopifyWebhooks || ['orders/fulfilled'];
              settings.emailDelay = 1;
              settings.sms = true;
              pollType = 'sms';
            }

            settings.pollType = pollType;
            this.updateSettings(settings);  
            this.setState({ pollType });
          }}
        />

        { emailSettingsForm }
        { emailPreviewForm }

        { pollLocations }
        { embedTarget }

        { postPurchaseTargeting }

        { jsOnClickSnippet }

        { this.props.poll.isVisible && <PublicPollLinkCard
          account={this.props.account}
          poll={this.props.poll}
          pollId={this.props.pollId}
          toggleVisibility={this.props.toggleVisibility}
          accountId={this.props.accountId}
          integrationSettings={this.props.account.integrationSettings || {}}
          className="no-margin"
          style={{ marginTop: 25, marginBottom: 25 }}
        /> }

        <SectionHeader
          title=<span><i className="fas fa-cog"/>General Settings</span>
          subtitle="Adjust your survey's title and behavior."
          bottom={true}
          className="no-margin"
        />

        <div className="inputs email-notifications-form card" style={{ marginBottom: 25 }}>
          <NameInput
            onChange={this.onChange.bind(this)}
            onValidate={this.onValidate.bind(this)}
            label="Survey Title"
            type="text"
            placeholder="Title for the survey"
            name="title"
            value={this.state.inputs.title}
            errorMessage="Please a title for the survey."
            ref={this.setRef.bind(this)}
          />

          <div style={{ marginBottom: 12}}>
            <label>Progress Indicator<Tooltip>Control how the amount of progress to the respondant is displayed. If you use branching logic, we recommend using the circle indicator.</Tooltip></label>
            <div className="inline-toggle">
              <Switch
                value={this.state.inputs.settings.showCounter}
                options={[
                  { label: 'Circle', value: true },
                  { label: 'Counter', value: 'counter' },
                  { label: 'None', value: false },
                ]}
                onChange={(value) => {
                  const settings = this.state.inputs.settings;
                  settings.showCounter = value
                  this.updateSettings(settings);
                }}
              />
            </div>
          </div>

          <div style={{ marginBottom: 0 }}>
            <label>Allow Edits</label>
            <div className="inline-toggle">
              <Toggle
                active={this.state.inputs.settings.allowEdits}
                onChange={(value) => {
                  const settings = this.state.inputs.settings;
                  settings.allowEdits = value
                  this.updateSettings(settings);
                }}
              />
              <div className="subtle">Allow users to edit their answers after submitting.</div>
            </div>
            <div style={{ marginTop: 25 }}>
              <label>Automatically Advance Slides</label>
              <div className="inline-toggle">
                <Toggle
                  active={this.state.inputs.settings.automaticallySwitchSlides}
                  onChange={(value) => {
                    const settings = this.state.inputs.settings;
                    settings.automaticallySwitchSlides = value
                    this.updateSettings(settings);
                  }}
                />
                <div className="subtle">Automatically move to the next slide on selection.</div>
              </div>
            </div>
          </div>

          { advancedGeneralOptions }

          <div className="show-advanced" onClick={() => this.setState({ showAdvancedGeneralOptions : !this.state.showAdvancedGeneralOptions })} >{ !this.state.showAdvancedGeneralOptions  ? 'More Options' : 'Less Options' }</div>

{/*
          <div style={{ marginBottom: 25}}>
            <label>Sentiment Analysis<Tooltip>This will keep a running tally of the sentiment of the responses to this poll. Only for the English language.</Tooltip></label>
            <div className="inline-toggle">
              <Toggle
                active={this.state.inputs.settings.enableSentimentAnalysis}
                onChange={(value) => {
                  const settings = this.state.inputs.settings;
                  settings.enableSentimentAnalysis = value
                  this.updateSettings(settings);
                }}
              />
              <div className="subtle">Analyze sentiment on all responses of this poll.</div>
            </div>
          </div>
*/}

          <div style={{ display: 'none' }}>
            <label>Link Only<Tooltip>This will make it so the survey will not appear as a pop-up on any pages. The survey will not show up according to page rules. The survey will only show up by using the API, being sent in an email, or by using the share link below.</Tooltip></label>
            <div className="inline-toggle">
              <Toggle
                active={this.state.inputs.settings.apiOnly}
                onChange={(value) => {
                  const settings = this.state.inputs.settings;
                  settings.apiOnly = value
                  this.updateSettings(settings);
                }}
              />
              <div className="subtle">Only show this survey via the public link.</div>
            </div>
          </div>

          { abandonedCheckoutSettings }
        </div>

        <SectionHeader
          title=<span><i class="fas fa-vote-yea" />Submission Rules</span>
          subtitle="Manage how often users can fill out this survey."
          bottom={true}
          className="no-margin"
        />

        <div className="inputs card" style={{ marginBottom: 25 }} >
          <div>
            <label>Allow Resubmissions<Tooltip>This is useful for <strong>contact us</strong> style forms where you want to collect feedback without any limits.</Tooltip></label>
            <div className="inline-toggle" style={{ marginBottom: 25 }}>
              <Toggle
                active={this.state.inputs.settings.allowResubmissions}
                onChange={(value) => {
                  // if (this.props.poll.settings.isVisible) {
                  //   this.showConfirmModal();
                  // } else {
                    const settings = this.state.inputs.settings;
                    settings.allowResubmissions = value;
                    this.updateSettings(settings);
                  // }
                }}
              />
              <div className="subtle">Let users fill out this survey multiple times.</div>

              <Confirm 
                title="Are you sure?"
                subtitle="This survey is visible. If you make this change anyone who has filled out this form will have their progress reset."
                show={this.state.showConfirmModal}
                onConfirm={this.onConfirm.bind(this)}
                onCancel={() => {
                  this.setState({ showConfirmModal: false });
                }}
              />
            </div>
          </div>

          { this.state.inputs.settings.allowResubmissions && <div>
            <label>Reset Session<Tooltip>This is useful for <strong>kiosk</strong> style forms where you want to collect feedback from a single survey using a tablet or other device.</Tooltip></label>
            <div className="inline-toggle absolute" style={{ marginBottom: 25 }}>
              <Toggle
                active={this.state.inputs.settings.resetSessions}
                onChange={(value) => {
                  const settings = this.state.inputs.settings;
                  settings.resetSessions = value;
                  this.updateSettings(settings);
                }}
              />
              <div className="subtle">Each survey completion will be treated as a brand new participant.</div>
            </div>
          </div> }

          <div>
            <label>URL Dependant<Tooltip>This will treat each survey as unique if it is visited on a new url. This is useful if you have dynamic pages and want to run the same survey multiple times depending on the context. For example: running a survey on a post purchase page.</Tooltip></label>
            <div className="inline-toggle absolute">
              <Toggle
                active={this.state.inputs.settings.urlDependant}
                onChange={(value) => {
                  const settings = this.state.inputs.settings;
                  settings.urlDependant = value;
                  this.updateSettings(settings);
                }}
              />
              <div className="subtle">Vistors can complete this survey again if it is delivered on a new URL.</div>
            </div>
          </div>
        </div>

        <SectionHeader
          title=<span><i class="fas fa-route"></i>Actions</span>
          subtitle="Actions to take when a survey is fully or partially completed."
          tooltip="By default, all notifications are sent to the account owner."
          className="no-margin"
        />

        <div className="inputs has-dynamic-input email-notifications-form card" style={{ marginBottom: 25, paddingBottom: this.state.inputs.settings.followUpEmail }}>

          <div style={{ marginBottom: 25 }}>
            <label>Follow-Up Email<Tooltip>Send a follow up email to the respondant.</Tooltip></label>
            <div className="inline-toggle">
              <Toggle
                active={this.state.inputs.settings.followUpEmail}
                onChange={(value) => {
                  const settings = this.state.inputs.settings;
                  settings.followUpEmail = value;
                  this.updateSettings(settings);
                }}
              />
              <div className="subtle">Send a follow-up email to the respondant.</div>
            </div>

            { followUpEmailForm }
          </div>

          <div style={{ marginBottom: 0, paddingBottom: 25 }}>
            <label>Notification Email<Tooltip>This will treat each survey as unique if it is visited on a new url. This is useful if you have dynamic pages and want to run the same survey multiple times depending on the context. For example: running a survey on a post purchase page.</Tooltip></label>
            <div className="inline-toggle">
              <Toggle
                active={this.state.inputs.settings.notifyOnComplete}
                onChange={(value) => {
                  const settings = this.state.inputs.settings;
                  settings.notifyOnComplete = value;
                  this.updateSettings(settings);
                }}
              />
              <div className="subtle">Notify me or my team by email.</div>
            </div>
          </div>

          { this.state.inputs.settings.notifyOnComplete && <div style={{ marginBottom: this.state.inputs.settings.notifyOnComplete ? 25 : 25 }}>
            <label>Notification Email Rules<Tooltip>Configure how often and under what circumstances we should notify you of your survey responses.</Tooltip></label>
            <div className="inline-toggle">
              <Switch
                vertical={true}
                options={[
                  { label: 'Email only if the survey is fully completed', value: 'strict' },
                  { label: 'Email even if the survey is partially completed ', value: true },
                  { label: 'Email only if the respondent submitted an email address', value: 'email-only' },
                  { label: 'Email only if an open-ended response is submitted', value: 'open-ended-only' }
                ]}
                value={this.state.inputs.settings.notifyOnComplete}
                onChange={(value) => {
                  const settings = this.state.inputs.settings;
                  settings.notifyOnComplete = value;
                  this.updateSettings(settings);
                }}
              />
            </div>
          </div> }

          { this.state.inputs.settings.notifyOnComplete && recipientsInput }

          { this.state.inputs.settings.notifyOnComplete && advancedEmailOptions }

          { this.state.inputs.settings.notifyOnComplete && <div className="show-advanced" onClick={() => this.setState({ showAdvancedEmailOptions: !this.state.showAdvancedEmailOptions})} >{ !this.state.showAdvancedEmailOptions ? 'More Options' : 'Less Options' }</div> }
        </div>

        { shopifyPollSettings }
      </div>
    );
  }

  renderModals() {
    return <span>
      { this.state.showCreateEmailTemplateModal && <CreateEmailTemplateModal 
        isOpen={this.state.showCreateEmailTemplateModal}
        settings={this.props.poll.emailDisplaySettings || this.props.account.emailDisplaySettings}
        poll={this.props.poll}
        account={this.props.account}
        onClose={() => this.state.onCreateModalClose()}
        onSave={(emailTemplateId) => this.state.onCreateModalSave(emailTemplateId)}
      /> }
      { this.state.showEditEmailTemplateModal && <CreateEmailTemplateModal 
        isOpen={this.state.showEditEmailTemplateModal}
        isEdit={true}
        settings={this.state.showEditEmailTemplateModal}
        poll={this.props.poll}
        account={this.props.account}
        onClose={() => {
          this.setState({ showEditEmailTemplateModal: false })
        }}
        onSave={() => {
          this.setState({ showEditEmailTemplateModal: false })
        }}
      /> }
    </span>
  }

  renderActions() {
    return (
      <div className="actions card">
        <AuthSubmitButton
          title="Update Survey" 
          disabled={!this.hasChanged()}
        />

        <div className="login">
          <div>Need help understanding surveys?</div>
          <a href="https://docs.zigpoll.com/polls" target="_blank" rel="noopener noreferrer">Learn more</a>
        </div>

        <HeaderButtons>
          <button 
            disabled={!this.hasChanged()}
            onClick={this.onSubmit.bind(this)}>Update Survey</button>
        </HeaderButtons>
      </div>
    )
  }

  render() {
    let pageDisplaySettings = this.props.account.pageDisplaySettings || generatePageDisplaySettings(this.props);
    if (this.props.poll && this.props.poll.pageDisplaySettings) {
      pageDisplaySettings = this.props.poll.pageDisplaySettings;
    }
    let settings = this.props.account.displaySettings;
    if (this.props.poll.displaySettings) {
      settings = this.props.poll.displaySettings;
    }

    const pollType = getPollDisplayType(this.props.poll);

    let previewLink = null;
    if (this.props.poll) {
      previewLink = (<span><a target='_blank' rel="noopener noreferrer" title="Preview your survey" className="poll-preview" href={`/preview/${this.props.accountId}/${this.props.poll._id.toString()}`}>Click here</a> for a full preview.</span>);
    }

    let belowVisualDisplay = <div className="below-visual-display"><i className="fas fa-arrow-turn-up" />A preview of the survey titled: <Link to={`/dashboard/a/${encode(this.props.accountId)}/p/${encode(this.props.poll._id)}`}>{this.props.poll.title}</Link> is printed above. <Link to={`/a/${encode(this.props.accountId)}/p/${encode(this.props.poll._id)}?tab=slides`}>Click here</Link> to make edits or <a href={`/preview/${this.props.accountId}/${this.props.poll._id.toString()}`} target="_blank">click here to try a full demo</a>.</div>

    return (
    <div className="poll-edit-form">
      <div className="split big">
        <div>
          <form onSubmit={this.onSubmit.bind(this)} className={`${this.state.valid === false ? 'invalid' : ''} ${this.state.loading || this.props.loading ? 'loading' : ''} ${this.props.className}`}>
            { this.renderInputs() }
            { this.renderActions() }
            { this.renderModals() }
          </form>
        </div>

        <div className="preview-container-wrapper">
          <div className="section-header small no-margin">
            <div className="top">
              <div className="section-subtitle"><i className="fas fa-magnifying-glass" />Survey Preview</div>
            </div>
            <div className="section-description">Survey represenation below. {previewLink}</div>
          </div>

          <div className="preview-container">
            <VisualDisplay
              slides={ [...this.props.poll.slides] }
              // currentIdx={this.state.currentIdx}
              // prev={this.prev.bind(this)}
              // next={this.next.bind(this)}
              // goto={this.goto.bind(this)}
              singleSlidePage={true}
              // showCounter={showCounter}
              belowVisualDisplay={belowVisualDisplay}
              pageDisplaySettings={pageDisplaySettings}
              pollType={pollType}
              hideCloseButton={this.props.poll.settings.hideCloseButton}
              hideXButton={this.props.poll.settings.hideXButton}
              {...settings }
            />
          </div>
        </div>
      </div>
    </div>
    );
  }
}

class VisualDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = { currentIdx: 0, active: true, prevIndexes: [] };
  }

  componentDidUpdate(prevProps, prevState) {  
    if (!_.isEqual(prevProps.slides, this.props.slides)) {
      this.reset();
    }
  }

  next() {
    this.state.prevIndexes.push(this.state.currentIdx);
    this.setState({ currentIdx: this.state.currentIdx + 1 });
  }

  prev() {
    const idx = this.state.prevIndexes.pop();
    if (idx !== undefined) {
      this.setState({ currentIdx: idx });
    }
  }

  goto(slideId) {
    let idx = undefined;
    this.props.slides.forEach(({ _id }, i) => {
      if (_id === slideId) {
        idx = i;
      }
    })
    if (idx === undefined) { return this.next(); }

    this.state.prevIndexes.push(this.state.currentIdx);
    this.setState({ currentIdx: idx });
  }

  reset() {
    this.setState({ currentIdx: 0, prevIndexes: [] });
  }

  render() {
    let showEmbed = !this.props.loading;

    let subtitle;
    let title;
    let showTitle;
    if (this.props.slides.length === 0) {
      showTitle = true;
      subtitle = <div><i className="fas fa-info-circle warning"/>There are no slides for this survey.</div>
      showEmbed = false;
    }
    if (this.props.loading) {
      subtitle = 'Loading...';
    }

    let pollPreview = <Embed 
      { ...this.props }
      toggleActive={() => { this.setState({ active: !this.state.active})}}
      active={this.state.active}
      next={this.next.bind(this)}
      prev={this.prev.bind(this)}
      goto={this.goto.bind(this)}
      currentIdx={this.state.currentIdx}
      isEmbed={this.props.pollType === 'embed'}
    />

    if (['widget', 'embed', 'api'].indexOf(this.props.pollType) === -1) {
      pollPreview = <PagePreview 
        { ...this.props }
        toggleActive={() => { this.setState({ active: !this.state.active})}}
        active={this.state.active}
        next={this.next.bind(this)}
        prev={this.prev.bind(this)}
        goto={this.goto.bind(this)}
        currentIdx={this.state.currentIdx}
      />
    }

    if (!showEmbed) {
      pollPreview = null;
    }

    let belowVisualDisplay = this.props.belowVisualDisplay || null;

    return (
    <div className="visual-display-wrapper">
      <div className="visual-display">
        <BrowserFrame 
          showTitle={showTitle}
          title={title}
          subtitle={subtitle}
        />
        { pollPreview }
      </div>
      { belowVisualDisplay }
    </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.polls.loading,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...PollActions, ...FlashNotificationActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PollEditForm);

