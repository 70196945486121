import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { url } from '../settings';

import _ from 'lodash';

import * as AccountActions from '../actions/Accounts';
import * as FlashNotificationActions from '../actions/FlashNotifications';
import * as UserActions from '../actions/Users';

import ColumnLayout from '../components/ColumnLayout';
import ColumnLoading from './ColumnLoading';

import SectionHeader from '../components/SectionHeader';
import AuthForm from '../components/AuthForm';
import LoadingIndicator from '../components/LoadingIndicator';

import { decode } from '../utils';

import HeaderButtons from '../components/HeaderButtons';
import { AuthSubmitButton } from '../components/Buttons';

import Toggle from '../components/Toggle';
import Switch from '../components/Switch';
import Tooltip from '../components/Tooltip';

import { PrivateKeyForm, WebhookEndpointForm } from '../pages/ApiSettings';

import klaviyoLogo from '../images/klaviyoLogo.png';
import mailchimpLogo from '../images/mailchimpLogo.png';
import omnisendLogo from '../images/omnisendLogo.png';
import getResponseLogo from '../images/getResponseLogo.png';
import gorgiasLogo from '../images/gorgiasLogo.png';
import gaLogo from '../images/gaLogo.svg';
import activeCampaignLogo from '../images/activeCampaignLogo.png';
import slackLogo from '../images/slackLogo.png';
import googleSheetsLogo from '../images/googleSheetsLogo.png';
import shopifyLogo from '../images/shopifyLogo.png';
import tiktokLogo from '../images/tiktokLogo.png';
import salesforceLogo from '../images/salesforceLogo.png';
import shopifyFlowLogo from '../images/shopifyFlowLogo.png';

import copy from 'copy-to-clipboard';

class InlineInput extends Component {
  onChange(e) {
    this.props.onChange(e);
  }

  render() {
    let label = null;
    if (this.props.label) {
      label = (<label>{ this.props.label }{ this.props.tooltip }</label>);
    }

    let input = <div style={{ marginTop: 10 }}><select
      onChange={this.onChange.bind(this)}
      name={this.props.name}
      value={this.props.value}
    >{ this.props.options.map(({ title, value }) => <option value={value}>{ title }</option>)}</select></div>

    return (
      <div className="inline-input">
        { label }
        <span className="prefix">{ this.props.prefix }</span>
        { input }
      </div> 
    );
  }
}


class Input extends Component {
  render() {
    let tooltip = null;
    if (this.props.moreInfo) {
      tooltip = <Tooltip>{ this.props.moreInfo }</Tooltip>
    }

    let optional = null;
    if (this.props.optional) {
      optional = <span>(optional)</span>
    }

    return (
      <div className={`input ${this.props.className}`}>
        <div style={{ position: 'relative' }}>
          <label>{ this.props.label }{ tooltip }{ optional }</label>
          <input
            type={ this.props.type }
            placeholder={ this.props.placeholder }
            name={ this.props.name }
            onChange={ this.props.onChange.bind(this) }
            value={ this.props.value || '' }
            disabled={this.props.disabled ? 'disabled' : ''}
            autoComplete="new-password"
            autofill="off"
          />
        </div>
      </div>
    );
  }
}

class SensitiveInput extends Component {
  constructor(props) {
    super(props);
    this.state = { showSensitiveContent: false };
  }

  render() {
    let tooltip = null;
    if (this.props.moreInfo) {
      tooltip = <Tooltip>{ this.props.moreInfo }</Tooltip>
    }

    let optional = null;
    if (this.props.optional) {
      optional = <span>(optional)</span>
    }

    return (
      <div className={`input sensitive-data ${this.props.className}`}>
        <div style={{ position: 'relative' }}>
          <label>{ this.props.label }{ tooltip }{ optional }</label>
          <div className="input-wrapper">
            <div onClick={() => {
              this.setState({ showSensitiveContent: !this.state.showSensitiveContent });
            }}><i className={`fas ${this.state.showSensitiveContent ? 'fa-eye-slash' : 'fa-eye'}`}/></div>
            <input
              type={ this.props.type }
              placeholder={ this.props.placeholder }
              name={ this.props.name }
              type={ this.state.showSensitiveContent ? 'text' : 'password' }
              onChange={ this.props.onChange.bind(this) }
              value={ this.props.value || '' }
              disabled={this.props.disabled ? 'disabled' : ''}
              autoComplete="new-password"
              autofill="off"
            />
          </div>
        </div>
      </div>
    );
  }

}

class IntegrationSettingsForm extends AuthForm {
  constructor(props) {
    super(props);

    let integrationSettings = this.props.account.integrationSettings || {};
    let toggles = {};

    Object.keys(integrationSettings).forEach((key) => {
      toggles[key] = true;
    });

    this.state = {
      inputs: { integrationSettings },
      toggles
    }
    this.setInitialState();
    this.props.getGoogleUrl(this.props.account._id).then((url) => {
      this.setState({ googleUrl: url });
    });
  }

  validate() {
    const integrationSettings = this.state.inputs.integrationSettings;
    if (integrationSettings.klaviyo) {
      const klaviyoSettings = integrationSettings.klaviyo;
      if (!klaviyoSettings.publicKey) {
        this.props.flash("Public Key Required");
        return false;
      }
      if (!klaviyoSettings.privateKey) {
        this.props.flash("Private Key Required");
        return false;
      }
    }

    if (integrationSettings.mailchimp) {
      const mailchimpSettings = integrationSettings.mailchimp;
      if (!mailchimpSettings.apiKey) {
        this.props.flash("API Key Required");
        return false;
      }
    }

    if (integrationSettings.omnisend) {
      const omnisendSettings = integrationSettings.omnisend;
      if (!omnisendSettings.apiKey) {
        this.props.flash("API Key Required");
        return false;
      }
    }

    if (integrationSettings.getResponse) {
      const getResponseSettings = integrationSettings.getResponse;
      if (!getResponseSettings.apiKey) {
        this.props.flash("API Key Required");
        return false;
      }
      if (!getResponseSettings.listToken) {
        this.props.flash("List Token Required");
        return false;
      }
    }


    if (integrationSettings.activeCampaign) {
      const activeCampaignSettings = integrationSettings.activeCampaign;
      if (!activeCampaignSettings.apiKey) {
        this.props.flash("API Key Required");
        return false;
      }
      if (!activeCampaignSettings.apiUrl) {
        this.props.flash("API Url Required");
        return false;
      }
    }
    return true;
  }

  onSubmit(e) {
    e.stopPropagation();
    e.preventDefault();

    if (!this.validate()) { return; }

    const account = {};
    account._id = this.props.account._id;
    account.integrationSettings = this.state.inputs.integrationSettings;

    this.props.updateAccount(account, "Integration settings have been updated.");
  }

  onChange(e) {
    this.updateSetting(e.target.name, e.target.value);
  }

  updateSetting(key, value) {
    const inputs = this.state.inputs;
    const integrationSettings = { ...inputs.integrationSettings };
    _.set(integrationSettings, key, value);
    
    console.log(key);
    console.log(value);
    console.log(integrationSettings);

    if (!value) {
      _.unset(integrationSettings, key);

      /* unset if values added then all removed */
      if (key.indexOf('.') !== -1) {
        const k = key.split('.')[0];
        if (Object.keys(integrationSettings[k]).length === 0) {
          _.unset(integrationSettings, k);
        }
      }
    }

    inputs.integrationSettings = integrationSettings;
    this.setState({ inputs });
  }

  toggleIntegration(key, value) {
    const inputs = this.state.inputs;
    const toggles = this.state.toggles;
    const integrationSettings = { ...inputs.integrationSettings };

    if (value) {
      if (key === 'slack') {
        integrationSettings[key] = [];
      } else {
        integrationSettings[key] = {};
      }
      toggles[key] = true;
    } else {
      _.unset(integrationSettings, key);
      _.unset(toggles, key);
    }

    inputs.integrationSettings = integrationSettings;;
    this.setState({ inputs, toggles });
  }

  renderInputs() {
    let klaviyoInputs = null;
    if (this.state.toggles['klaviyo']) {
      const klaviyoSettings = this.state.inputs.integrationSettings['klaviyo'] || {};
      let publicKey = klaviyoSettings.publicKey;
      let privateKey = klaviyoSettings.privateKey;

      let autoSubscribeListId = null;
      if (klaviyoSettings.autoSubscribe) {
        autoSubscribeListId = (
          <div className="input" style={{ marginTop: 20 }}>
            <Input
              name="klaviyo.listId"
              value={klaviyoSettings.listId}
              onChange={this.onChange.bind(this)}
              placeholder="List Id"
              optional={true}
              label="List Id"
              moreInfo="If you want to auto-subscribe participants to a specific list, please enter it here."
            />
          </div>
        );
      }

      klaviyoInputs = (
      <span>
        <div style={{ marginTop: 20 }}>
          <Input
            name="klaviyo.publicKey"
            value={publicKey}
            onChange={this.onChange.bind(this)}
            placeholder="Public Key"
            label="Public Key"
            moreInfo={<span>You can learn more about API keys and how to access them <a href="https://help.klaviyo.com/hc/en-us/articles/115005062267-How-to-Manage-Your-Account-s-API-Keys" target="_blank">here</a>.</span>}
          />
          <SensitiveInput
            name="klaviyo.privateKey"
            value={privateKey}
            onChange={this.onChange.bind(this)}
            placeholder="Private Key"
            label="Private Key"
            type={this.state.showSensitiveData ? 'password' : 'text' }
            moreInfo={<span>You can learn more about API keys and how to access them <a href="https://help.klaviyo.com/hc/en-us/articles/115005062267-How-to-Manage-Your-Account-s-API-Keys" target="_blank">here</a>.</span>}
          />

          <div className="input">
            <label>Auto Subscribe<Tooltip>If you want to auto-subscribe participants please make sure you allow write access to subscriptions and lists in your API key.</Tooltip></label>
            <div className="inline-toggle">
              <Toggle
                active={klaviyoSettings.autoSubscribe}
                onChange={(value) => {
                  console.log(value);
                  this.onChange({ target: { name: 'klaviyo.autoSubscribe', value } })
                }}
              />
              <div className="subtle">Auto-subscribe new profiles automatically.</div>
            </div>
          </div>

          { autoSubscribeListId }
        </div>
      </span>);
    }

    let tiktokInputs = null;
    if (this.state.toggles['tiktok']) {
      const tiktokSettings = this.state.inputs.integrationSettings['tiktok'] || {};
      let apiKey = tiktokSettings.apiKey;

      tiktokInputs = (<div style={{ marginTop: 20 }}>
        <Input
          name="tiktok.apiKey"
          value={apiKey}
          onChange={this.onChange.bind(this)}
          placeholder="Advertiser Id"
          label="Advertiser Id"
          moreInfo={<span>You can learn more about API keys and how to access them <a href="https://tiktok.com/help/about-api-keys/" target="_blank">here</a>.</span>}
        />
      </div>);
    }

    let salesforceInputs = null;
    if (this.state.toggles['salesforce']) {
      const salesforceSettings = this.state.inputs.integrationSettings['salesforce'] || {};
      let consumerKey = salesforceSettings.consumerKey;
      let consumerSecret = salesforceSettings.consumerSecret;

      salesforceInputs = (<div style={{ marginTop: 20 }}>
        <Input
          name="salesforce.consumerKey"
          value={consumerKey}
          onChange={this.onChange.bind(this)}
          placeholder="Consumer Key"
          label="Consumer Key"
          moreInfo={<span>You can learn more about API keys and how to access them <a href="https://tiktok.com/help/about-api-keys/" target="_blank">here</a>.</span>}
        />
        <Input
          name="salesforce.consumerSecret"
          value={consumerSecret}
          onChange={this.onChange.bind(this)}
          placeholder="Consumer Secret"
          label="Consumer Secret"
          moreInfo={<span>You can learn more about API keys and how to access them <a href="https://tiktok.com/help/about-api-keys/" target="_blank">here</a>.</span>}
        />
      </div>);
    }

    let mailchimpInputs = null;
    if (this.state.toggles['mailchimp']) {
      const mailchimpSettings = this.state.inputs.integrationSettings['mailchimp'] || {};
      let apiKey = mailchimpSettings.apiKey;
      let listId = mailchimpSettings.listId;

      mailchimpInputs = (<div style={{ marginTop: 20 }}>
        <SensitiveInput
          name="mailchimp.apiKey"
          value={apiKey}
          onChange={this.onChange.bind(this)}
          placeholder="API Key"
          label="API Key"
          moreInfo={<span>You can learn more about API keys and how to access them <a href="https://mailchimp.com/help/about-api-keys/" target="_blank">here</a>.</span>}
        />
        <Input
          name="mailchimp.listId"
          value={listId}
          onChange={this.onChange.bind(this)}
          placeholder="Audience Id"
          optional={true}
          label="Audience Id"
          moreInfo={<span>You can learn more about Audience Ids keys and how to access them <a href="https://mailchimp.com/help/find-audience-id/" target="_blank">here</a>. If no id is entered will we use the first audience we find.</span>}
        />
      </div>);
    }

    let omnisendInputs = null;
    if (this.state.toggles['omnisend']) {
      const omnisendSettings = this.state.inputs.integrationSettings['omnisend'] || {};
      let apiKey = omnisendSettings.apiKey;

      omnisendInputs = (<div style={{ marginTop: 20 }}>
        <SensitiveInput
          name="omnisend.apiKey"
          value={apiKey}
          onChange={this.onChange.bind(this)}
          placeholder="API Key"
          label="API Key"
          moreInfo={<span>You can learn more about API keys and how to access them <a href="https://www.zigpoll.com/blog/omnisend-integration" target="_blank">here</a>.</span>}
        />
      </div>);
    }

    let getResponseInputs = null;
    if (this.state.toggles['getResponse']) {
      const getResponseSettings = this.state.inputs.integrationSettings['getResponse'] || {};
      let apiKey = getResponseSettings.apiKey;
      let listToken = getResponseSettings.listToken;

      getResponseInputs = (<div style={{ marginTop: 20 }}>
        <SensitiveInput
          name="getResponse.apiKey"
          value={apiKey}
          onChange={this.onChange.bind(this)}
          placeholder="API Key"
          label="API Key"
          moreInfo={<span>You can learn more about API keys and how to access them <a href="https://getsitecontrol.com/help/integrate-widgets-getresponse/#:~:text=To%20find%20your%20API%20key,Now%20your%20accounts%20are%20connected." target="_blank">here</a>.</span>}
        />
        <Input
          name="getResponse.listToken"
          value={listToken}
          onChange={this.onChange.bind(this)}
          placeholder="List Token"
          label="List Token"
          moreInfo={<span>You can learn more about your List Token and how to access it <a href="https://support.exitbee.com/email-marketing-crm-integrations/how-to-find-your-get-response-list-id" target="_blank">here</a>.</span>}
        />
      </div>);
    }

    let activeCampaignInputs = null;
    if (this.state.toggles['activeCampaign']) {
      const activeCampignSettings = this.state.inputs.integrationSettings['activeCampaign'] || {};
      let apiKey = activeCampignSettings.apiKey;
      let apiUrl = activeCampignSettings.apiUrl;

      let eventKey = activeCampignSettings.eventKey;
      let eventActId = activeCampignSettings.eventActId;

      activeCampaignInputs = (<div style={{ marginTop: 20 }}>
        <SensitiveInput
          name="activeCampaign.apiKey"
          value={apiKey}
          onChange={this.onChange.bind(this)}
          placeholder="API Key"
          label="API Key"
          moreInfo={<span>You can learn more about API keys and how to access them <a href="https://help.activecampaign.com/hc/en-us/articles/207317590-Getting-started-with-the-API" target="_blank">here</a>.</span>}
        />
        <Input
          name="activeCampaign.apiUrl"
          value={apiUrl}
          onChange={this.onChange.bind(this)}
          placeholder="API Url"
          label="API Url"
          moreInfo={<span>You can learn more about your API Url and how to access it <a href="https://help.activecampaign.com/hc/en-us/articles/207317590-Getting-started-with-the-API" target="_blank">here</a>.</span>}
        />

        <Input
          name="activeCampaign.eventKey"
          value={eventKey}
          onChange={this.onChange.bind(this)}
          placeholder="Event Key"
          label="Event Key"
          optional={true}
          moreInfo={<span>You can learn more about your Event Key and how to access it <a href="https://help.activecampaign.com/hc/en-us/articles/221870128-An-overview-of-Event-Tracking" target="_blank">here</a>.</span>}
        />
        <Input
          name="activeCampaign.eventActId"
          value={eventActId}
          onChange={this.onChange.bind(this)}
          placeholder="Event ActId"
          optional={true}
          label="Event ActId"
          moreInfo={<span>You can learn more about your Event ActId and how to access it <a href="https://help.activecampaign.com/hc/en-us/articles/221870128-An-overview-of-Event-Tracking" target="_blank">here</a>.</span>}
        />
      </div>);
    }

    let gorgiasInputs = null;
    if (this.state.toggles['gorgias']) {
      const getResponseSettings = this.state.inputs.integrationSettings['gorgias'] || {};
      const link = `https://api.zigpoll.com/gorgias?accountId=${this.props.account._id}&email={{ticket.customer.email}}`
      gorgiasInputs = (<div className="input" style={{ marginTop: 20 }}>
        <label>Integration URL<Tooltip><a href="https://www.zigpoll.com/blog/gorgias-integration" target='_blank'>Click here</a> to learn more about how to configure your Gorgias HTTP integration and how to use this URL.</Tooltip></label>
        <div className={`public-poll-link`}>
          <a
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            copy(link);
            this.props.flash('The link has been copied to your clipboard.');
          }}
          target="_blank" title={link} rel="noopener noreferrer" href={link}>{link}</a>
        </div>
      </div>);
    }

    const slackRedirectUrl = `https:${url}/slack/auth/callback`;
    const slackClientId = '3825034372515.3818521083238';
    const slackState = this.props.account._id;

    let slackInputs = null;
    if (this.state.toggles['slack']) {

      const polls = this.props.account.polls || [];
      const pollOptions = polls.map(({ title, _id }) => { return { title: title, value: _id } });
      pollOptions.unshift({ title: 'All Surveys', value: false });

      let slackSettingsArray = this.state.inputs.integrationSettings['slack'] || [];
      if (!_.isArray(slackSettingsArray)) {
        slackSettingsArray = [slackSettingsArray];
      }

      slackInputs = <div>{slackSettingsArray.map((slackSettings, idx) => {
        return (<div className="slack-input" style={{ marginTop: 20, position: 'relative' }}>
            {/*
            <div style={{ marginBottom: 20 }}>
              <label>Integration Behavior <Tooltip>Default will send notifications to Slack for all surveys. If you want to split it out per survey or have multiple channels please select "specific surveys" instead.</Tooltip></label>
              <Switch
                options={[
                  { label: 'Connect all surveys', value: 'mrkdwn' },
                  { label: 'Connect specific surveys only', value: 'plain_text' }
                ]}
                onChange={(value) => {
                  this.onChange({ target: { name: 'slack.type', value } })
                }}
                value={slackSettings.format === undefined ? 'mrkdwn' : slackSettings.format }
              />
            </div>
            */}

            <div className="has-dynamic-input">
              <div className="remove" onClick={() => {
                let slackSettingsArray = this.state.inputs.integrationSettings['slack'] || [];
                if (!_.isArray(slackSettingsArray)) {
                  slackSettingsArray = [slackSettingsArray];
                }
                slackSettingsArray.splice(idx, 1);
                this.onChange({ target: { name: 'slack', value: slackSettingsArray } })
              }}><i className="fas fa-close" />Remove</div>
              <Input
                name="slack.incoming_webhook.channel"
                disabled={true}
                value={slackSettings.incoming_webhook ? slackSettings.incoming_webhook.channel : ''}
                onChange={this.onChange.bind(this)}
                placeholder="Channel"
                label="Channel"
                moreInfo={<span>Messages will be posted to this channel.</span>}
              />

              <InlineInput
                name={`slack[${idx}].pollId`}
                value={slackSettings.pollId}
                prefix="Send notifications to this channel for:"
                options={pollOptions}
                onChange={this.onChange.bind(this)}
              />
          </div>

          <div className="card-divider" style={{ padding: '1px 0px', margin: '20px 0' }} />

        </div>)
      })}

      { !this.state.showAddSlack && <div className="blue-link" style={{ marginTop: 20 }} onClick={() => {
      const url = `https://slack.com/oauth/v2/authorize?client_id=${slackClientId}&scope=incoming-webhook&redirect_uri=${slackRedirectUrl}&state=${slackState}&user_scope=`;
      window.location.href = url;
      }}><i className="fas fa-plus"/> Click here to add another channel.</div> }
      </div>

      if (!slackSettingsArray.length) {
        slackInputs = (
          <div style={{marginTop: 20}}>
            <a href={`https://slack.com/oauth/v2/authorize?client_id=${slackClientId}&scope=incoming-webhook&redirect_uri=${slackRedirectUrl}&state=${slackState}&user_scope=`}><img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" /></a>
          </div>
        );
      }
    }

    let googleSheetsInputs = null;
    if (this.state.toggles['googleSheets']) {
      const googleSheetsSettings = this.state.inputs.integrationSettings['googleSheets'] || {};
      let authButton = null;

      if (!this.state.inputs.integrationSettings['googleSheets'].scope) {
        authButton = <a className="google-sheets-auth-button" href={ this.state.googleUrl }><img src={googleSheetsLogo} />Click To Authenticate</a>
      }

      googleSheetsInputs = (<div style={{ marginTop: 20 }}>
        <Input
          name="googleSheets.documentId"
          disabled={googleSheetsSettings.scope ? false : true}
          value={googleSheetsSettings.documentId}
          onChange={this.onChange.bind(this)}
          placeholder="<YOUR-DOC-ID>"
          label="Document Id"
          moreInfo={<span>Responses will be written to this Google Sheet</span>}
        />
        { authButton }
      </div>);
    }

    return (
    <span>
      <div className="card" style={{ marginBottom: 25 }}>
        <div className="input">
          <label><img src={klaviyoLogo} />Klaviyo<Tooltip><a href="https://docs.zigpoll.com/integrations" target='_blank'>Click here</a> to learn more about our Klaviyo integration.</Tooltip></label>
          <div className="inline-toggle">
            <Toggle
              active={this.state.toggles.klaviyo}
              disabled={false}
              onChange={(value) => {
                this.toggleIntegration('klaviyo', value);
              }}
            />
            <div className="subtle">Enable to complete your Klaviyo integration.</div>
          </div>
        </div>
        { klaviyoInputs }
      </div>

      {/*
      <div className="card" style={{ marginBottom: 25 }}>
        <div className="input">
          <label><img src={tiktokLogo} />TikTok<Tooltip><a href="https://docs.zigpoll.com/integrations" target='_blank'>Click here</a> to learn more about our TikTok integration.</Tooltip></label>
          <div className="inline-toggle">
            <Toggle
              active={this.state.toggles.tiktok}
              disabled={false}
              onChange={(value) => {
                this.toggleIntegration('tiktok', value);
              }}
            />
            <div className="subtle">Enable to complete your TikTok integration.</div>
          </div>
        </div>
        { tiktokInputs }
      </div>
      */}

      <div className="card" style={{ marginBottom: 25 }}>
        <div className="input">
          <label><img src={mailchimpLogo} />Mailchimp<Tooltip><a href="https://docs.zigpoll.com/integrations" target='_blank'>Click here</a> to learn more about our Mailchimp integration.</Tooltip></label>
          <div className="inline-toggle">
            <Toggle
              active={this.state.toggles.mailchimp}
              disabled={false}
              onChange={(value) => {
                this.toggleIntegration('mailchimp', value);
              }}
            />
            <div className="subtle">Enable to complete your Mailchimp integration.</div>
          </div>
        </div>
        { mailchimpInputs }
      </div>

      <div className="card" style={{ marginBottom: 25 }}>
        <div className="input">
          <label><img src={omnisendLogo} />Omnisend<Tooltip><a href="https://www.zigpoll.com/blog/omnisend-integration" target='_blank'>Click here</a> to learn more about our Omnisend integration.</Tooltip></label>
          <div className="inline-toggle">
            <Toggle
              active={this.state.toggles.omnisend}
              disabled={false}
              onChange={(value) => {
                this.toggleIntegration('omnisend', value);
              }}
            />
            <div className="subtle">Enable to complete your Omnisend integration.</div>
          </div>
        </div>
        { omnisendInputs }
      </div>

      {/*
      <div className="card" style={{ marginBottom: 25 }}>
        <div className="input">
          <label><img src={salesforceLogo} />Salesforce<Tooltip><a href="https://docs.zigpoll.com/integrations" target='_blank'>Click here</a> to learn more about our TikTok integration.</Tooltip></label>
          <div className="inline-toggle">
            <Toggle
              active={this.state.toggles.salesforce}
              disabled={false}
              onChange={(value) => {
                this.toggleIntegration('salesforce', value);
              }}
            />
            <div className="subtle">Enable to complete your Salesforce integration.</div>
          </div>
        </div>
        { salesforceInputs }
      </div>
      */}

      <div className="card" style={{ marginBottom: 25 }}>
        <div className="input">
          <label><img src={getResponseLogo} />GetResponse<Tooltip><a href="https://docs.zigpoll.com/integrations" target='_blank'>Click here</a> to learn more about our GetResponse integration.</Tooltip></label>
          <div className="inline-toggle">
            <Toggle
              active={this.state.toggles.getResponse}
              disabled={false}
              onChange={(value) => {
                this.toggleIntegration('getResponse', value);
              }}
            />
            <div className="subtle">Enable to complete your GetResponse integration.</div>
          </div>
        </div>
        { getResponseInputs }
      </div>

      <div className="card" style={{ marginBottom: 25 }}>
        <div className="input">
          <label><img src={activeCampaignLogo} />ActiveCampaign<Tooltip><a href="https://docs.zigpoll.com/integrations" target='_blank'>Click here</a> to learn more about our ActiveCampaign integration.</Tooltip></label>
          <div className="inline-toggle">
            <Toggle
              active={this.state.toggles.activeCampaign}
              disabled={false}
              onChange={(value) => {
                this.toggleIntegration('activeCampaign', value);
              }}
            />
            <div className="subtle">Enable to complete your ActiveCampaign integration.</div>
          </div>
        </div>
        { activeCampaignInputs }
      </div>

      <div className="card" style={{ marginBottom: 25 }}>
        <div className="input">
          <label><img src={gorgiasLogo} />Gorgias<Tooltip><a href="https://docs.zigpoll.com/integrations" target='_blank'>Click here</a> to learn more about our Gorgias integration.</Tooltip></label>
          <div className="inline-toggle">
            <Toggle
              active={this.state.toggles.gorgias}
              disabled={false}
              onChange={(value) => {
                this.toggleIntegration('gorgias', value);
                this.updateSetting('gorgias.enabled', value);
              }}
            />
            <div className="subtle">Enable to configure your Gorgias integration.</div>
          </div>
        </div>
        { gorgiasInputs }
      </div>

      <div className="card" style={{ marginBottom: 25 }}>
        <div className="input">
          <label><img src={gaLogo} />Google Analytics<Tooltip><a href="https://docs.zigpoll.com/integrations" target='_blank'>Click here</a> to learn more about our Google Analytics integration.</Tooltip></label>
          <div className="inline-toggle">
            <Toggle
              active={this.state.toggles.googleAnalytics}
              disabled={false}
              onChange={(value) => {
                this.toggleIntegration('googleAnalytics', value);
              }}
            />
            <div className="subtle">Enable to activate your GA integration.</div>
          </div>
        </div>
      </div>

      <div className="card" style={{ marginBottom: 25 }}>
        <div className="input">
          <label><img src={slackLogo} />Slack<Tooltip><a href="https://docs.zigpoll.com/integrations" target='_blank'>Click here</a> to learn more about our Slack integration.</Tooltip></label>
          <div className="inline-toggle">
            <Toggle
              active={this.state.toggles.slack}
              disabled={false}
              onChange={(value) => {
                this.toggleIntegration('slack', value);
              }}
            />

            <div className="subtle">Enable to activate your Slack integration.</div>
          </div>

          { slackInputs }
        </div>
      </div>

      <div className="card" style={{ marginBottom: 25 }}>
        <div className="input">
          <label><img src={shopifyLogo} />Shopify<Tooltip><a href="https://docs.zigpoll.com/integrations" target='_blank'>Click here</a> to learn more about our Shopify integration.</Tooltip></label>
          <div className="inline-toggle">
            <Toggle
              active={this.props.account.shop ? true : false}
              disabled={true}
              onChange={(value) => {
                this.toggleIntegration('slack', value);
              }}
            />

            <div className="subtle">{ this.props.account.shop ? 'Your shopify integration is enabled.' : <span>To use our Shopify integration, <a style={{ textDecoration: 'underline' }} href="https://apps.shopify.com/zigpoll" target="_blank">install the app</a>.</span> }</div>
          </div>
        </div>
      </div>

      <div className="card" style={{ marginBottom: 25 }}>
        <div className="input">
          <label><img src={shopifyFlowLogo} />Shopify Flow<Tooltip><a href="https://www.zigpoll.com/blog/shopify-flow-integration" target='_blank'>Click here</a> to learn more about our Shopify Flow integration.</Tooltip></label>
          <div className="inline-toggle">
            <Toggle
              active={this.props.account.shop ? true : false}
              disabled={true}
              onChange={(value) => {
                this.toggleIntegration('slack', value);
              }}
            />

            <div className="subtle">{ this.props.account.shop ? 'Your shopify integration is enabled.' : <span>To use our Shopify Flow integration, <a style={{ textDecoration: 'underline' }} href="https://apps.shopify.com/zigpoll" target="_blank">install the app</a>.</span> }</div>
          </div>
        </div>
      </div>


      {/*
      <div className="card" style={{ marginBottom: 25 }}>
        <div className="input">
          <label><img src={googleSheetsLogo} />Google Sheets<Tooltip><a href="https://docs.zigpoll.com/integrations" target='_blank'>Click here</a> to learn more about our Google Sheets integration.</Tooltip></label>
          <div className="inline-toggle">
            <Toggle
              active={this.state.toggles.googleSheets}
              disabled={false}
              onChange={(value) => {
                this.toggleIntegration('googleSheets', value);
              }}
            />

            <div className="subtle">Enable to activate your Google Sheets integration.</div>
          </div>

          { googleSheetsInputs }
        </div>
      </div>
      */}
    </span>);
  }

  renderActions() {
    return (
      <div className="actions card">
        <AuthSubmitButton
          title="Save Changes" 
          disabled={!this.hasChanged()}
        />

        <div className="login">
          <div>Need help with integrations?</div>
          <a href={`https://docs.zigpoll.com/integrations`} target="_blank" rel="noopener noreferrer">Learn more</a>
        </div>

        <HeaderButtons>
          <button 
            disabled={!this.hasChanged()}
            onClick={this.onSubmit.bind(this)}>Save Changes</button>
        </HeaderButtons>
      </div>
    )
  }

  render() {
    return (
      <form onSubmit={this.onSubmit.bind(this)} className={`${this.state.valid === false ? 'invalid' : ''} ${this.state.loading || this.props.loading ? 'loading' : ''} ${this.props.className}`}>
          { this.renderInputs() }
          { this.renderActions() }
      </form>
    );
  }
}

class IntegrationSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    if (!this.props.account && this.props.accountId) {
      this.props.fetchAccount(this.props.accountId);
    }
  }

  render() {
    if (!this.props.account) {
      return <ColumnLoading />
    }

    return (
      <ColumnLayout title="Integrations" graphics={true}>

        <SectionHeader
          title=<span><i className="fas fa-plug" />Integrations</span>
          subtitle="Connect Zigpoll to your favorite apps."
          className="no-margin"
        />

        <IntegrationSettingsForm
          account={this.props.account}
          getGoogleUrl={this.props.getGoogleUrl}
          updateAccount={this.props.updateAccount}
          flash={this.props.flash}
          loading={this.props.loading}
        />

        <PrivateKeyForm user={this.props.user} createPrivateApiKey={this.props.createPrivateApiKey} removePrivateApiKey={this.props.removePrivateApiKey} flash={this.props.flash} />

        <WebhookEndpointForm user={this.props.user} account={this.props.account} createWebhook={this.props.createWebhook} removeWebhook={this.props.removeWebhook} flash={this.props.flash} />

        <LoadingIndicator loading={this.props.accounts.loading} />

      </ColumnLayout>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const user = state.user;
  const accountId = decode(ownProps.match.params.accountId);
  const pollId = decode(ownProps.match.params.pollId);
  const slideId = decode(ownProps.match.params.slideId);
  const accounts = state.accounts || {};
  const account = accounts[accountId];

  return {
    user,
    accountId,
    pollId,
    slideId,
    accounts,
    account,
    loading: accounts.loading
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...AccountActions, ...UserActions, ...FlashNotificationActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationSettings);
