import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ColumnLoading from './ColumnLoading';

import ColumnLayout from '../components/ColumnLayout';

import SlideInfo from '../components/SlideInfo';
import Tooltip from '../components/Tooltip';
import Diff from '../components/Diff';
import PollSelector from '../components/PollSelector';
import SlideIdCard from '../components/SlideIdCard';

import * as DashboardActions from '../actions/Dashboard';

import { decode, encode, renderNumber, renderAccountTitle } from '../utils';

import qs from 'query-string';

class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.fetchSlideData(this.props.slideId);
  }

  componentDidUpdate(prevProps) {
    if (this.props.slideId !== prevProps.slideId) {
      return this.props.fetchSlideData(this.props.slideId);
    }
  }

  render() {
    let account;

    if (!this.props.slide) {
      return <ColumnLoading />
    }

    return (
      <ColumnLayout
        title="Slide → Dashboard"
        className="dashboard"
        graphics={false}
      >
        <div style={{ minWidth: 1100 }}>
          <div className="dashboard-container" style={{ minHeight: '0px' }}>
            <div style={{ margin: '0px auto', width: '100%' }}>

              <div className="card-subtitle" style={{ textAlign: 'center' }}>Slide Results</div>
              <div className='poll-card slide-dashboard'>
                <div>
                  <div className="poll-content">
                    <SlideInfo slide={this.props.slide} diffs={this.props.diffs} accountId={this.props.accountId} pollId={this.props.pollId} />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </ColumnLayout>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const accountId = decode(ownProps.match.params.accountId);
  const accounts = state.accounts || {};
  const account = accounts[accountId];
  // const pollId = state.dashboard.pollId;

  const pollId = decode(ownProps.match.params.pollId);

  const slideId = decode(ownProps.match.params.slideId);
  const slides = state.slides || [];
  const slide = slides[slideId];


  return {
    account,
    accounts,
    accountId,
    pollId,
    slideId,
    slide,
    user: state.user,
    diffs: state.diffs,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(DashboardActions, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
