import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import RangeNextSlideInput from './RangeNextSlideInput';

import _ from 'lodash';

import * as FlashNotificationsActions from '../actions/FlashNotifications';

import { handleize } from '../utils';

const answerTitles = ['Very Unsatisfied','Unsatisfied','Neutral','Satisfied','Very Satisfied'];
const answerHandles = ['very-unsatisfied','unsatisfied','neutral','satisfied','very-satisfied'];

class RangeInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      min: props.min || 'very-unsatisfied',
      max: props.max || 'very-satisfied'
    };
  }

  onMinChange(e) {
    this.setState({ min: e.target.value }, this.update.bind(this))
  }

  onMaxChange(e) {
    this.setState({ max: e.target.value }, this.update.bind(this))
  }

  update() {
    const answers = [];

    const min = answerHandles.indexOf(this.state.min);
    const max = answerHandles.indexOf(this.state.max);
    for (var i = min; i <= max; i++) {
      answers.push({ title: answerTitles[i], handle: answerHandles[i] });
    }

    this.props.onChange({ target: { name: 'answers', value: answers } })
  }

  render() {
    return (
      <div className="range-input-box" style={{ marginBottom: 10 }}>
        <div className="input unchanged">
          <div>
            Values range from <select
              style={{ width: 170, textAlign: 'left', textAlignLast: 'left' }}
              onChange={this.onMinChange.bind(this)}
            >{ answerTitles.slice(0, 2).map((val) => {
              return <option value={handleize(val, this.props.slideId)} selected={handleize(val, this.props.slideId) === this.state.min}>{val}</option>
            }) }</select> to <select
              style={{ width: 170, textAlign: 'left', textAlignLast: 'left' }}
              onChange={this.onMaxChange.bind(this)}
            >{ answerTitles.slice(3,5).map((val) => {
              return <option value={handleize(val, this.props.slideId)} selected={handleize(val, this.props.slideId) === this.state.max}>{val}</option>
            }) }</select>
          </div>
          {/* <RangeNextSlideInput
            slides={this.props.slides}
            nextSlide={this.props.nextSlide}
            value={this.props.title || 'Dynamic Input'}
            onChange={this.onChange.bind(this)}
            min={this.state.min}
            max={this.state.max}
            answers={this.props.answers}
          />*/}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(FlashNotificationsActions, dispatch);
}

export default connect(null, mapDispatchToProps, null, { withRef: true })(RangeInput);
