import _ from 'lodash';

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';

import { NameInput } from './SimpleInputs';
import Modal from './Modal';
import Toggle from './Toggle';
import Tooltip from './Tooltip';
import NextSlideInput from './NextSlideInput';

import * as FlashNotificationsActions from '../actions/FlashNotifications';

import { handleize, move } from '../utils';

import { DragDropContext, DragSource, DropTarget } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

/* Drag */
const dragSource = {
  beginDrag(props) {
    return {
      idx: props.idx
    };
  }
};

const dragCollect = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  }
};

let direction;
const dropTarget = {
  hover(props, monitor, component) {
    const dragIdx = monitor.getItem().idx;
    let dropIdx = props.idx;

    if (dragIdx < dropIdx) {
      direction = 'down';
      component.forceUpdate();
    } else if (dragIdx > dropIdx) {
      direction = 'up';
      component.forceUpdate();
    }
  },
  drop(props, monitor, component) {
    const dragIdx = monitor.getItem().idx;
    let dropIdx = props.idx;

    if (dragIdx === dropIdx) {
      return;
    }

    let idx;
    if (dragIdx < dropIdx) {
      idx = dropIdx - 1 < 0 ? 0 : dropIdx;
      props.reorderAnswers(dragIdx, idx);
    } else {
      idx = dropIdx + 1 >= props.pollCount ? props.pollCount - 1 : dropIdx;
      props.reorderAnswers(dragIdx, idx);
    }
  }
};

const dropCollect = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver()
})

class DumbDragHandle extends Component {
  render() {
    const { connectDragSource } = this.props;
    return connectDragSource(<span className="handle" />)
  }
}

class DumbDropContext extends Component {
  render() {
    const { connectDropTarget, isOver } = this.props;
    return connectDropTarget(<div className={`drop-context ${isOver ? `is-over ${direction}` : ''}`}>{ this.props.children }</div>);
  }
}

const DragHandle = compose(
  DragSource('ANSWER', dragSource, dragCollect),
)(DumbDragHandle);

const DropContext = compose(
  DropTarget('ANSWER', dropTarget, dropCollect)
(DumbDropContext))

class Answer extends Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false };
  }

  onChange(e) {
    this.props.onChange(e, this.props.idx);
  }

  onValidate(e) {
    this.props.onValidate(e, this.props.idx);
  }

  setRef(element) {
    if (!this.inputs) {
      this.inputs = {};
    }
    if (element) {
      this.inputs[element.props.name] = element;
    }
  }

  validate() {
    let valid = true;

    // for (const key in this.inputs) {
    //   if (!this.inputs[key].validate()) {
    //     valid = false;
    //   }
    // }

    return valid;
  }

  removeAnswer(e) {
    this.props.removeAnswer(this.props.idx);
  }

  showModal() {
    this.setState({ showModal: true });
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  render() {
    return <DropContext
      reorderAnswers={this.props.reorderAnswers}
      idx={this.props.idx}
    ><div className="answer">
      <DragHandle idx={this.props.idx} />
      <NameInput
        onChange={this.onChange.bind(this)}
        onValidate={this.onValidate.bind(this)}
        label={`Answer #${this.props.idx + 1}`}
        type="text"
        placeholder="Public facing title for the answer"
        name="title"
        value={this.props.title}
        errorMessage="Please a title for the answer option."
        ref={this.setRef.bind(this)}
        // maxlength={40}
        // extraComponent={<NextSlideInput
        //   slides={this.props.slides}
        //   nextSlide={this.props.nextSlide}
        //   idx={this.props.idx}
        //   value={this.props.title}
        //   hidden={this.props.showRules ? false: true}
        //   onChange={this.props.onChange.bind(this)}
        // />}
      />
      { this.props.showAdvancedInputs && (<div className="clearfix">
        <NameInput
          onChange={this.onChange.bind(this)}
          onValidate={this.onValidate.bind(this)}
          label={`Handle`}
          type="text"
          placeholder="Unique handle for the answer"
          name="handle"
          value={this.props.handle}
          errorMessage="Please a handle for the answer option."
          ref={this.setRef.bind(this)}
          style={{ marginTop: -20, width: '100%', paddingRight: 0, float: 'left' }}
        />
      </div>) }
      <div className="answer-actions">
        <div className={`advanced-edit ${this.props.showAdvancedInputs ? 'active' : ''}`} onClick={() => this.props.toggleAdvancedInputs(this.props.idx)}><i className="fas fa-edit" />Advanced Edit</div>
        <div className="remove" onClick={() => {
          if (this.props.votes) {
            this.showModal();
          } else {
            this.removeAnswer();
          }
        }} ><i className="fas fa-trash" />Remove</div>
      </div>
      <Modal 
        isOpen={this.state.showModal}
        onRequestClose={this.closeModal.bind(this)}
      >
        <div className="frame">
          <div className="close" onClick={this.closeModal.bind(this)} />
          <div className="title">Are you sure?</div>
          <div className="content">
            <div className="subtitle">This will remove the answer and all responses & votes associated with it.</div>
            <div className="actions">
              <button className="positive" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                this.removeAnswer();
                this.closeModal();
              }}>Yes</button>
              <button className="negative" onClick={this.closeModal.bind(this)}>No</button>
            </div>
          </div>
        </div>
      </Modal>
    </div></DropContext>
  }
}

class AnswersInput extends Component {
  constructor(props) {
    super(props);

    let answers = [];
    let dynamicAnswer = {dynamic: true};

    props.answers.forEach((answer) => {
      if (answer.dynamic && this.props.showDynamicAnswer) {
        dynamicAnswer = answer;
      } else {
        answers.push(answer);
      }
    });

    this.state = {
      answers,
      dynamicAnswer,
      showDynamicAnswer: this.props.showDynamicAnswer && dynamicAnswer.handle ? true : false,
      advancedInputs: []
    };
  }

  reset() {
    this.setState({ valid: undefined, invalid: undefined });
  }

  addAnswer(e) {
    e.preventDefault();
    e.stopPropagation();

    const answers = this.state.answers;
    let hasEmpty = false;

    // answers.forEach(answer => {
    //   if (!answer.title) {
    //     hasEmpty = true;
    //   }
    // });

    // if (hasEmpty) { return; }

    answers.push({ title: '', handle: '' });
    this.setState({ answers });
  }

  removeAnswer(idx) {
    delete this.inputs[idx];
    const answers = this.state.answers;
    answers.splice(idx, 1);

    this.setState({ answers }, this.update.bind(this));
  }

  onChange(e, idx) {
    let answers = _.cloneDeep(this.state.answers);
    const answer = answers[idx];
    answer[e.target.name] = e.target.value;

    if (e.target.name === 'title' && this.state.advancedInputs.indexOf(idx) === -1) {
      answer.handle = handleize(e.target.value, this.props.slideId);
    }

    this.setState({ answers }, this.update.bind(this));
  }

  update() {
    const answers = this.state.answers.map(answer => { delete answer.votes; delete answer.totalOrderValue; delete answer.votesSinceOrderValueAdded; delete answer.rankings; return answer });

    _.remove(answers, ({ handle }) => !handle);

    if (this.state.showDynamicAnswer) {
      let dynamicAnswer = this.state.dynamicAnswer;

      if (!this.state.dynamicAnswer.handle) {
        dynamicAnswer = { title: 'Other? Write it in!', handle: handleize('Other? Write it in!', this.props.slideId), dynamic: true }
      }
      answers.push(dynamicAnswer);
    }

    const e = { target: { name: 'answers', value: answers } };
    this.props.onChange(e)
  }

  onDynamicChange(e) {
    const dynamicAnswer = this.state.dynamicAnswer;
    dynamicAnswer[e.target.name] = e.target.value;

    if (e.target.name === 'title' && !this.state.showDynamicAnswerAdvancedInputs) {
      dynamicAnswer.handle = handleize(e.target.value, this.props.slideId);
    }

    this.setState({ dynamicAnswer }, this.update.bind(this));
  }

  onValidate(e, idx) {
    this.props.onValidate('answers', true);
  }

  setRef(element) {
    if (!this.inputs) {
      this.inputs = {};
    }

    if (element) {
      if (element.getDecoratedComponentInstance && element.getDecoratedComponentInstance()) {
        element = element.getDecoratedComponentInstance();
      }
      this.inputs[element.props.idx] = element;
    }
  }

  reorderAnswers(dragIdx, dropIdx) {
    const answers = move(this.state.answers, dragIdx, dropIdx);
    this.setState({ answers, advancedInputs: [] }, this.update.bind(this));
  }

  validate() {
    let valid = true;

    for (const key in this.inputs) {
      if (!this.inputs[key].validate()) {
        valid = false;
      }
    }


    const answers = [ ...this.state.answers];
    _.remove(answers, ({ handle }) => !handle);

    var uniq = _.uniqBy(answers, answer => answer.handle);

    if (this.state.dynamicAnswer) {
      if (uniq.map(({ handle }) => handle).indexOf(this.state.dynamicAnswer.handle) !== -1) {
        this.props.flash('Duplicate answers detected. Please make sure each option is unique and try again.', 'warning');
        return;
      }
    }

    if (uniq.length !== answers.length) {
      const e = { target: { name: 'answers', value: uniq } };
      this.props.onChange(e)

      this.props.flash('Duplicate answers detected. They were automatically removed.', 'warning');

      this.setState({ answers: uniq });
    }

    return valid;
  }

  toggleAdvancedInputs(index) {
    let advancedInputs = this.state.advancedInputs || [];
    let idx = advancedInputs.indexOf(index);
    if (idx !== -1) {
      advancedInputs.splice(idx, 1);
    } else {
      advancedInputs.push(index);
    }
    this.setState({ advancedInputs })
  }

  renderAnswers() {
    let answers = null;

    answers = this.state.answers.map(({ title, handle, value, nextSlide, votes }, idx) => (
      <Answer 
        key={idx}
        title={title}
        handle={handle}
        value={value}
        nextSlide={nextSlide}
        idx={idx}
        votes={votes}
        onChange={this.onChange.bind(this)}
        onValidate={this.onValidate.bind(this)}
        removeAnswer={this.removeAnswer.bind(this)}
        ref={this.setRef.bind(this)}
        slideId={this.props.slideId}
        slides={this.props.slides}
        showRules={this.props.showRules}
        reorderAnswers={this.reorderAnswers.bind(this)}
        toggleAdvancedInputs={this.toggleAdvancedInputs.bind(this)}
        showAdvancedInputs={this.state.advancedInputs.indexOf(idx) !== -1}
      />
    ));

    return answers;
  }

  render() {
    let dynamicAnswerInput = null;

    if (this.state.showDynamicAnswer) {
      dynamicAnswerInput = <div className="answer no-padding"><NameInput
        onChange={this.onDynamicChange.bind(this)}
        noValidate={true}
        label="Answer Label"
        type="text"
        placeholder="Other? Write it in!"
        name="title"
        idx={1000}
        value={this.state.dynamicAnswer.title}
        errorMessage="Please a title for the dynamic answer."
        ref={this.setRef.bind(this)}
      />
      { this.state.showDynamicAnswerAdvancedInputs && (<div className="clearfix">
        <NameInput
          onChange={this.onDynamicChange.bind(this)}
          onValidate={this.onValidate.bind(this)}
          label={`Handle`}
          type="text"
          placeholder="Unique handle for the answer"
          name="handle"
          value={this.state.dynamicAnswer.handle}
          errorMessage="Please a handle for the answer option."
          ref={this.setRef.bind(this)}
          style={{ marginTop: -20, width: '100%', paddingRight: 0, float: 'left' }}
        />
      </div>) }
      <div className="answer-actions">
        <div className={`advanced-edit ${this.state.showDynamicAnswerAdvancedInputs ? 'active' : ''}`} onClick={() => this.setState({ showDynamicAnswerAdvancedInputs: !this.state.showDynamicAnswerAdvancedInputs })}><i className="fas fa-edit" />Advanced Edit</div>
        <div className="remove" onClick={() => {
          this.setState({ showDynamicAnswer: false, showDynamicAnswerAdvancedInputs: false, dynamicAnswer: { 'title': '', handle: '', dynamic: true } }, this.update.bind(this));
        }} ><i className="fas fa-trash" />Remove</div>
      </div>
      </div>
    } else {
      /* Remove the input reference */
      if (this.inputs) {
        delete this.inputs[1000];
      }
    }

    let dynamicAnswerToggle = null;
    if (this.props.showDynamicAnswer) {
      dynamicAnswerToggle = (
        <div className="input unchanged" style={{ marginTop: 40, marginBottom: 25 }}>
          <label>Dynamic "Other" Answer<Tooltip>A dynamic answer allows people to write in various responses. You can view these responses in your dashboard.</Tooltip></label>

          <div className="inline-toggle">
            <Toggle 
              active={this.state.showDynamicAnswer}
              onChange={(value) => {
                this.setState({ showDynamicAnswer: value, showDynamicAnswerAdvancedInputs: false, dynamicAnswer: { 'title': '', handle: '', dynamic: true } }, this.update.bind(this));
              }}
            />
            <p className="subtle">Include a dynamic answer option for open-ended responses</p>
          </div>
        </div>
      );
    }

    return (
      <DndContext><div className={`answers-input ${this.props.className}`}>
        {/*
        <div className="input" style={{ marginBottom: 20 }}>
          <label>Answer Options<Tooltip>Each option needs to be unique.</Tooltip></label>
        </div>
        */}

        { this.renderAnswers() }
        <div className="add-answer">
          <div onClick={this.addAnswer.bind(this)}>Add Answer</div>
        </div>

        { dynamicAnswerToggle }
        { dynamicAnswerInput }  

      </div></DndContext>
    );
  }
}

class Context extends Component {
  render() {
    return <div>{ this.props.children }</div>
  }
}

const DndContext = DragDropContext(HTML5Backend)(Context);

function mapDispatchToProps(dispatch) {
  return bindActionCreators(FlashNotificationsActions, dispatch);
}

export default connect(null, mapDispatchToProps, null, { withRef: true })(AnswersInput);
