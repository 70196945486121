import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import _ from 'lodash';

import { decode, getCards, getSubscription, getActiveCard, getSubscriptionCard, loadContactUsSurvey } from '../utils';
import { plans, planDetails } from '../settings';

import * as AccountActions from '../actions/Accounts';
import * as UserActions from '../actions/Users';

import Confirm from './Confirm';
import SectionHeader from './SectionHeader';
import PromotionInput from './PromotionInput'

import { AuthSubmitButton } from './Buttons';

/* TODO :: Clean up these components and this page in general */
class Card extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { card, pill, style } = this.props;

    if (!card) {
      return null;
    }

    let actions = null;
    if (this.props.hasMultipleCards) {
      actions = (
      <div className="actions">
        <button 
          type="button"
          className={`change-card ${this.props.changeCard ? 'cancel' : ''}`} onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            this.props.onClick();
          }}>{this.props.changeCard ? 'Cancel' : 'Change Card'}</button>
      </div>
      );
    }

    return (<div className="cc-details" style={style}>
      <div style={{ position: 'relative' }}><label>Card Information</label>{pill}</div>
      <p><span className="inline-label">Brand:</span><strong>{ card.brand }</strong></p>
      <p><span className="inline-label">Ending in:</span><strong>{ card.last4 }</strong></p>
      <p><span className="inline-label">Expires:</span><strong>{ card.exp_month } / { card.exp_year }</strong></p>

      { actions }
    </div>);
  }
}

class SelectCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onClick(e) {
    e.preventDefault();
    e.stopPropagation();

    this.props.onClick(this.props.card);
  }

  render() {
    const { card, pill, style } = this.props;

    return (<div className={`cc-details ${this.props.active ? 'active' : ''}`} style={style} onClick={this.onClick.bind(this)}>
      <div style={{ position: 'relative' }}><label>Card Information</label>{pill}</div>
      <p><span className="inline-label">Brand:</span><strong>{ card.brand }</strong></p>
      <p><span className="inline-label">Ending in:</span><strong>{ card.last4 }</strong></p>
      <p><span className="inline-label">Expires:</span><strong>{ card.exp_month } / { card.exp_year }</strong></p>
    </div>);
  }
}

class Cards extends Component {
  render() {
    if (!this.props.show) { return null; }

    const cards = this.props.cards;

    if (this.props.fetching) {
      return (
        <div className="object-list credit-cards">
          <div>Loading card data...</div>
      </div>);
    }

    if (!cards.length) {
      return (
        <div className="object-list credit-cards">
          <div>No cards have been added. Please add some to change your plan.</div>
        </div>);
    }

    return (
      <div>
        <SectionHeader
          title=<span><i class="fas fa-credit-card"></i>Saved Cards</span>
          className="no-margin"
          subtitle="Select a card from the list below and click update plan."
          tooltip="If you would like to add a new card you can do so from the profile page."
        />
        <div className="object-list credit-cards select-card">
          { this.props.cards.map((card) => {
            let active = false;
            if (card.id === this.props.activeCard.id) {
              active = true;
            }
            return (<SelectCard key={card.id} onClick={this.props.onClick} card={card} {...this.props} active={active} />)
          })}
        </div>
      </div>
    );
  }
}

class AccountSubscriptionForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showConfirm: false,
      changeCard: false,
    };

    this.setInitialState(true);
  }

  setInitialState(unmounted) {
    const planId = this.props.planId || plans[0].id;
    const user = this.props.user;
    const subscription = getSubscription(this.props.user, this.props.account.subscriptionId);

    let activeCard = getSubscriptionCard(user, subscription);
    if (!activeCard) {
      activeCard = getActiveCard(user)
    }

    this.initialState = {
      planId,
      activeCard
    }

    if (unmounted) {
      if (activeCard) {
        // eslint-disable-next-line
        this.state = { ...this.state, planId, activeCard };
      } else {
        // eslint-disable-next-line
        this.state = { ...this.state, planId };
      }
    } else {      
      this.setState({ planId, activeCard });
    }
  }

  componentDidMount() {
    if (!this.props.user.customer) {
      this.props.fetchStripeData().then(() => {
        this.setInitialState();
      });
    }
  }

  componentDidUpdate(prevProps) {
    if ((this.props.accounts.loading !== prevProps.accounts.loading) && this.props.accounts.loading === false) {
      this.props.fetchStripeData().then(() => {
        this.setState({ changeCard: false });
        this.setInitialState();
      });
    }
  }

  hasChanged() {
    return !_.isEqual({ planId: this.state.planId, activeCard: this.state.activeCard }, this.initialState);
  }

  onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ showConfirm: true });
  }

  onChange(e) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ planId: e.target.value });
  }

  render() {
    // if (!this.props.user.customer) {
    //   return null;
    // }

    const planId = this.state.planId;
    const plan = _.find(plans, (plan) => plan.id === planId) || plans[0];
    const cards = getCards(this.props.user) || [];
    const activeCard = this.state.activeCard;
    const hasPromo = this.props.account.lifetimeDeal || this.props.account.promo;

    let noCards = null;
    if (!activeCard && !hasPromo) {
      noCards = (
        <div className="empty-object-list">
          <h3>You don't have any credit cards saved.</h3>
          <p>Please add one if you would like to change your subscription from the Free plan.</p>
          <Link to={`/settings#billing`}>Add Card</Link>
        </div>
      );
    }

    let details = null;
    if (planDetails[plan.key]) {
      details = <div className="plan-details">
        <br/>
        <label>Subscription Details</label>
        <ul>{planDetails[plan.key].map((item) => {
          return <li>{ item }</li>
        })}</ul>
        <br/>
      </div>
    }

    const discount = this.props.account.discount || this.props.user.discount || {};
    const percentOff = discount.percentOff ? (1 - discount.percentOff / 100) : 1;
    return (<form className={`subscription-form ${this.props.accounts.loading ? 'loading' : ''}`}>
      <SectionHeader
        title=<span><i class="fas fa-file-invoice-dollar"></i>Subscription Plan</span>
        subtitle="Edit the subscription plan for this account."
        tooltip="The default credit card will be used if not otherwise specified."
      />

      <div className="subscription-details">

        <div className="card" style={{ marginBottom: 25, paddingBottom: 0, overflow: 'hidden' }}>
          <label>Subscription Plan</label>

          <div className="select-wrapper">
            <select value={planId} onChange={this.onChange.bind(this)}>
              { plans.map(({ label, id, price }) => {

                const str = `${label}: ${ price === 0 ? 'FREE' : `$${price} per month` }`;
                return (<option value={id} key={id}>{str}</option>)
              })}
            </select>
          </div>

          { details }

          <Card 
            card={activeCard}
            style={{ paddingBottom: 25 }}
            onClick={() => { this.setState({ changeCard: !this.state.changeCard, activeCard: this.initialState.activeCard }) }}
            pill={<span className="pill green">Active</span>}
            changeCard={this.state.changeCard}
            hasMultipleCards={cards.length > 1}
          />

          <div className="below">To change plans, select a new plan from the dropdown list above. All changes take effect immediately.<br/><br/>You can learn more about our subscription plans <a target="_blank" rel="noopener noreferrer" href="https://docs.zigpoll.com/subscription-plans">here</a>.<br/><br/>If no plan fits your needs, please <a href="https://www.zigpoll.com/contact" target="_blank" onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            loadContactUsSurvey(this.props.user);
          }}>contact us</a> and we will work with you to find a solution.</div>

        </div>

        { noCards }

        <Cards
          cards={cards}
          activeCard={activeCard}
          show={this.state.changeCard}
          fetching={this.props.user.fetching}
          onClick={(card) => { this.setState({ activeCard: card }) }}
        />

        <PromotionInput />

        <div className="card actions">
          <AuthSubmitButton
            title="Update Plan"
            disabled={this.props.accounts.loading || !activeCard || !this.hasChanged()}
            onClick={this.onSubmit.bind(this)}
          />

          <div className="">
            <div>Not sure what you need?</div>
            <a target="_blank" rel="noopener noreferrer" href="https://www.zigpoll.com/#pricing-plans">See all plans</a>
          </div>
        </div>

        <Confirm 
          title="Are you sure?"
          subtitle={<span>This will charge the account owner <strong>${plan.price * percentOff}</strong> per month. {this.props.account.promo ? <strong><br/><br/>This will also cancel your currently applied promotion.</strong> : ''}{this.props.account.lifetimeDeal ? <strong><br/><br/>This will also cancel your lifetime deal.</strong> : ''}</span>}
          show={this.state.showConfirm}
          onConfirm={() => {
            let source;

            if (this.state.activeCard) {
              source = this.state.activeCard.id;
            }

            this.props.changePlan(this.state.planId, source);
          }}
          onCancel={() => {
            this.setState({ showConfirm: false });
          }}
        />
      </div>
    </form>)
  }
}

function mapStateToProps(state, ownProps) {
  const accountId = decode(ownProps.match.params.accountId);
  const accounts = state.accounts || {};
  const account = accounts[accountId];
  const user = state.user;

  let planId;
  if (account.plan) {
    planId = account.plan.id;
  }

  return {
    accountId,
    accounts,
    account,
    planId,
    user
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...AccountActions, ...UserActions }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountSubscriptionForm));

