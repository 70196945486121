import React, { Component } from 'react';

import $ from 'jquery';
import _ from 'lodash';

import BrowserFrame from './BrowserFrame';
import EmailPreview from './EmailPreview';

export default class VisualDisplay extends Component {
  render() {
    return (
    <div className="visual-display-wrapper">
      <div className="visual-display no-max-height">
        <BrowserFrame
          backgroundColor={this.props.backgroundColor}
          subject={this.props.content.subject}
        />
        <EmailPreview
          {...this.props}
        />
      </div>
      { this.props.belowVisualDisplay }
    </div>
    );
  }
}