import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

import * as PollActions from '../actions/Polls';
import * as FlashNotificationActions from '../actions/FlashNotifications';

import { NameInput, DisplayRulesInput } from './SimpleInputs';
import { AuthSubmitButton } from './Buttons';
import Tooltip from './Tooltip';
import Switch from './Switch';
import AuthForm from './AuthForm';
import Modal from './Modal';
import Embed from './Embed';
import { DisplayRulesTemplatesInput, TemplatesInput } from './TemplateInputs';

import { getSlideLabel, decode } from '../utils';

import { pollTemplates, displayRulesTemplates } from '../settings';

const descriptions = {
  'every-page': 'This option will render the survey on every page of your website.',
  'template': <span>Select a template below for your survey. We provide templates for a variety of common use-cases, if you don't find one thats suitable, <a href="https://www.zigpoll.com/contact" target="_blank">let us know</a>.</span>,
  'link-only': <span>This option will present the survey using a sharable link <a href="https://survey.zigpoll.com/2kParn8gL6RvpveWu/2vLaXDvDpaVJjmngT" target="_blank">like this one</a>. You can use this link to embed a survey within emails, social media, or SMS campaigns.</span>,
  'manual': 'This option will render the survey on the pages you specify below.',
};

class PollCreateForm extends AuthForm {
  constructor(props)  {
    super(props);
    this.state = { 
      inputs: {
        title: '',
        pageRules: [],
        product: 'Your product',
        emailDelay: 1
      },
      valid: undefined
    };
    this.inputStates = {};
  }

  submitAction() {
    const poll = this.state.inputs;
    poll.accountId = this.props.account._id;

    this.props.createPoll(poll);
  }

  renderInputs() {
    let settings = this.props.account.displaySettings;

    let templatesInput = null;
    if (this.props.useSlidesTemplate) {
      templatesInput = <TemplatesInput
        product={this.state.inputs.product}
        settings={settings}
        slidesTemplate={this.state.inputs.slidesTemplate}
        onChange={this.onChange.bind(this)}
      />
    }

    let displayRulesTemplatesInput = null;
    let displayRulesManualInput = null;

    if (this.props.displayRulesOption === 'template') {
      displayRulesTemplatesInput = <DisplayRulesTemplatesInput
        emailDelay={this.state.inputs.emailDelay}
        emailDelayInterval={this.state.inputs.emailDelayInterval}
        shopifyWebhooks={this.state.inputs.shopifyWebhooks}
        displayRulesTemplate={this.state.inputs.displayRulesTemplate}
        isShopify={this.props.account.shop !== undefined}
        onChange={this.onChange.bind(this)}
        page="create"
      />
    }
    if (this.props.displayRulesOption === 'manual') {
      displayRulesManualInput = (<div className="card" style={{ marginTop: 15, paddingBottom: 0, maxWidth: 730 }}>
        <label>Manual Page Rules</label>
        <p className="subtle">Enter your rules below. You can edit these rules later.</p>

        <DisplayRulesInput
          hideAllPages={true}
          onChange={this.onChange.bind(this)}
          onValidate={this.onValidate.bind(this)}
          type="text"
          placeholder="URL"
          name="pageRules"
          value={this.state.inputs.pageRules}
          errorMessage="Please a title for the account."
          ref={this.setRef.bind(this)}
          domains={this.props.account.domains}
        />
      </div>);
    }

    return (
      <div className="inputs">
        <div className="step"><span>1</span>What will your survey say?</div>

        <div className="create-poll step-2" style={{ maxWidth: 600 }}>
          <div className="top">
            <label>Survey Template<Tooltip>If you want to get started quickly with a pre-built template select "use a template" and have a look at the options. You can edit this template once it has been created.</Tooltip></label>

            <Switch
              options={[
                { label: 'Build it from scratch', value: false },
                { label: 'Start with a template', value: true },
              ]}
              value={this.props.useSlidesTemplate}
              onChange={(value) => {
                this.props.set('useSlidesTemplate', value);
              }}
            />
          </div>

          <div className="bottom">
            <p className="subtle display-description"><i className="fas fa-info-circle" style={{ marginRight: 8 }} />{ this.props.useSlidesTemplate ? <span>Select a template below for your survey questions (you can edit everything later). If you can't find a template thats suitable for your use-case, <a href="https://www.zigpoll.com/contact" target="_blank">let us know</a>.</span> : 'Select this option if you already know what you want to ask and are willing to create all of your questions on your own.' }</p>
          </div>
        </div>

        { templatesInput }

        <div className="step"><span>2</span>Where will your survey show up?</div>

        <div className="create-poll step-2">
          <div className="top">
            <label>Display Rules<Tooltip><span>Fill out this section if you want different surveys to show up on different pages of your website. If you decide to fill it out, the survey will only show up on pages which match the rules you add. If you want the survey to show up on all pages select <strong>show survey on all pages</strong>.</span></Tooltip></label>

            <Switch
              options={[
                { label: 'Start with a template', value: 'template', tag: 'Web / Email' },
                { label: 'Every page', value: 'every-page', tag: 'Web' },
                { label: 'Link Only', value: 'link-only', tag: 'Link' },
                { label: 'Configure Manually', value: 'manual', tag: 'Web' },
              ]}
              value={this.props.displayRulesOption}
              onChange={(value) => {
                this.props.set('displayRulesOption', value);
              }}
            />
          </div>
            
          <div className="bottom">
            <p className="subtle display-description"><i className="fas fa-info-circle" style={{ marginRight: 8 }} />{ descriptions[this.props.displayRulesOption]}</p>
          </div>

        </div>

        { displayRulesManualInput }
        { displayRulesTemplatesInput }

        <div className="step"><span>3</span>What should your survey be named?</div>

        <div className={`card`}>
          <NameInput
            onChange={this.onChange.bind(this)}
            onValidate={this.onValidate.bind(this)}
            label="Survey Title"
            moreInfo="This title will be used for your reference so pick something simple that you can easily identify."
            type="text"
            placeholder="Example: Post Purchase Survey"
            name="title"
            value={this.state.inputs.title}
            errorMessage="Please a title for the survey."
            ref={this.setRef.bind(this)}
          />
        </div>

      </div>
    );
  }

  onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    if (!this.state.inputs["title"]) {
      this.props.flash('Please enter a title for the survey');
      // return;
    }

    if (this.props.displayRulesOption === 'template' && !this.state.inputs.displayRulesTemplate) {
      this.props.flash('Please select a template or choose one of the other options');
      return;
    }

    if (this.props.useSlidesTemplate && !this.state.inputs.slidesTemplate) {
      this.props.flash('Please select a template or choose "Build slides manually"');
      return;
    }

    if (this.props.displayRulesOption === 'every-page') {
      this.state.inputs.pageRules = [];
    }

    if (this.props.displayRulesOption === 'link-only') {
      this.state.inputs.apiOnly = true;
    }

    if (!this.props.useSlidesTemplate) {
      this.state.inputs.slidesTemplate = null;
    }

    if (this.props.displayRulesOption !== 'template') {
      this.state.inputs.displayRulesTemplate = null;
    }

    super.onSubmit(e);
  }

  renderActions() {
    return (
      <div className={`actions card`} style={{ marginTop: 25 }}>
        <AuthSubmitButton className="green" title="Create Survey" />

        <div className="login">
          <div>Don't understand surveys?</div>
          <a href="https://docs.zigpoll.com/polls" target="_blank" rel="noopener noreferrer">Learn more</a>
        </div>
      </div>
    )
  }

  render() {
    return (
      <form onSubmit={this.onSubmit.bind(this)} className={`${this.state.valid === false ? 'invalid' : ''} ${this.state.loading || this.props.loading ? 'loading' : ''} ${this.props.className}`}>
        <div className={``}>
          { this.renderInputs() }
          { this.renderActions() }
        </div>
      </form>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const accountId = decode(ownProps.match.params.accountId);
  const pollId = decode(ownProps.match.params.pollId);

  const accounts = state.accounts || [];
  const account = accounts[accountId];

  return {
    loading: state.polls.loading,
    account,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...PollActions, ...FlashNotificationActions }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PollCreateForm));

