import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import _ from 'lodash';

import Tooltip from '../components/Tooltip';
import Diff from '../components/Diff';
import DateRangeToggle from '../components/DateRangeToggle';
import $ from 'jquery';

import * as FlashNotificationActions from '../actions/FlashNotifications';
import * as SlideActions from '../actions/Slides';

import { encode, renderNumber, renderCurrency, numberWithCommas, previewSlides, getSlideLabel, getSlideIcon, stripTags, truncate, getDateAsTimestamp, prepareInsights, getSlideTitle, sortChartDataByAnswers, capitalize, csvEscape, getTimestamp} from '../utils';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official'

class NPSChart extends Component {
  constructor(props) {
    super(props);

    let defaultTooltipContent;
    let tooltipContent = defaultTooltipContent = function () {
      return `<div><b>Responses</b>: ${numberWithCommas(this.point.votes)} <span>${parseFloat(this.point.percentage).toFixed(1)}%</span></div>`
    }
    if (props.tooltipContent) {
      tooltipContent = props.tooltipContent;
    }
    let labelFormatter = function () { return `<strong>${this.point.name}</strong>: ${numberWithCommas(this.point.votes)} Votes (${parseFloat(this.point.percentage).toFixed(1)}%)` };
    if (props.labelFormatter) {
      labelFormatter = props.labelFormatter;
    }

    this.state = {};

    const series = this.prepareSeries();
    const options = {
      title: {},
      chart: {
        type: 'pie',
        styledMode: true,
        height: this.props.condensed ? 200 : 250,
      },
      tooltip: {
        // enabled: false,
        // pointFormat: `
        //   <div>
        //     <div>Votes: <strong>{point.votes:.0f}</strong></div>
        //     <div>Percent: <strong>{point.y:.0f}%</strong></div>
        //   </div>`
        useHTML: true,
        borderRadius: 10,
        formatter: function (val) {
          if (this.series.userOptions.className === 'nps-groupings') {
            return `<div class="tooltip-wrapper">
              <div class="tooltip-header-large">${this.point.name}</div>
              <div class="tooltip-content">
                ${defaultTooltipContent.call(this)}
              </div>
            </div>`;
          }
          return `<div class="tooltip-wrapper">
            <div class="tooltip-header-large">${this.point.name}</div>
            <div class="tooltip-content">
              ${tooltipContent.call(this)}
            </div>
          </div>`;
        }
      },
      legend: { enabled:false },
      plotOptions: {
        series: { 
          animation: false,
        },
      },
      yAxis: {
        min: 0,
        title: 'Revenue',
        labels: {
          formatter: function () {
            return renderNumber(this.value)
          }
        }
      },
      xAxis: {
        categories: props.categories,
        crosshair: true
      },
      series
    };

    this.state.options = options;
  }

  prepareGroupings(data) {
    data = data || this.props.data;
    const len = data.length;
    const groups = [{
        name: 'Detractors',
        y: 0,
      }, {
        name: 'Passives',
        y: 0,
      }, {
        name: 'Promoters',
        y: 0,
      }
    ];

    let total = 0;
    let promotersTotal = 0;
    let detractorsTotal = 0;

    let breakpoints = { detractor: 0.7, promoter: 0.9 };

    if (len <= 5) {
      breakpoints = { detractor: 0.5, promoter: 0.8 };
    }

    let groupIdx = 0;
    let prevGroup = '';
    data.forEach((val, idx) => {
      let className = '';
      const perc = (idx+1)/len;

      total += val.votes || 0;

      if (perc < breakpoints.detractor) {
        if (prevGroup !== 'detractor') {
          groupIdx = 0;
          prevGroup = 'detractor';
        }
        className = 'detractor idx-'+groupIdx++;

        groups[0].y += val.votes || 0;
        detractorsTotal += val.votes || 0;
      } else if (perc < breakpoints.promoter) {
        if (prevGroup !== 'passive') {
          groupIdx = 0;
          prevGroup = 'passive';
        }
        className = 'passive idx-'+groupIdx++;

        groups[1].y += val.votes || 0;
      } else {
        if (prevGroup !== 'promoter') {
          groupIdx = 0;
          prevGroup = 'promoter';
        }
        className = 'promoter idx-'+groupIdx++;

        groups[2].y += val.votes || 0;      
        promotersTotal += val.votes || 0;
      }

      val.className = className;
    });

    data = _.remove(data, ({ votes }) => !votes);

    const npsScore = ((promotersTotal / total) - (detractorsTotal / total)) * 100;

    return { groups, npsScore };
  }

  prepareSeries() {
    const { groups, npsScore } = this.prepareGroupings();
    this.state.npsScore = npsScore;

    const series = [{
        colorByPoint: true,
        data: this.props.data,
        startAngle: -90,
        endAngle: 90,
        dataLabels: {
          distance: 10
        },
        className: 'nps-results',
        center: ['50%', '100%'],
        size: '197%',
        innerSize: '70%',
      }, {
        colorByPoint: true,
        className: 'nps-groupings',
        data: groups,
        dataLabels: {
          enabled: false
          // distance: -45,
        },
        startAngle: -90,
        endAngle: 90,
        center: ['50%', '100%'],
        size: '130%',
        innerSize: '85%',
      }]
    return series;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!_.isEqual(nextProps.data, this.props.data)) {
      const { groups, npsScore } = this.prepareGroupings(nextProps.data)
      const state = { ...this.state };
      const series = [ ...state.options.series ];
      series[0].data = nextProps.data;
      series[1].data = groups;
      state.series = series;
      state.npsScore = npsScore;

      this.setState(state, () => this.forceUpdate());
      return true;
    }
    return false;
  }
 
  render() {
    if (this.props.condensed) { return null; }

    return <div id="poll-results-chart" className="nps-results-chart">
      {/*<div className="card-subtitle center">Survey Results</div>*/}

      <div className="highcharts-wrapper">
        <div className="nps-score"><span>NPS</span>{ (this.state.npsScore === undefined) ? '--' : Math.round(this.state.npsScore) }</div>
        <HighchartsReact
          highcharts={Highcharts}
          options={this.state.options}
          updateArgs={[true, false, false]}
        />
      </div>
    </div>
  }
}

class DonutChart extends Component {
  constructor(props) {
    super(props);

    let defaultTooltipContent;
    let tooltipContent = defaultTooltipContent = function () {
      return `<div><b>Responses</b>: ${numberWithCommas(this.point.votes)} <span>${parseFloat(this.point.percentage).toFixed(1)}%</span></div>`
    }
    if (props.tooltipContent) {
      tooltipContent = props.tooltipContent;
    }
    let labelFormatter = function () { return `<strong>${this.point.name}</strong>: ${numberWithCommas(this.point.votes)} Votes (${parseFloat(this.point.percentage).toFixed(1)}%)` };
    if (props.labelFormatter) {
      labelFormatter = props.labelFormatter;
    }

    this.state = {};

    const series = this.prepareSeries();
    const options = {
      title: {},
      chart: {
        type: 'pie',
        styledMode: true,
        height: this.props.condensed ? 200 : 250,
      },
      tooltip: {
        useHTML: true,
        borderRadius: 10,
        formatter: function (val) {
          return `<div class="tooltip-wrapper">
            <div class="tooltip-header-large">${this.point.name}</div>
            <div class="tooltip-content">
              ${tooltipContent.call(this)}
            </div>
          </div>`;
        }
      },
      legend: { enabled: false },
      plotOptions: {
        series: { 
          animation: false,
        },
      },
      yAxis: {
        min: 0,
        title: 'Revenue',
        labels: {
          formatter: function () {
            return renderNumber(this.value)
          }
        }
      },
      xAxis: {
        categories: props.categories,
        crosshair: true
      },
      series
    };

    this.state.options = options;
  }

  prepareSeries() {
    const series = [{
      colorByPoint: true,
      data: this.props.data,
      startAngle: -90,
      endAngle: 90,
      dataLabels: {
        distance: 10
      },
      className: 'nps-results',
      center: ['50%', '100%'],
      size: '197%',
      innerSize: '70%',
    }];

    return series;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!_.isEqual(nextProps.data, this.props.data)) {
      const state = { ...this.state };
      const series = [ ...state.options.series ];
      series[0].data = nextProps.data;
      state.series = series;

      this.setState(state, () => this.forceUpdate());
      return true;
    }
    return false;
  }
 
  render() {
    if (this.props.condensed) { return null; }

    return <div id="poll-results-chart" className="nps-results-chart">
      <div className="highcharts-wrapper">
        <HighchartsReact
          highcharts={Highcharts}
          options={this.state.options}
          updateArgs={[true, false, false]}
        />
      </div>
    </div>
  }
}

class ColumnChart extends Component {
  constructor(props) {
    super(props);

    let tooltipContent = function () {
      return `<div><b>Responses</b>: ${numberWithCommas(this.point.votes)} <span>${parseFloat(this.point.votePercentage).toFixed(1)}%</span></div>`
    }
    if (props.tooltipContent) {
      tooltipContent = props.tooltipContent;
    }
    let labelFormatter = function () { return `<strong>${this.point.name}</strong>: ${numberWithCommas(this.point.votes)} Votes (${parseFloat(this.point.votePercentage).toFixed(1)}%)` };
    if (props.labelFormatter) {
      labelFormatter = props.labelFormatter;
    }

    const options = {
      title: {},
      chart: {
        type: 'column',
        styledMode: true,
        height: this.props.condensed ? 200 : 250,
      },
      tooltip: {
        // enabled: false,
        // pointFormat: `
        //   <div>
        //     <div>Votes: <strong>{point.votes:.0f}</strong></div>
        //     <div>Percent: <strong>{point.y:.0f}%</strong></div>
        //   </div>`
        useHTML: true,
        borderRadius: 10,
        formatter: function (val) {
          return `<div class="tooltip-wrapper">
            <div class="tooltip-header-large">${this.point.name}</div>
            <div class="tooltip-content">
              ${tooltipContent.call(this)}
            </div>
          </div>`;
        }
      },
      legend: { enabled:false },
      plotOptions: {
        series: { animation: false },
        column: {
            pointPadding: 0.1,
            borderWidth: 0,
        }
      },
      yAxis: {
        min: 0,
        title: 'Revenue',
        labels: {
            formatter: function () {
              return renderNumber(this.value)
            }
        }
      },
      xAxis: {
        categories: props.categories,
        crosshair: true
      },
      series: [{
        colorByPoint: true,
        data: this.props.data,
        type: 'column',
        // borderRadius: '10%',
        // states: {
          // hover: {
            // enabled: false
          // }
        // }
      }]
    };

    this.state = { options };
  }

  shouldComponentUpdate(nextProps) {
    if (!_.isEqual(nextProps.data, this.props.data)) {
      const state = { ...this.state };
      const series = [ ...state.options.series ];
      series[0].data = nextProps.data;
      state.series = series;
      this.setState(state);
      return true;
    }
    return false;
  }
 
  render() {
    if (this.props.condensed) { return null; }

    return <div id="poll-results-chart">
      {/*<div className="card-subtitle center">Survey Results</div>*/}

      <div className="highcharts-wrapper">
        <HighchartsReact
          highcharts={Highcharts}
          options={this.state.options}
          updateArgs={[true, false, false]}
        />
      </div>
    </div>
  }
}

class SlideResultsChart extends Component {
  constructor(props) {
    super(props);

    let tooltipContent = function () {
      return `<div><b>Responses</b>: ${numberWithCommas(this.point.votes)} <span>${parseFloat(this.point.percentage).toFixed(1)}%</span></div>`
    }
    if (props.tooltipContent) {
      tooltipContent = props.tooltipContent;
    }
    let labelFormatter = function () { return `<strong>${this.point.name}</strong>: ${numberWithCommas(this.point.votes)} Votes (${parseFloat(this.point.percentage).toFixed(1)}%)` };

    if (props.labelFormatter) {
      labelFormatter = props.labelFormatter;
    }

    const options = {
      title: {},
      chart: {
        type: 'pie',
        styledMode: true,
        height: this.props.condensed ? 200 : 250,
      },
      tooltip: {
        // enabled: false,
        // pointFormat: `
        //   <div>
        //     <div>Votes: <strong>{point.votes:.0f}</strong></div>
        //     <div>Percent: <strong>{point.y:.0f}%</strong></div>
        //   </div>`
        useHTML: true,
        borderRadius: 10,
        formatter: function (val) {
          return `<div class="tooltip-wrapper">
            <div class="tooltip-header-large">${this.point.name}</div>
            <div class="tooltip-content">
              ${tooltipContent.call(this)}
            </div>
          </div>`;
        }
      },
      plotOptions: {
        series: { animation: false },
        pie: {
          allowPointSelect: false,
          cursor: 'pointer',
          dataLabels: {
              enabled: true,
              formatter: labelFormatter
          }
        }
      },
      series: [{
        name: 'Votes',
        colorByPoint: true,
        data: this.props.data,
        // states: {
          // hover: {
            // enabled: false
          // }
        // }
      }]
    };

    this.state = { options };
  }

  shouldComponentUpdate(nextProps) {
    if (!_.isEqual(nextProps.data, this.props.data)) {
      const state = { ...this.state };
      const series = [ ...state.options.series ];
      series[0].data = nextProps.data;
      state.series = series;
      this.setState(state);
      return true;
    }
    return false;
  }
 
  render() {
    if (this.props.condensed) { return null; }

    return <div id="poll-results-chart">
      {/*<div className="card-subtitle center">Survey Results</div>*/}

      <div className="highcharts-wrapper">
        <HighchartsReact
          highcharts={Highcharts}
          options={this.state.options}
          updateArgs={[true, false, false]}
        />
      </div>
    </div>
  }
}

class SortByInput extends Component {
  render() {
    return (<div className="sort-by-input">
      <div className="selected"><i className="fas fa-sort"/>Sort By: <div className="select-container"><select
        onChange={(e) => {
          e.preventDefault();
          e.stopPropagation();
          this.props.onChange(e.target.value);
        }}
        value={this.props.value}>  
        <option value="results">Results</option>
        <option value="default">Default</option>
        <option value="reverse">Reverse</option>
      </select></div></div>
    </div>);
  }
}

class RankingChart extends Component {
  constructor(props) {
    super(props);

    let tooltipContent = function () {
      let content = ``;

      let weightedAvg = 0;
      let total = 0;

      this.points.forEach((point, index) => {
        if (point.y == .25) { return; }

        content += `<div><b>Ranked #${index+1}</b>: ${numberWithCommas(point.y)}</span></div>`;
        weightedAvg += point.y * (this.points.length - index);
        total += point.y;
      });

      /* This is weighted avg for the point only, not total of all answer options */
      // content += `<div><b>Weighted Avg:</b> ${renderNumber(weightedAvg/total)}</div>`;

      return content;
    }
    if (props.tooltipContent) {
      tooltipContent = props.tooltipContent;
    }

    const options = {
      title: {},
      chart: {
        type: 'column',
        styledMode: true,
        height: this.props.condensed ? 200 : 250,
      },
      tooltip: {
        useHTML: true,
        shared: true,
        borderRadius: 10,
        formatter: function (val) {
          return `<div class="tooltip-wrapper">
            <div class="tooltip-header-large">${truncate(this.x, 30)}</div>
            <div class="tooltip-content">
              ${tooltipContent.call(this)}
            </div>
          </div>`;
        }
      },
      legend: { enabled:false },
      plotOptions: {
        series: { animation: false },
        column: {
            pointPadding: 0.1,
            borderWidth: 0,
        }
      },
      yAxis: {
        min: 0,
        minTickInterval: 1,
        title: 'Total',
        labels: {
          formatter: function () {
            return renderNumber(this.value)
          }
        }
      },
      xAxis: {
        categories: props.categories,
        crosshair: true
      },
      series: this.props.series
    };

    this.state = { options };
  }

  shouldComponentUpdate(nextProps) {
    if (!_.isEqual(nextProps.series, this.props.series)) {
      const state = { ...this.state };
      const series = nextProps.series;
      state.options.series = series;
      state.options.xAxis.categories = nextProps.categories;
      this.setState(state);
      return true;
    }
    return false;
  }
 
  render() {
    if (this.props.condensed) { return null; }

    return <div id="poll-results-chart">
      {/*<div className="card-subtitle center">Survey Results</div>*/}

      <div className="highcharts-wrapper ranking-chart">
        <HighchartsReact
          highcharts={Highcharts}
          options={this.state.options}
          updateArgs={[true, false, false]}
        />
      </div>
    </div>
  }
}

class ChartGroup extends Component {
  constructor(props) {
    super(props);
    this.state = { idx: props.startIdx || 0 }
  }
  render() {
    const slide = this.props.slide;
    const charts = [];

    let sortedData = this.props.data;
    if (this.props.sortBy === 'results') {
      sortedData = _.orderBy(this.props.data, ({ votes }) => votes || 0, 'desc');
    } else if (this.props.sortBy === 'reverse') {
      sortedData = this.props.data.slice().reverse();
    }
    const chartData = _.map(this.props.data, (d) => {
      d.y = d.votes;
      return d;
    });

    charts.push(<SlideResultsChart
      data={sortedData}
      condensed={this.props.condensed}
      tooltipContent={this.props.hasOrderValue && 
        function () { 
          return `<div class="multiple-rows">
          <div><b>Responses</b>: ${numberWithCommas(this.point.votes)} <span>${parseFloat(this.point.percentage).toFixed(1)}%</span></div>        
          <div><b>Revenue</b>: ${renderCurrency(this.point.tov)} <span>${parseFloat(this.point.tovPercentage || 0).toFixed(1)}%</span></div>
          <div><b>Average Order Value</b>: ${renderCurrency(this.point.aov)}</div>
        </div>`;
        }
      }
    />);

    charts.push(<DonutChart
      data={sortedData}
      condensed={this.props.condensed}
      tooltipContent={this.props.hasOrderValue && 
        function () { 
          return `<div class="multiple-rows">
          <div><b>Responses</b>: ${numberWithCommas(this.point.votes)} <span>${parseFloat(this.point.percentage).toFixed(1)}%</span></div>        
          <div><b>Revenue</b>: ${renderCurrency(this.point.tov)} <span>${parseFloat(this.point.tovPercentage || 0).toFixed(1)}%</span></div>
          <div><b>Average Order Value</b>: ${renderCurrency(this.point.aov)}</div>
        </div>`;
        }
      }
    />);

    charts.push(
      <ColumnChart
        condensed={this.props.condensed}
        data={sortedData}
        categories={sortedData.map(({ name }) => name)}
        tooltipContent={this.props.hasOrderValue && function () {
          return `<div class="multiple-rows">
          <div><b>Average Order Value</b>: ${renderCurrency(this.point.aov)}</div>
          <div><b>Revenue</b>: ${renderCurrency(this.point.tov)} <span>${parseFloat(this.point.tovPercentage || 0).toFixed(1)}%</span></div>
          <div><b>Responses</b>: ${numberWithCommas(this.point.votes)} <span>${parseFloat(this.point.votePercentage).toFixed(1)}%</span></div>        
        </div>`;
        }}
        labelFormatter={function () {
          return `<strong>${this.point.name}</strong>: ${renderCurrency(this.point.tov)} (${parseFloat(this.point.tovPercentage).toFixed(1)}%)`;
        }}
      />
    );
    let chartTypes = [
      {
        title: 'Pie Chart',
        icon: 'fa-chart-pie'
      }, 
      {
        title: 'Donut Chart',
        icon: 'fa-circle-half-stroke'
      },
      {
        title: 'Column Chart',
        icon: 'fa-chart-column'
      }
    ];

    if (slide.type === 'range' || slide.type === 'star-rating' || slide.type === 'satisfaction') {
      chartTypes.push({
        title: 'NPS Chart',
        icon: 'fa-masks-theater'
      })

      charts.push(
        <NPSChart
          data={sortChartDataByAnswers(this.props.data, slide.answers)}
          condensed={this.props.condensed}
          tooltipContent={this.props.hasOrderValue &&
            function () { 
              return `<div class="multiple-rows">
              <div><b>Responses</b>: ${numberWithCommas(this.point.votes)} <span>${parseFloat(this.point.percentage).toFixed(1)}%</span></div>        
              <div><b>Revenue</b>: ${renderCurrency(this.point.tov)} <span>${parseFloat(this.point.tovPercentage || 0).toFixed(1)}%</span></div>
              <div><b>Average Order Value</b>: ${renderCurrency(this.point.aov)}</div>
            </div>`;
            }
          }
        />
      );
    }

    let chartSelector = null;
    if (charts.length > 1) {
      chartSelector = <div className="chart-selector">
        { chartTypes.map((chart, idx) => 
          <div title={chart.title} className={`${this.state.idx === idx ? 'active' : ''}`} onClick={() => this.setState({ idx })}><div className="content"><div>{ chart.title }</div></div><i className={`fas ${chart.icon}`} /></div>
        )}
      </div>
    }

    if (this.props.condensed) {
      return null;
    }

    return <div className="chart-group-container">
      { chartSelector }
      { charts[this.state.idx] }
    </div>
  }
}

class SlideAnswers extends Component {
  constructor(props) {
    super(props);
    this.state = { idx: 0, inactive: true, sortBy: window.localStorage.getItem('zigpoll-answers-sortby') || 'results', resultsIdx: 0 }
  }

  next() {
    this.setState({ idx: ++this.state.idx })
  }

  prev() {
    this.setState({ idx: --this.state.idx })
  }

  componentDidMount() {
    const height = $('.slide-answers-component').height();
    if (height >= 430 && !this.state.hidden) {
      this.setState({ hidden: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.slide._id !== this.props.slide._id) {
      this.setState({ idx: 0, hidden: undefined });
    }
    const height = $('.slide-answers-component').height();
    if (height >= 430 && !this.state.hidden) {
      this.setState({ hidden: true });
    }
  }

  renderRankView() {
    const slide = this.props.slide;
    let answers = slide.answers;
    let hasRanking = false;

    answers.forEach((answer) => {
      if (answer.rankings) {
        hasRanking = true;
      }
    });

    if (!hasRanking) {
      if (this.state.hasUsedDateRange) {
        return (<div className="section slide-answers-component">
          <DateRangeToggle
            slide={this.props.slide}
            pollId={this.props.pollId}
            fetchSlide={this.props.fetchFullSlide}
            onSubmit={(dateRange) => {
              this.props.fetchVotes(this.props.accountId, this.props.pollId, this.props.slide._id, dateRange);
              this.setState({ hasUsedDateRange: true })
            }}
          />
          <div className="empty-object-list">
            <h3>No Data Found For This Range.</h3>
            <p>Please select another date range and try again.</p>
          </div>
        </div>);        
      }
      

      return (<div className="section slide-answers-component">
        <div className="empty-object-list">
          <h3>No Rankings have been submitted.</h3>
          <p>Check back later to see what how people ranked your answers.</p>
        </div>
      </div>);
    }

    let minRank = 0;
    let maxRank = -1;
    let maxTotal = -1;
    let valueTotal = 0;
  
    answers.forEach((answer) => {
      const rankings = answer.rankings || {};
      answer.rankings = rankings;
      let weightedAvg = 0;
      let total = 0;

      Object.keys(rankings).forEach((key) => {
        total += rankings[key];
        valueTotal += rankings[key] * ((answers.length + 1) - key);
      });

      if (total > maxTotal) {
        maxTotal = total;
      }
    });

    let sortedByRankingAnswers = _.orderBy(answers, (answer) => {
      const rankings = answer.rankings || {};
      answer.rankings = rankings;
      let weightedAvg = 0;
      let total = 0;

      Object.keys(rankings).forEach((key) => {
        weightedAvg += rankings[key] * ((answers.length + 1) - key);
        total += rankings[key];

        if (+key > maxRank) {
          maxRank = key;
        }
      });

      answer.realWeightedAvg = (weightedAvg/total);

      if (total === 0) {
        answer.weightedAvg = 0;
        return 0;
      }
      answer.weightedAvg = (weightedAvg / valueTotal) * 100;
      return answer.weightedAvg;
    }, 'desc');

    if (this.state.sortBy === 'default') {
      sortedByRankingAnswers = _.orderBy(sortedByRankingAnswers, ({ handle }) => _.findIndex(answers, (answer) => answer.handle === handle), 'asc');
    } else if (this.state.sortBy === 'reverse') {
      sortedByRankingAnswers = sortedByRankingAnswers.slice().reverse();
    }

    const series = [];
    if (maxRank === -1) {
      sortedByRankingAnswers.forEach((answer, i) => {      
        series.push({
          name: i,
          data: _.map(sortedByRankingAnswers, ({ rankings }) => rankings[i+1] || .25)
        });
      })
    } else {
      for (let i = minRank; i < maxRank; i++) {
        series.push({
          name: i,
          data: _.map(sortedByRankingAnswers, ({ rankings }) => rankings[i+1] || .25)
        });
      }
    }

    let maxSubmissionCount = -1;

    return (<div className="section slide-answers-component">
       { <DateRangeToggle
        slide={this.props.slide}
        pollId={this.props.pollId}
        fetchSlide={this.props.fetchFullSlide}
        onSubmit={(dateRange) => {
          this.props.fetchVotes(this.props.accountId, this.props.pollId, this.props.slide._id, dateRange);
          this.setState({ hasUsedDateRange: true })
        }}
      />}

      {/*{ nextPrev }*/}

      <SortByInput
        value={this.state.sortBy}
        onChange={(value) => {
          window.localStorage.setItem('zigpoll-answers-sortby', value);
          this.setState({ sortBy: value });
        }}
      />

      <RankingChart
        key="ranking"
        condensed={this.props.condensed}
        series={series}
        categories={sortedByRankingAnswers.map(({ title }) => title)}
      />

      <div className="summary-results-tabs">
        <div className="next-prev" style={{ display: this.props.condensed ? 'none' : '' }}>
          <div className="prev" onClick={() => {
            if (this.state.resultsIdx === 0) {
              this.setState({ resultsIdx: 2 })
            } else {
              this.setState({ resultsIdx: --this.state.resultsIdx })
            }
          }}><i className="fas fa-chevron-left" /></div>
          <div className="next" onClick={() => {
            if (this.state.resultsIdx === 2) {
              this.setState({ resultsIdx: 0 })
            } else {
              this.setState({ resultsIdx: ++this.state.resultsIdx })
            }
          }}><i className="fas fa-chevron-right" /></div>
        </div>
        <div className={`${Math.abs(this.state.resultsIdx) === 0 ? 'active' : ''}`}>
          <p className="stats">Weighted Scores</p>

          <div className='results active'>
          {sortedByRankingAnswers.map((answer, idx) => {
            const { title, handle, votes, weightedAvg, rankings } = answer;

            let submissionCount = 0;
            Object.keys(rankings).forEach((key) => {
              submissionCount += rankings[key];
            });
            answer.submissionCount = submissionCount;
            if (submissionCount > maxSubmissionCount) {
              maxSubmissionCount = submissionCount;
            }

            const maxWeightedAvg = sortedByRankingAnswers[0].weightedAvg;
            const selected = (weightedAvg === maxWeightedAvg);

            let perc = (weightedAvg / maxWeightedAvg) * 100 + '%';

            return (
              <div key={handle} className={`result ${selected ? 'selected' : ''}`}>
                <div className="fill" style={{ width: perc }}></div>
                <div className="perc wide" title={weightedAvg ? parseFloat(weightedAvg).toFixed(2) : 0}>{renderNumber(weightedAvg)}</div>
                <div className="title small">{getSlideTitle(slide, handle, title)}</div>

                <div className="percentage">{renderNumber((weightedAvg / maxWeightedAvg) * 100)}%</div>
              </div>
            );
          })}
          </div>
        </div>

        <div className={`${Math.abs(this.state.resultsIdx) === 1 ? 'active' : ''}`}>
          <p className="stats">Submission Totals</p>

          <div className='results active'>
          {sortedByRankingAnswers.map(({ title, handle, votes, submissionCount }, idx) => {

            let perc = (submissionCount / maxSubmissionCount) * 100 + '%';

            return (
              <div key={handle} className={`result`}>
                <div className="fill" style={{ width: perc }}></div>
                <div className="perc wide" title={submissionCount ? parseFloat(submissionCount).toFixed(2) : 0}>{renderNumber(submissionCount)}</div>
                <div className="title small">{getSlideTitle(slide, handle, title)}</div>

                <div className="percentage">{renderNumber((submissionCount / maxSubmissionCount) * 100)}%</div>
              </div>
            );
          })}
          </div>
        </div>

        <div className={`${Math.abs(this.state.resultsIdx) === 2 ? 'active' : ''}`}>
          <p className="stats">Weighted Average</p>

          <div className='results active'>
          {sortedByRankingAnswers.map((answer, idx) => {
            const { title, handle, votes, realWeightedAvg } = answer;

            let perc = (realWeightedAvg/answers.length) * 100 + '%';
            const color = '#9fd0ec';

            return (
              <div key={handle} className={`result`}>
                <div className="fill" style={{ width: perc, background: color }}></div>
                <div className="perc wide" title={realWeightedAvg ? parseFloat(realWeightedAvg).toFixed(2) : 0}>{renderNumber(realWeightedAvg)}</div>
                <div className="title small">{getSlideTitle(slide, handle, title)}</div>

                <div className="percentage">{renderNumber((realWeightedAvg / answers.length) * 100)}%</div>
              </div>
            );
          })}
          </div>
        </div>
      </div>
    </div>)
  }

  render() {
    const slide = this.props.slide;
    if (!slide || !slide.type) { return null; }

    const diff = this.props.diffs[slide._id] || {};
    const answersDiff = diff.answersDiff || {};

    if (!previewSlides[slide.type].answers) { return null; }
    if (slide.type === 'legal-consent') { return null; }
    if (slide.type === 'rank') { return this.renderRankView(); }

    let answers = slide.answers;
    if (answers.length > 15) {
      answers = answers.filter(({ votes }) => votes > 0);
    }

    const total = _.sumBy(answers, a => a.votes || 0);
    const maxAnswer = _.maxBy(answers, a => a.votes || 0);
    let percBase = total;
    if (this.state.usePenetrationRate) {
      percBase = slide.engagementCount;
    }

    if (total === 0) {
      if (this.state.hasUsedDateRange) {
        return (<div className="section slide-answers-component">
          <DateRangeToggle
            slide={this.props.slide}
            pollId={this.props.pollId}
            fetchSlide={this.props.fetchFullSlide}
            onSubmit={(dateRange) => {
              this.props.fetchVotes(this.props.accountId, this.props.pollId, this.props.slide._id, dateRange);
              this.setState({ hasUsedDateRange: true })
            }}
          />
          <div className="empty-object-list">
            <h3>No Data Found For This Range.</h3>
            <p>Please select another date range and try again.</p>
          </div>
        </div>);        
      }

      // if (this.props.condensed) { return null; }

      return (<div className="section slide-answers-component">
        <div className="empty-object-list">
          <h3>No Votes have been cast yet.</h3>
          <p>Check back later to see what answers people voted for</p>
        </div>
      </div>);
    }

    let viewMore = null;
    if (!this.props.hideLinks && this.props.responsesUrl) {
      viewMore = (<Link to={this.props.responsesUrl} className="view-more">See who responded</Link>);
    }

    let hasTotalOrderValue = false;
    let tovTab = null;
    let aovTab = null;

    answers.forEach(({ totalOrderValue, votes, handle }) => {
      if (totalOrderValue) {
        hasTotalOrderValue = true;
      }

      /* Prepare for chart tooltips */
      let answer = _.find(answers, ['handle', handle]);
      answer.votePercentage = (votes/percBase) * 100;
    });

    let sortedByVotesAnswers = answers;
    if (this.state.sortBy === 'results') {
      sortedByVotesAnswers = _.orderBy(answers, ({ votes }) => votes || 0, 'desc');
    } else if (this.state.sortBy === 'reverse') {
      sortedByVotesAnswers = answers.slice().reverse();
    }

    let sortedByTotalOrderValueAnswers = [];
    let sortedByAOVAnswers = [];
    if (hasTotalOrderValue) {
      const sumOrderValue = _.sumBy(answers, a => a.totalOrderValue || 0);
      const maxOrderValueAnswer = _.maxBy(answers, a => a.totalOrderValue || 0);
      const maxAovAnswer = _.maxBy(answers, ({ totalOrderValue, votesSinceOrderValueAdded}) => {
        let aov = totalOrderValue / votesSinceOrderValueAdded;
        if (_.isNaN(aov)) {
          aov = 0;
        }
        return aov;
      });
      let maxAov = maxAovAnswer.totalOrderValue / maxAovAnswer.votesSinceOrderValueAdded;
      if (_.isNaN(maxAov)) {
        maxAov = 0;
      }

      sortedByTotalOrderValueAnswers = answers;
      sortedByAOVAnswers = answers;

      if (this.state.sortBy === 'results') {
        sortedByTotalOrderValueAnswers = _.orderBy(answers, ({totalOrderValue}) => { return totalOrderValue || 0 }, 'desc');
        sortedByAOVAnswers = _.orderBy(answers, ({ totalOrderValue, votesSinceOrderValueAdded }) => {
          let aov = totalOrderValue / votesSinceOrderValueAdded;
          if (_.isNaN(aov)) {
            aov = 0;
          }
          return aov;
        }, 'desc');
      } else if (this.state.sortBy === 'reverse') {
        sortedByTotalOrderValueAnswers = answers.slice().reverse();
        sortedByAOVAnswers = answers.slice().reverse();
      }

      tovTab = <div>
        <p className="stats">Revenue<span className="download-summary" onClick={() => {
          const rows = sortedByTotalOrderValueAnswers.map(({ title, handle, totalOrderValue }) => {
            let perc = ((totalOrderValue || 0)/sumOrderValue) * 100;
            return [ stripTags(slide.title), csvEscape(title), totalOrderValue.toFixed(2), perc.toFixed(2) + '%' ];
          });

          rows.unshift([ 'Question', 'Answer', 'Revenue', 'Percentage' ]);

          const csvContent = "data:text/csv;charset=utf-8," 
              + rows.map(e => e.join(",")).join("\n");

          const encodedUri = encodeURI(csvContent);
          const link = document.createElement("a");

          link.setAttribute("href", encodedUri);
          link.setAttribute("download", `${stripTags(slide.title)}-revenue-summary.csv`);
          document.body.appendChild(link); // Required for FF

          link.click(); // This will download the data file named "my_data.csv".
        }}><i className="fas fa-download"/><div className="tooltip-content">Download Summary</div></span></p>

        <div className='results active'>
        {sortedByTotalOrderValueAnswers.map(({ title, handle, votes, votesSinceOrderValueAdded, totalOrderValue }, idx) => {

          let perc = ((totalOrderValue || 0)/sumOrderValue) * 100 + '%';
          const selected = maxOrderValueAnswer.handle === handle;

          let color = '#e4e0e0';
          if (selected && votes) {
            color = 'rgba(6, 147, 227, 0.5)';
          }

          let answer = _.find(answers, ['handle', handle]);
          answer.tov = totalOrderValue;
          answer.tovPercentage = (totalOrderValue/sumOrderValue) * 100;

          return (
            <div key={handle} className={`result ${selected ? 'selected' : ''}`}>
              <div className="fill" style={{ width: perc }}></div>
              <div className="perc wide" title={totalOrderValue ? parseFloat(totalOrderValue).toFixed(2) : 0}>{renderCurrency(totalOrderValue || 0, undefined, this.props.user.currency, `0,0.0[a]`)}</div>
              <div className="title small">{getSlideTitle(slide, handle, title)}</div>
              <div className="percentage">{renderNumber((totalOrderValue/sumOrderValue) * 100)}%</div>
            </div>
          );
        })}
        </div>
      </div>

      aovTab = <div>
        <p className="stats">Average Order Value<span className="download-summary" onClick={() => {
          const rows = sortedByAOVAnswers.map(({ title, handle, totalOrderValue, votesSinceOrderValueAdded }) => {
            let aov = totalOrderValue / votesSinceOrderValueAdded;

            if (_.isNaN(aov)) {
              aov = 0;
            }
            
            let perc = (aov/maxAov) * 100;
            if (aov === 0) {
              perc = 0;
            }

            return [ stripTags(slide.title), csvEscape(title), aov.toFixed(2), perc.toFixed(2) + '%' ];
          });

          rows.unshift([ 'Question', 'Answer', 'AOV', 'Percentage' ]);

          const csvContent = "data:text/csv;charset=utf-8," 
              + rows.map(e => e.join(",")).join("\n");

          const encodedUri = encodeURI(csvContent);
          const link = document.createElement("a");

          link.setAttribute("href", encodedUri);
          link.setAttribute("download", `${stripTags(slide.title)}-aov-summary.csv`);
          document.body.appendChild(link); // Required for FF

          link.click(); // This will download the data file named "my_data.csv".
        }}><i className="fas fa-download"/><div className="tooltip-content">Download Summary</div></span></p>

        <div className='results active o'>
        {sortedByAOVAnswers.map(({ title, handle, votes, votesSinceOrderValueAdded, totalOrderValue }, idx) => {


          let aov = totalOrderValue / votesSinceOrderValueAdded;

          if (_.isNaN(aov)) {
            aov = 0;
          }
          let perc = (aov/maxAov) * 100 + '%';
          perc = 0;

          const selected = maxAovAnswer.handle === handle;

          let color = '#e4e0e0';
          if (selected && votes) {
            color = 'rgba(6, 147, 227, 0.5)';
          }

          let percentage = <div className="percentage">{renderNumber((aov - maxAov) / ((aov+maxAov)/2) * 100)}%</div>

          if (aov === 0) {
            percentage = null;
          }

          if (aov === maxAov) {
            percentage = <div className="percentage">Best</div>;
          }

          let answer = _.find(answers, ['handle', handle]);
          answer.aov = aov;
          answer.aovPercentage = percentage;

          return (
            <div key={handle} className={`result ${selected ? 'selected' : ''}`}>
              <div className="fill" style={{ width: perc }}></div>
              <div className="perc wide" title={aov ? parseFloat(aov).toFixed(2) : 0}>{renderCurrency(aov, undefined, this.props.user.currency)}</div>
              <div className="title small">{getSlideTitle(slide, handle, title)}</div>

              <div className="percentage">{renderNumber((aov/maxAov) * 100)}%</div>
            </div>
          );
        })}
        </div>
      </div>
    }

    let combinedResponses = slide.combinedAnswers || {};
    let sortedCombinedResponses = [];
    let combinedResponsesTotal = 0;
    Object.keys(combinedResponses).forEach((key) => {
      combinedResponsesTotal += combinedResponses[key];
      sortedCombinedResponses.push({ votes: combinedResponses[key], handle: key });
    });
    sortedCombinedResponses = _.orderBy(sortedCombinedResponses, ['votes']).reverse();
    sortedCombinedResponses = sortedCombinedResponses.map(({ handle }) => handle);

    let combinedResponsesToggle = null;
    let hasPercToggle = slide.type === 'checkbox' || slide.type === 'inline-multiple-choice' || slide.type === 'image-choice';

    /* Any survey after Nov 7th 2024 */
    let hasCombinedResponses = false;
    if (getTimestamp(slide._id).getTime() >= 1731009825891) {
      hasCombinedResponses = true;
    }

    if (hasPercToggle && hasCombinedResponses) {
      combinedResponsesToggle = <span onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.setState({ showCombinedResponses: !this.state.showCombinedResponses }) }}className="download-summary show-combined-responses"><i className={`fas ${!this.state.showCombinedResponses ? 'fa-expand' : 'fa-compress' }`} /><div className="tooltip-content">{ !this.state.showCombinedResponses ? 'Show Combined Responses' : 'Hide Combined Responses' }</div></span>
    }

    const responsesTab = (<div>
      <p className="stats">Responses { combinedResponsesToggle }<span className="download-summary" onClick={(e) => {
       e.preventDefault(); e.stopPropagation();
        const rows = sortedByVotesAnswers.map(({ title, handle, votes }) => {
          let perc = (votes/percBase) * 100;

          if (!votes) {
            perc = 0;
          }

          return [ stripTags(slide.title), csvEscape(title), votes, perc.toFixed(2) + '%' ];
        });

        rows.unshift([ 'Question', 'Answer', 'Votes', 'Percentage' ]);

        const csvContent = "data:text/csv;charset=utf-8," 
            + rows.map(e => e.join(",")).join("\n");

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `${stripTags(slide.title)}-summary.csv`);
        document.body.appendChild(link); // Required for FF

        link.click(); // This will download the data file named "my_data.csv".
      }}><i className="fas fa-download"/><div className="tooltip-content">Download Summary</div></span></p>

      <div className='results active'>
        {sortedByVotesAnswers.map(({ title, handle, votes, votesSinceOrderValueAdded, totalOrderValue }, idx) => {
          let perc = (votes/percBase) * 100 + '%';

          if (!votes) {
            perc = '0%';
          }

          const selected = maxAnswer.handle === handle;

          let number = votes;

          let color = '#e4e0e0';

          // if (selected && votes) {
            // color = 'rgba(6, 147, 227, 1)';
            color = "#2167f5";
          // }

          return (
            <div key={handle} className={`result ${selected ? 'selected' : ''}`}>
              <div className="fill" style={{ width: perc }}></div>
              <div className="perc" title={number || 0}>{renderNumber(number)}</div>
              <div className="title">{getSlideTitle(slide, handle, title)}</div>
              <div className={`percentage ${hasPercToggle && 'has-toggle has-inline-tooltip left'}`} onClick={() => {
                if (hasPercToggle) {
                  this.setState({ usePenetrationRate: !this.state.usePenetrationRate });
                }
              }}>{hasPercToggle && <div className="tooltip-content">{ this.state.usePenetrationRate ? 'Penetration Rate' : 'Response Rate' }</div>}{renderNumber((votes/percBase) * 100)}%</div>
              <Diff value={answersDiff[handle]}/>
            </div>
          );
        })}
      </div>

      {/* Break apart later */}
      { this.state.showCombinedResponses && <div>
        <p className="stats">Combined Responses<span className="download-summary" onClick={() => {
          console.log('Hello');
        }}><i className="fas fa-download"/><div className="tooltip-content">Download Summary</div></span></p>

        <div className='results active'>
          {sortedCombinedResponses.map((key, idx) => {
            const votes = combinedResponses[key];
            let perc = (votes/combinedResponsesTotal) * 100 + '%';

            if (!votes) {
              perc = '0%';
            }

            // const selected = maxAnswer.handle === handle;
            const selected = false;

            let number = votes;

            let color = '#e4e0e0';

            // if (selected && votes) {
              // color = 'rgba(6, 147, 227, 1)';
              color = "#2167f5";
            // }

            let title = [];
            key.split(' ').forEach((handle) => {
              let answer = _.find(slide.answers, (answer) => answer.handle === handle);
              if (answer) {
                title.push(answer.title);
              } else {
                title.push(handle);
              }
            });
            title = title.join(', ');

            return (
              <div key={key} className={`result ${selected ? 'selected' : ''}`}>
                <div className="fill" style={{ width: perc }}></div>
                <div className="perc" title={number || 0}>{renderNumber(number)}</div>
                <div className="title">{title}</div>
                <div className="percentage">{renderNumber((votes/combinedResponsesTotal) * 100)}%</div>
                {/*<Diff value={answersDiff[handle]}/>*/}
              </div>
            );
          })}
        </div>
      </div>}
    </div>);

    const pieData = answers.map((answer, idx) => {
      let { votes, handle, title } = answer;
      let perc = (votes/percBase);

      if (!votes) {
        perc = 0;
      }

      if (this.props.condensed) {
        title = truncate(title, 30);
      }

      return {
        name: title,
        y: perc,
        votes,
        selected: maxAnswer.handle === handle,
        votePercentage: answer.votePercentage,
        aov: answer.aov,
        tov: answer.tov,
        tovPercentage: answer.tovPercentage
      }
    });

    const tovData = sortedByTotalOrderValueAnswers.map((answer) => {
      let { votes, handle, title } = answer;

      if (this.props.condensed) {
        title = truncate(title, 30);
      }

      return {
        name: title,
        y: answer.tov,
        votes,
        selected: maxAnswer.handle === handle,
        votePercentage: answer.votePercentage,
        aov: answer.aov,
        tov: answer.tov,
        tovPercentage: answer.tovPercentage
      }
    });

    const aovData = sortedByAOVAnswers.map((answer) => {
      let { votes, handle, title } = answer;

      if (this.props.condensed) {
        title = truncate(title, 30);
      }

      return {
        name: title,
        y: answer.aov,
        votes,
        selected: maxAnswer.handle === handle,
        votePercentage: answer.votePercentage,
        aov: answer.aov,
        tov: answer.tov,
        tovPercentage: answer.tovPercentage
      }
    });

    let next = <div className="next" onClick={() => this.next() }><i className="fas fa-arrow-right" /></div>;
    let prev = <div className="prev" onClick={() => this.prev()}><i className="fas fa-arrow-left" /></div>

    let tabs = [];
    let charts = [];

    tabs.push(responsesTab);

    let startIdx = 0;
    if (slide.type === 'binary') {
      startIdx = 1;
    }
    if (slide.type === 'range') {
      startIdx = 2;
    }
    if (slide.type === 'satisfaction') {
      startIdx = 3;
    }
    if (slide.type === 'star-rating') {
      startIdx = 1;
    }

    charts.push(<ChartGroup
      condensed={this.props.condensed}
      startIdx={startIdx}
      categories={slide.answers.map(({ title }) => title)}
      slide={slide}
      sortBy={this.state.sortBy}
      hasOrderValue={tovTab}
      data={pieData}
    />);

    if (tovTab) {
      tabs.push(tovTab)
      charts.push(<div className="chart-group-container full"><ColumnChart
        key="tov"
        condensed={this.props.condensed}
        data={tovData}
        categories={sortedByTotalOrderValueAnswers.map(({ title }) => title)}
        tooltipContent={function () { 
          return `<div class="multiple-rows">
          <div><b>Revenue</b>: ${renderCurrency(this.point.tov)} <span>${parseFloat(this.point.tovPercentage || 0).toFixed(1)}%</span></div>
          <div><b>Average Order Value</b>: ${renderCurrency(this.point.aov)}</div>
          <div><b>Responses</b>: ${numberWithCommas(this.point.votes)} <span>${parseFloat(this.point.votePercentage).toFixed(1)}%</span></div>        
        </div>`;
        }}
        labelFormatter={function () {
          return `<strong>${this.point.name}</strong>: ${renderCurrency(this.point.tov)} (${parseFloat(this.point.tovPercentage).toFixed(1)}%)`;
        }}
      /></div>);
    }
    if (aovTab) {
      tabs.push(aovTab);
      charts.push(<div className="chart-group-container full"><ColumnChart
        key="aov"
        condensed={this.props.condensed}
        data={aovData}
        categories={sortedByAOVAnswers.map(({ title }) => title)}
        tooltipContent={function () { 
          return `<div class="multiple-rows">
          <div><b>Average Order Value</b>: ${renderCurrency(this.point.aov)}</div>
          <div><b>Revenue</b>: ${renderCurrency(this.point.tov)} <span>${parseFloat(this.point.tovPercentage || 0).toFixed(1)}%</span></div>
          <div><b>Responses</b>: ${numberWithCommas(this.point.votes)} <span>${parseFloat(this.point.votePercentage).toFixed(1)}%</span></div>        
        </div>`;
        }}
        labelFormatter={function () {
          return `<strong>${this.point.name}</strong>: ${renderCurrency(this.point.tov)} (${parseFloat(this.point.tovPercentage).toFixed(1)}%)`;
        }}
      /></div>);
    }

    let hasNext = true;
    let hasPrev = true;
    if (this.state.idx === 0) {
      prev = (<div className="prev disabled"><i className="fas fa-arrow-left" /></div>);
      hasPrev = false;
    }
    if (this.state.idx >= tabs.length - 1) {
      next = (<div className="next disabled"><i className="fas fa-arrow-right" /></div>);
      hasNext = false;
    }
    let nextPrev = null;
    if (hasNext || hasPrev) {
      const titles = ['Responses', 'Revenue', 'Avg. Order Value']
      let title = <div className="title">{ titles[this.state.idx] }</div>;
      nextPrev = (<span className="next-prev-top">{ prev }{ title }{ next }</span>);
    }

    return (
      <div className={`slide-answers-component section ${this.props.loading ? 'loading' : ''} ${this.state.active ? 'active' : ''} ${this.state.hidden ? 'has-more' : ''}`}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();

        this.setState({ active: true });
      }}
      >
         { <DateRangeToggle
          slide={this.props.slide}
          pollId={this.props.pollId}
          fetchSlide={this.props.fetchFullSlide}
          onSubmit={(dateRange) => {
            this.props.fetchVotes(this.props.accountId, this.props.pollId, this.props.slide._id, dateRange);
            this.setState({ hasUsedDateRange: true })
          }}
        />}

        { nextPrev }

        <SortByInput
          value={this.state.sortBy}
          onChange={(value) => {
            window.localStorage.setItem('zigpoll-answers-sortby', value);
            this.setState({ sortBy: value });
          }}
        />

        { charts[this.state.idx] }
        { tabs[this.state.idx] }

        { viewMore }
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    loading: state.slides.loading,
    diffs: state.diffs,
    user: state.user
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...SlideActions, ...FlashNotificationActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SlideAnswers);