import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Switch, Route, Redirect } from 'react-router';

import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';

import * as UserActions from '../actions/Users';

import { encode, getAccountId } from '../utils';

/* Auth*/
import SignUp from './SignUp';
import LogIn from './LogIn';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';

import LifetimeDealSignUp from './LifetimeDealSignUp';

/* Settings */
import Settings from './Settings';
import AffiliateProgram from './AffiliateProgram';
import IntegrationSettings from './IntegrationSettings';
import ApiSettings from './ApiSettings';
import AutomationSettings from './AutomationSettings';
import Insights from './Insights';
import Campaigns from './Campaigns';
import Charts from './Charts';

/* Accounts */
import CreateAccount from './CreateAccount';
import Accounts from './Accounts';
import Account from './Account';
import ManageAccount from './ManageAccount';
import ManageWhiteLabel from './ManageWhiteLabel';

/* Polls */
import CreatePoll from './CreatePoll';
import Polls from './Polls';
import Poll from './Poll';

/* Slides */
import CreateSlide from './CreateSlide';
import Slides from './Slides';
import Slide from './Slide';

/* Dashboard */
import Dashboard from './Dashboard';
import PollDashboard from './PollDashboard';
import SlideDashboard from './SlideDashboard';

/* Reports */
import Reports from './Reports';

/* Responses */
import Responses from './Responses';
import AccountResponses from './AccountResponses';
import PollResponses from './PollResponses';

/* Emails */
import Emails from './Emails';
import AccountEmails from './AccountEmails';
import PollEmails from './PollEmails';

/* Participants */
import Participant from './Participant';
import SlideParticipants from './SlideParticipants';
import PollParticipants from './PollParticipants';
import AccountParticipants from './AccountParticipants';

/* Activity */
import Activity from './Activity';

/* Misc */
import Loading from './Loading';

/* 404 */
import NotFound from './NotFound';

const publicPaths = [
  '/log-in',
  '/sign-up',
  '/forgot-password',
  '/reset-password',
  '/rebeliance'
];

const authPaths = [
  '/log-in',
  '/sign-up',
  '/forgot-password',
  '/reset-password',
  '/rebeliance'
];

class Router extends Component {

  constructor(props) {
    super(props);

    const { cookies } = props;
    this.state = {
      _id: cookies.get('_id')
    }

    const pathIsPrivate = publicPaths.indexOf(this.props.location.pathname) === -1;
    const isAuthPath = authPaths.indexOf(this.props.location.pathname) !== -1;

    if (this.state._id) {
      this.props.restore(this.state._id).then(() => {
        if (isAuthPath) {
          this.props.history.replace('/sign-up');
        }
      });
    } else if (pathIsPrivate) {
      this.props.history.replace('/sign-up')
    }
  }

  componentDidUpdate(prevProps) {
    const pathIsPrivate = publicPaths.indexOf(this.props.location.pathname) === -1;
    const isAuthPath = authPaths.indexOf(this.props.location.pathname) !== -1;

    if (this.props.user._id && isAuthPath) {
      this.props.history.replace('/');
    }

    if (pathIsPrivate && !this.props.user._id && !this.props.user.loading) {
      this.props.history.replace('/sign-up');
    }
  }

  render() {
    const pathIsPrivate = publicPaths.indexOf(this.props.location.pathname) === -1;

    if (pathIsPrivate && (!this.props.user._id || this.props.user.loading)) {
      return (<Route component={Loading} />);
    }

    let accountId;
    if (this.props.user && this.props.user.accounts) {
      accountId = encode(getAccountId(this.props.user.accounts[0]));
    }

    return (
      <Switch>
        <Route exact path="/emails/a/:accountId/p/:pollId/s/:slideId" component={Emails} />
        <Route exact path="/emails/a/:accountId" component={AccountEmails} />
        <Route exact path="/emails/a/:accountId/p/:pollId" component={PollEmails} />

        <Route exact path="/responses/a/:accountId" component={AccountResponses} />
        <Route exact path="/responses/a/:accountId/p/:pollId" component={AccountResponses} />
        <Route exact path="/responses/a/:accountId/p/:pollId/s/:slideId" component={AccountResponses} />

        <Route exact path="/participants/a/:accountId/pa/:participantId" component={Participant} />
        <Route exact path="/participants/a/:accountId/p/:pollId/pa/:participantId" component={Participant} />
        <Route exact path="/participants/a/:accountId/p/:pollId/s/:slideId/pa/:participantId" component={Participant} />


        <Route exact path="/participants/a/:accountId" component={AccountParticipants} />
        <Route exact path="/participants/a/:accountId/p/:pollId" component={PollParticipants} />
        <Route exact path="/participants/a/:accountId/p/:pollId/s/:slideId" component={SlideParticipants} />


        <Redirect exact path="/" to={`/dashboard/a/${accountId}`} />

        <Route exact path="/dashboard/a/:accountId" component={Dashboard} />
        <Route exact path="/dashboard/a/:accountId/p/:pollId" component={PollDashboard} />
        <Route exact path="/dashboard/a/:accountId/p/:pollId/s/:slideId" component={PollDashboard} />


        <Route exact path="/reports/a/:accountId" component={Reports} />
        <Route exact path="/reports/a/:accountId/p/:pollId" component={Reports} />

        <Route exact path="/sign-up" component={SignUp} />
        <Route exact path="/log-in" component={LogIn} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/reset-password" component={ResetPassword} />

        <Route exact path="/rebeliance" component={LifetimeDealSignUp} />

        <Route exact path="/settings" component={Settings} />
        <Route exact path="/settings/a/:accountId" component={Settings} />
        <Route exact path="/settings/a/:accountId/p/:pollId" component={Settings} />
        <Route exact path="/settings/a/:accountId/p/:pollId/s/:slideId" component={Settings} />

        <Route exact path="/affiliate" component={AffiliateProgram} />
        <Route exact path="/affiliate/a/:accountId" component={AffiliateProgram} />
        <Route exact path="/affiliate/a/:accountId/p/:pollId" component={AffiliateProgram} />
        <Route exact path="/affiliate/a/:accountId/p/:pollId/s/:slideId" component={AffiliateProgram} />

        <Route exact path="/integrations" component={IntegrationSettings} />
        <Route exact path="/integrations/a/:accountId" component={IntegrationSettings} />
        <Route exact path="/integrations/a/:accountId/p/:pollId" component={IntegrationSettings} />
        <Route exact path="/integrations/a/:accountId/p/:pollId/s/:slideId" component={IntegrationSettings} />

        <Route exact path="/automations" component={AutomationSettings} />
        <Route exact path="/automations/a/:accountId" component={AutomationSettings} />
        <Route exact path="/automations/a/:accountId/p/:pollId" component={AutomationSettings} />
        <Route exact path="/automations/a/:accountId/p/:pollId/s/:slideId" component={AutomationSettings} />

        <Route exact path="/api" component={ApiSettings} />
        <Route exact path="/api/a/:accountId" component={ApiSettings} />

        <Route exact path="/insights" component={Insights} />
        <Route exact path="/insights/a/:accountId" component={Insights} />
        <Route exact path="/insights/a/:accountId/p/:pollId" component={Insights} />
        <Route exact path="/insights/a/:accountId/p/:pollId/s/:slideId" component={Insights} />

        <Route exact path="/campaigns" component={Campaigns} />
        <Route exact path="/campaigns/a/:accountId" component={Campaigns} />
        <Route exact path="/campaigns/a/:accountId/p/:pollId" component={Campaigns} />
        <Route exact path="/campaigns/a/:accountId/p/:pollId/s/:slideId" component={Campaigns} />

        <Route exact path="/charts" component={Charts} />
        <Route exact path="/charts/a/:accountId" component={Charts} />
        <Route exact path="/charts/a/:accountId/p/:pollId" component={Charts} />
        <Route exact path="/charts/a/:accountId/p/:pollId/s/:slideId" component={Charts} />

        <Route exact path="/activity" component={Activity} />
        <Route exact path="/activity/a/:accountId" component={Activity} />
        <Route exact path="/activity/a/:accountId/p/:pollId" component={Activity} />

        <Route exact path="/a/create" component={CreateAccount} />

        <Route exact path="/accounts" component={Accounts} />
        <Route exact path="/a/:accountId" component={Account} />
        <Route exact path="/a/:accountId/manage" component={ManageAccount} />
        {/*<Route exact path="/a/:accountId/manage-white-label" component={ManageWhiteLabel} />*/}

        <Route exact path="/a/:accountId/p/create" component={CreatePoll} />

        <Route exact path="/a/:accountId/p" component={Polls} />
        <Route exact path="/a/:accountId/p/:pollId" component={Poll} />

        <Route exact path="/a/:accountId/p/:pollId/s/create" component={CreateSlide} />

        <Route exact path="/a/:accountId/p/:pollId/s" component={Slides} />
        <Route exact path="/a/:accountId/p/:pollId/s/:slideId" component={Slide} />

        <Route component={NotFound} />
      </Switch>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(UserActions, dispatch);
}

export default withRouter(withCookies(connect(mapStateToProps, mapDispatchToProps)(Router)));