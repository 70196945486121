import React, { Component } from 'react';
import Dropzone from 'react-dropzone'

export default class SingleImageUploader extends Component {
  constructor(props) {
    super(props);
    const image = props.image ? { url: props.image } : null;
    this.state = { image: image };
  }

  clear() {
    this.setState({ image: null });
    this.props.onChange(null);
  }

  render() {
    const onDrop = (acceptedFiles) => {
      console.log('ondrop called');
      acceptedFiles.forEach((file) => {
        const reader = new FileReader()

        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = () => {
          const image = file;
          image.binary = reader.result;
          image.url = URL.createObjectURL(file)

          this.setState({ image: image }, () => {
            this.props.onChange(this.state.image);
          });
        }

        reader.readAsDataURL(file);
      }, [])
    };

    let content = (
      <Dropzone
        onDrop={onDrop}
        multiple={false}
        disabled={this.props.disabled}
        noDragEventsBubbling={true}
        accept="image/png, image/gif, image/jpeg, image/svg+xml"
      >
        {({ getRootProps, getInputProps, isDragActive }) => (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          {
            isDragActive ?
              <p>Drop the Image</p> :
              <p>Drop the Image here, or click to upload</p>
          }
        </div>
        )}
      </Dropzone>
    );

    if (this.state.image) {
      content = (
        <div className="image-preview" style={{ maxWidth: this.props.maxWidth || null }}>
          <div className="remove" onClick={this.clear.bind(this)}/>
          <img src={this.state.image.url} />
        </div>
      );
    }

    let optional = null;
    if (this.props.optional) {
      optional = (<span>(Optional)</span>);
    }

    let label = this.props.label || (<label>Image{optional}</label>);
    if (this.props.hideLabel) {
      label = null;
    }

    return (
      <div key={this.props.key} style={this.props.style} className={`single-image-uploader ${this.props.disabled ? 'disabled' : '' }`}>
        {label}

        { content }
      </div>
    )
  }
}
