import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import * as UserActions from '../actions/Users';

import { EmailInput, PasswordInput } from './SimpleInputs';
import { AuthSubmitButton } from './Buttons';

import AuthForm from './AuthForm';

class UserLoginForm extends AuthForm {
  constructor(props)  {
    super(props);
    this.state = { 
      inputs: {
        email: '',
        password: ''
      },
      valid: undefined
    };
    this.inputStates = {};
  }

  submitAction() {
    this.props.login(this.state.inputs);
  }

  renderInputs() {
    return (
      <div className="inputs">
        <EmailInput
          onChange={this.onChange.bind(this)}
          onValidate={this.onValidate.bind(this)}
          label="Email Address"
          type="email"
          placeholder="name@company.com"
          name="email"
          value={this.state.inputs.email}
          errorMessage="Please enter a valid email address."
          ref={this.setRef.bind(this)}
          maxlength={255}
          hideCharCount={true}
        />

        <PasswordInput
          onChange={this.onChange.bind(this)}
          onValidate={this.onValidate.bind(this)}
          label="Password"
          type="password"
          showForgotPasswordLink={true}
          placeholder="Password (at least 6 characters)"
          name="password"
          value={this.state.inputs.password}
          errorMessage="Please enter a password with at least 6 characters."
          ref={this.setRef.bind(this)}
        />
      </div>
    );
  }

  renderActions() {
    return (
      <div className="actions">
        <AuthSubmitButton title="Log Back In" />

        <div className="log-in">
          <div>Don't have an account?</div>
          <Link to="/sign-up">Sign Up</Link>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    loading: state.user.loading
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(UserActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(UserLoginForm);

