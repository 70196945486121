import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import _ from 'lodash';

import * as PollActions from '../actions/Polls';

import ColumnLayout from '../components/ColumnLayout';
import ColumnLoading from './ColumnLoading';

import SectionHeader from '../components/SectionHeader';
import BrowserFrame from '../components/BrowserFrame';
import Embed from '../components/Embed';
import Slides from '../components/Slides';

import { decode, encode } from '../utils';

class VisualDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = { currentIdx: 0, active: true };
  }

  componentDidUpdate(prevProps, prevState) {  
    if (!_.isEqual(prevProps.slides, this.props.slides)) {
      this.setState({ currentIdx: 0 });
    }
  }

  next() {
    console.log('next called');
    this.setState({ currentIdx: this.state.currentIdx + 1 });
  }

  prev() {
    this.setState({ currentIdx: this.state.currentIdx - 1 });
  }

  render() {
    return (
      <div className="visual-display">
        <BrowserFrame 
          title="Survey Preview"
          subtitle="Flip through your survey below."
        />
        <Embed 
          { ...this.props }
          toggleActive={() => { this.setState({ active: !this.state.active})}}
          active={this.state.active}
          next={this.next.bind(this)}
          prev={this.prev.bind(this)}
          currentIdx={this.state.currentIdx}
        />
      </div>      
    );
  }
}

class SlidesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    if (!this.props.poll || !this.props.account) {
      this.props.fetchAccountAndPoll();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.pollId !== prevProps.pollId) {
      this.props.fetchPoll();
    }
  }

  render() {
    if (!this.props.poll || !this.props.account) {
      return (
        <ColumnLoading />
      );
    }

    let settings = this.props.account.displaySettings;
    if (this.props.poll.displaySettings) {
      settings = this.props.poll.displaySettings;
    }

    let slidesDisplay = (<div className="empty-object-list" style={{ marginTop: 25 }}>
        <h3>There are no slides for this survey.</h3>
        <p>Slides are the building blocks of a survey. They can do a variety of things from asking multi-choice questions to collecting customer information.</p>
        <p>Get started by clicking the link below.</p>
        <Link to={`/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}/s/create`}>Create One</Link>
      </div>);

    if (this.props.poll.slides.length) {
      slidesDisplay = (
        <div className="split big">
          <Slides {...this.props} />
          <VisualDisplay
            slides={ [...this.props.poll.slides] }
            {...settings }
          />
        </div>
      );
    }

    return (
      <ColumnLayout
        title="Slides"
        className="poll"
        pageType="slide-edit"
      >
        <div style={{ minWidth: 1100 }}>

          <SectionHeader
            className="center no-margin"
            title="Slide Management"
            subtitle="Drag and drop to adjust the order."
            tooltip="Each slide is a step of your survey. There are several types of slides that can be used to capture customer feedback. You can add as many as you want."
            bottom={true}
          />

          <div className="slide-reorder-display">
            { slidesDisplay }
          </div>

        </div>
      </ColumnLayout>
    );
  }
}


function mapStateToProps(state, ownProps) {
  const accountId = decode(ownProps.match.params.accountId);
  const pollId = decode(ownProps.match.params.pollId);

  const accounts = state.accounts || [];
  const account = accounts[accountId];

  const polls = state.polls || [];
  const poll = polls[pollId];

  return {
    accountId,
    accounts,
    account,
    pollId,
    polls,
    poll,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(PollActions, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SlidesPage));