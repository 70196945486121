import React, { Component } from 'react';

import tinycolor from 'tinycolor2';
import Embed from './Embed';

const fonts = {
  'sans': 'Helvetica',
  'serif': 'Georgia',
  'slab': 'Courier New'
}

class Row extends Component {
  render() {
    if (!this.props.content) { return null; }

    return (
      <div
        className={this.props.className}
        dangerouslySetInnerHTML={{ __html : this.props.content }}
      />
    );
  }
}

export default class BrowserFrame extends Component {
  constructor(props) {
    super(props);
    this.state = { currentIdx: 0, active: true, prevIndexes: [] };
  }

  next() {
    if (this.props.next) { return this.props.next() };

    this.state.prevIndexes.push(this.state.currentIdx);
    this.setState({ currentIdx: this.state.currentIdx + 1 });
  }

  prev() {
    if (this.props.prev) { return this.props.prev() };

    const idx = this.state.prevIndexes.pop();
    if (idx !== undefined) {
      this.setState({ currentIdx: idx });
    }
  }

  goto(slideId) {
    if (this.props.goto) { return this.props.goto(slideId) };

    let idx = undefined;
    this.props.poll.slides.forEach(({ _id }, i) => {
      if (_id === slideId) {
        idx = i;
      }
    })
    if (idx === undefined) { return this.next(); }

    this.state.prevIndexes.push(this.state.currentIdx);
    this.setState({ currentIdx: idx });
  }

  toggleActive() {
    if (this.props.toggleActive) { return this.props.toggleActive() };

    this.setState({ active: !this.state.active});
  }

  render() {
    const settings = this.props.pageDisplaySettings || {};

    // let logo = "https://s3.us-east-2.amazonaws.com/zigpoll/dist/img/logo.png";
    let logo = null;
    if (settings.logoImage) {
      logo = <div className="logo"><img src={settings.logoImage} /></div>;
    }

    let slides = this.props.slides;
    if (slides.length === 0) {
      slides = [{
        title: 'Example Slide',
        // subtitle: "You're doing great!",
        copy: `<p>This is an example slide. Your published zigpoll will look look something like this given your current settings.</p>`,
        settings: {
          showTitle: true
        }
      }]
    }

    return (
      <div className={`page-preview-wrapper ${settings.style}`}>
        <div class="page-preview">
          <div className="page-content" style={{ backgroundColor: settings.backgroundColor, backgroundImage: settings.backgroundImage ? `url(${settings.backgroundImage})` : '' }}>
            { logo }

            <div className="bg" style={{ background: settings.foregroundColor}} />
            <div class="wave-divider inverted" aria-hidden="true"><svg class="wave-1hkxOo" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 100" preserveAspectRatio="none"><path class="wavePath-haxJK1" d="M826.337463,25.5396311 C670.970254,58.655965 603.696181,68.7870267 447.802481,35.1443383 C293.342778,1.81111414 137.33377,1.81111414 0,1.81111414 L0,150 L1920,150 L1920,1.81111414 C1739.53523,-16.6853983 1679.86404,73.1607868 1389.7826,37.4859505 C1099.70117,1.81111414 981.704672,-7.57670281 826.337463,25.5396311 Z" fill={settings.foregroundColor}></path></svg></div>

            <div className="content">
              <Embed 
                { ...this.props }
                slides={slides}
                active={true}
                next={this.next.bind(this)}
                prev={this.prev.bind(this)}
                goto={this.goto.bind(this)}
                pagePreview={true}
                pollState={this.props.pollState}
                scalePoll={this.props.scalePoll}
                toggleActive={this.toggleActive.bind(this)}
                currentIdx={this.props.currentIdx === undefined ? this.state.currentIdx : this.props.currentIdx}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}